.checkout {
  .card-number {
    width: 300px;
  }

  .expiry {
    width: 110px;
  }

  .cvc {
    width: 90px;
  }

  .input-outer {
    background: linear-gradient(to right, #106ff1, #a723ec);
    border-radius: 30px;
    padding: 2px;
    margin-top: 15px;



    .input-inner {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 10px 0;
      border-radius: 30px;
      padding-left: 25px;
      overflow: hidden;

    }


  }

  .send-button {
    margin-top: 30px;
    width: 150px;
  }

  & .error-msg-container {
    font-size: 15px;
    color: #dc3545;
  }

  .card-completed-container {
    display: flex;
   

    .saved-card-border {
      background: linear-gradient(to right, #106ff1, #a723ec);
      border-radius: 30px;
      padding: 2px;
      width: 400px;
      margin-right: 40px;

      .saved-card-inner {
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 10px 0;
        border-radius: 30px;
        padding-left: 25px;
        overflow: hidden;
        display: flex;
        align-items: center;

        .card-type-value {
          margin-right: 30px;
        }
      }
    }

    .cc-buttons {
      display: flex;
      
      .btn {
        margin-right: 20px;
        width: 180px;
      }
    }
  }
}


@media (max-width: 580px) {
  .checkout {

    & .card-completed-container {
      flex-direction: column;
      align-items: center;
    }

    & .saved-card-border {
      width: 100% !important;
      margin: 0 !important;
    }

    & .cc-buttons {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    & .cc-buttons button {
      margin-right: 0 !important;
      margin: 15px auto !important;
      width: 85% !important;
    }

    & .checkout-form {
      display: flex;
      flex-direction: column;
      // align-items: center;
    }

  }
}