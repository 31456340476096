#manage-article-reviews {

    background: #f1f2f361;
    position: relative;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 910px;
    }

    & .search-row {
        display: flex;
        margin-top: 50px;
        padding-bottom: 25px;
        border-bottom: 1px solid #ddd;
    }

    & .top-row {
        display: flex;
        justify-content: space-evenly;
    }

    & .inactive-tab {
        color: #ddd;
    }

    & .tab-item {
        cursor: pointer;
    }

    // & .filter-row {
    //     display: flex;
    //     justify-content: space-evenly;

    // }

    & .leftside-content {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }

    & .rightside-content {
        width: 30%;
        display: flex;
        justify-content: flex-end;
    }

    
    & .small-gradient-button {
        width: 90%;
        height: 50px;
        text-align: center;
    }

    & .small-gradient-button span {
        margin: 0;
    }

    & .sp-search-input {
        width: 90%;
    }

    & .results-section {
        margin-top: 30px;
    }

    & .message-span-container {
        padding-left: 30px;
    }

    & .filters {
        display: flex;
        margin-top: 20px;
        justify-content: center;
    }

    & .filters .filter-item {
        width: 150px;
        text-align: center;
        color: #457bd569;
        cursor: pointer;
    }

    & .filters .filter-item.active-filter {
        color: #457bd5;
        font-weight: bold;
    }

    & .filters .middle-filter {
        border-left: 3px solid #457bd5a8;
        border-right: 3px solid #457bd5a8;
    }

    & .rev-card-manage {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 30px;
        padding: 30px 35px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        margin: 15px 0;
    }

    & .rev-card-actions {
        width: 100px;
    }

    & .rev-actions-container {
        display: flex;
        justify-content: space-around;
    }

    & .accept-icon {
        cursor: pointer;
        color: #28a745;
    }

    & .delete-icon {
        cursor: pointer;
    }

    & .decline-icon {
        cursor: pointer;
        color: #dc3545;
    }

    & .rev-card-title {
        display: flex;
        margin-bottom: 15px;
    }

    & .rev-card-value {
        font-weight: bold;
        color: #634e70;
    }

    & .rev-card-rating {
        display: flex;
        margin-bottom: 20px;
    }

    & .rating-label {
        margin-right: 15px;
    }

    & .rev-card-review {
        display: flex;
    }

    & .review-label {
        margin-right: 15px;
    }

    & .review-content {
        font-size: 15px;
        line-height: 30px;
    }
    // & .ai-image-container {
    //     width: 130px;
    //     height: 130px;
    //     border-radius: 15px;
    //     overflow: hidden;
    // }

    // & .ai-image-container img {
    //     width: 100%;
    //     height: 100%;
    // }

    // & .ai-info-container {
    //     flex: 1;
    //     padding-left: 50px;
    // }

    // & .ai-actions-container {
    //     width: 120px;
    // }

    // & .ai-main-info {
    //     font-size: 25px;
    //     display: flex;
    //     justify-content: flex-start;
    //     // font-weight: 600;
    //     color: #634e70
    // }

    // & .ai-main-info .ai-u-name {
    //     font-weight: 600;
    // }

    // & .ai-details-info {
    //     margin-top: 10px;
    //     font-size: 17px;
    //     color: #634e70;
    // }

    // & .ai-detail-container {
    //     display: flex;
    // }

    // & .ai-detail-label {
    //     width: 75px;
    //     font-weight: 600;
    // }

    // & .ai-actions-container {
    //     display: flex;
    //     justify-content: space-evenly;
    // }

    // & .edit-icon:hover {
    //     color: #ffc107;
    //     cursor: pointer;
    // }
    // & .delete-icon:hover {
    //     color: #dc3545;
    //     cursor: pointer;
    // }
    // & .update-icon:hover {
    //     color: #28a745;
    //     cursor: pointer;
    // }

    // & .edit-sp-name, .edit-sp-description {
    //     width: 90%;
    // }

    // & .edit-sp-description{
    //     margin-top: 15px;
    // }

}

@media (max-width: 580px) {
    #manage-article-reviews {

        & .inner-content-container {
            width: 100%;
        }

        & .inner-content {
            width: 95%;
        }

        & .page-title {
            text-align: center;
        }
        
        & .rev-card-manage {
            flex-direction: column;
            align-items: center;
        }

        & .rev-card-actions {
            margin-top: 30px;
        }

        & .rev-card-title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }   

        & .to-container {
            margin-left: -10px;
        }

        & .rev-card-review {
            flex-direction: column;
            align-items: center;
        }

        & .review-content {
            text-align: center;
        }

        & .no-review-text {
            text-align: center;
        }

        & .no-review-text {
            text-align: center;
        }

    }
}