@import "../../variables.scss";

.search-field--button {
    background-image: linear-gradient(270deg, $search_btn_gradient_start 0%, $search_btn_gradient_end 100%);
    font-size: 20px;
    text-align: left;

    span {
        margin-left: 85px;
        @media (max-width: $md - 1) {
            margin-left: auto;
        }
    }

    img {
        margin-left: 45px;
        @media (max-width: $md - 1) {
            margin-left: 45px;
            margin-right: auto;
        }
    }
}