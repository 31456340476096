#article-review-section {
    width: 80%;
    max-width: 960px;
    margin: 0 auto;

    & .section-title {
        font-size: 30px;
        font-weight: bold;
    }

    & .rev-f-row {
        width: 100%;
        margin: 0 auto 30px;
    }

    & .rev-f-half-input-container {
        width: 45%;
    }

    & .rev-f-half-input-container .basic-input {
        width: 100%;
    }

    & .rev-f-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    & .rev-f-full-input-container {
        width: 100%
    }

    & .rev-f-full-input-container .basic-input {
        width: 100%;
    }

    & .art-rev-submit-btn {
        width: 250px;
        height: 55px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    & .ar-form-response-row {
        display: flex;
        justify-content: space-around;
        margin-bottom: 10px;
    }

    & .basic-input-err {
        border-bottom: 1px solid red;
    }
}

#review-list-section-container {
    margin-top: 75px;

    & .rev-list-item {
        margin-bottom: 20px;
    }

    & .rev-list-item-name {
        font-weight: bold;
        color: #634e70;
        margin-bottom: 5px;
    }

    & .rev-list-item-comment {
        font-size: 17px;
    }

    & .rev-list-item-reply {
        cursor: pointer;
        color: #457bd5;
        max-width: max-content;
    }

    & .reply-section {
        margin-top: 1rem;
        margin-left: 1rem;
    }

    & .arrow-replies {
        margin-left: 10px;
        font-size: 2rem;
        cursor: pointer;
    }

    & .visible {
        display: inline;
    }

    & .hidden {
        display: none;
    }

    & .reply-form {
        margin-bottom: 20px;
    }
}
