#therapist-manage-insurances {

    background: #f1f2f361;
    position: relative;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .add-row {
        display: flex;
        margin-top:25px;
        padding-bottom: 35px;
        // border-bottom: 1px solid #ddd;
    }

    & .add-row .leftside-content {
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: space-between;
    }

    & .leftside-content {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }

    & .rightside-content {
        width: 30%;
        display: flex;
        justify-content: flex-end;
    }

    
    & .small-gradient-button {
        width: 90%;
        height: 50px;
    }

    & .small-gradient-button span {
        margin: 0;
    }

    & .ins-add-input {
        width: 100%;
    }

    & .add-section {
        margin-top: 30px;
    }

    & .message-span-container {
        padding-left: 30px;
    }

    & .ins-list-item {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 30px;
        padding: 20px 35px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        margin: 15px 0;
    }

    & .ins-li-name {
        font-weight: 600;
    }

    & .ins-li-description {
        font-size: 15px;
    }

    & .ins-li-info {
        flex: 1;
        border-right: 1px solid #eee;
    }

    & .ins-li-actions {
        width: 70px;
        display: flex;
        justify-content: space-evenly;
    }

    & .edit-icon:hover {
        color: #ffc107;
        cursor: pointer;
    }
    & .add-icon:hover {
        color: #28a745;
        cursor: pointer;
    }
    & .delete-icon:hover {
        color: #dc3545;
        cursor: pointer;
    }

    & .edit-sp-name, .edit-sp-description {
        width: 90%;
    }

    & .edit-sp-description{
        margin-top: 15px;
    }

    & ul {
        list-style-type: none;
    }

    & .attached-specialities-list {
        display: flex;
        flex-wrap: wrap;
    }

    & .ins-at-item {
        display: flex;
        background: linear-gradient(to right,  #106ff1, #a723ec);
        // padding: 5px 15px;
        border-radius: 25px;
        align-items: center;
        margin: 5px 10px;
        justify-content: space-around;
        padding: 2px;
    }

    & .ins-at-outer {
        display: flex;
        background: #fff;
        padding: 5px 15px;
        border-radius: 25px;
        align-items: center;
        height: 45px;
    }

    & .ins-at-outer .ins-at-info {
        height: 45px;
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    & .ins-at-outer .ins-at-info h4 {
        margin: 0
    }

    & .ins-at-actions {
        height: 45px;
        display: flex;
        align-items: center;
    }

    & .attached-specialities-section {
        margin-top: 25px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 15px;
    }

    & .add-ins-btn {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 180px;
        margin-left: 15px;
    }

    & .bold-val {
        color: #634e70;
        font-weight: bold;
    }

    & .not-authorized-description {
        padding: 20px;
        font-size: 18px;
    }

}

@media (max-width: 580px) {
    #therapist-manage-insurances {

        & .inner-content {
            width: 95%;
        }

        & .page-title {
            text-align: center;
        }

        & .ins-at-item {
            width: 100%;
        }

        & .ins-at-outer {
            width: 100%;
        }

        & .ins-at-info {
            flex: 1;
            justify-content: space-around;
            overflow: hidden;
            text-align: center;
            font-size: 15px;
        }

        & .add-row {
            flex-direction: column;
            align-items: center;
        }

        & .add-ins-btn {
            width: 80%;
            margin-top: 20px;
        }

        & .ins-at-name {
            font-size: 17px;
        }
        
    }
}