.data-card {
    width: 20%;
    min-width: 185px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 15px 35px 30px;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);

    & .dc-icon-row {
        display: flex;
        justify-content: space-around;
    }

    & .dc-icon-container {
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 50%;
    }

    & .dc-value-row {
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    & .dc-value {
        font-size: 35px;
        font-weight: bold;
    }

    & .dc-label {
        font-size: 15px;
        text-align: center;
        color: #ccc
    }

    & .dc-label-row {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 5px;
    }

    // Pink Styles
    & .dc-pink-background {
        background: rgba(255, 53, 153, .15)
    }

    & .dc-pink-color {
        color: #ff3599;
    }
    // Blue Styles
    & .dc-blue-background {
        background: rgba(18, 110, 241, .15)
    }

    & .dc-blue-color {
        color: #126ef1;
    }
    // Purple Styles
    & .dc-purple-background {
        background: rgba(165, 36, 236, .15)
    }

    & .dc-purple-color {
        color: #a524ec;
    }
    // Orange Styles
    & .dc-orange-background {
        background: rgba(253, 149, 87, .15)
    }

    & .dc-orange-color {
        color: #fd9557;
    }

}


@media (max-width: 490px) {
    .data-card {
        width: 100%;
        margin-bottom: 35px;
    }
}
