@import "../../variables.scss";

#homepage {
  & .hp-section-title {
    // margin-top: 25px;
    margin-bottom: 30px;
    color: #fff;
  }

  & .hp-section-subtitle {
    margin-bottom: 50px;
    color: #ff3a4b;
    font-weight: bold;
  }

  & .text-d {
    color: #fff;
  }
}

.home-second-bg {
  background: url("../../static/images/desktop/home-favourite-products-background-missized.png") no-repeat;
  background-position: center 40px;
  background-size: 2000px;

  @media (max-width: $lg - 1) {
    h2 {
      margin-top: 30px;
    }
    background-size: auto 1300px;
    background-position-y: 0px;
    display: block;
  }

  @media (max-width: $md - 1) {
    background-position-y: 150px;
    background-size: 2700px;
  }

  .favorite-section {
    h2 {
      margin-top: 30px;
    }

    @media (max-width: $md - 1) {
      padding-top: 0px;
      p {
        text-align: left;
      }
    }
  }
  .special-section {
    max-width: 1200px;
    margin: 50px auto 0 auto;
    // h2 {
    //   color: white;
    // }
    @media (max-width: 780px) {
      display: block;
      margin: auto;
      margin-top: 70px;
    }
    @media (max-width: $md - 1) {
      margin-top: 0px;
      p {
        text-align: left;
      }
    }
  }
  .travel-section {
    margin-top: 40px;
    h2 {
      margin-bottom: 45px;
      color: white;
    }

    .container {
      padding-bottom: 150px;
    }

    @media (max-width: $md - 1) {
      h2 {
        color: $heading_purple;
      }
      p {
        text-align: left;
      }

      .container {
        padding-bottom: 50px;
      }
    }
  }
}

.home-logo {
  margin: 100px auto 90px;
  text-align: center;

  @media (max-width: $lg - 1) {
    margin: 110px 0 70px;

    img {
      height: 100px;
      width: auto;
    }
  }
}

.home-title {
  // margin-top: 170px;
  margin-left: 40px;
  margin-bottom: 55px;
  padding-bottom: 20px;
  width: 66.66%;

  @media (max-width: $lg - 1) {
    margin: 80px auto 0 100px;
    width: 80%;
  }

  @media (max-width: $md - 1) {
    margin: 0 auto;
    width: 90%;
  }

  h1 {
    color: $heading_purple;
    font-size: 30px;
    font-weight: 600;
  }
}

.home-provider-profiles-left {
  @media (max-width: $md - 1) {
    text-align: center;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}

.business-name {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: auto;
  overflow: hidden;
  height: 60px;
}

.partners-name {
  color: #634e70;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: auto;
  overflow: hidden;
  height: 60px;
}

.home-provider-profiles-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 50px;

  h2 {
    margin: 0;
    line-height: 33px;

    @media (max-width: $md - 1) {
      margin: 40px 0;
    }
  }

  span {
    line-height: 44px;
  }

  @media (max-width: $lg - 1) {
    padding: 20px 0 25px;
  }

  @media (max-width: $md - 1) {
    padding-bottom: 0;

    button {
      margin: 40px auto 0;
    }
  }
}
.inclusive-text {
  h3 {
    font-weight: 700;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 30px;
    @media (max-width: $md - 1) {
      text-align: center;
    }
  }
  p {
    padding-bottom: 24px;
    @media (max-width: $md - 1) {
      padding-bottom: 0;
      text-align: left;
    }
  }
}
.blog-listing--container {
  margin-left: auto;
  margin-right: auto;

  @media (max-width: $sm - 1) {
    max-width: 380px;
  }

  .btn {
    @media (max-width: $md - 1) {
      margin: 40px 0 0;
    }
  }
}

.blog-button {
  margin-top: 120px;
  position: center;
}
.productsButton {
  margin-top: 100px;
  @media (max-width: $md - 1) {
    padding-bottom: 50px;
  }
}

.home-product-card {
  width: 170px;
  height: 140px;
  margin: 0 3px;

  .product-title {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: auto;
    overflow: hidden;
    height: 56px;
  }

  .product--image-container {
    flex-basis: 50%;

    @media (max-width: $lg - 1) {
      flex-basis: 45%;
    }

    .product--image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 20px;

      @media (max-width: 600px) {
        height: 140px;
      }
    }
  }
}
.business-flex-slider {
    width: 80%;
    margin: 10px auto 0;

    @media (max-width: 650px) {
        width: 100%;
        margin: 20px auto 0;
    }

    .flex-slider--row-favourite {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        // margin: 40px 0 70px 0;
        padding: 0;
        @media (max-width: 650px) {
            margin: 0 auto;
            padding: 0;
            flex-direction: column;
            align-items: center;

            .home-business-card {
                // margin-top: 20px;
                width: 300px;
            }
          }
        
    }
}

.home-business-card{
  margin: 0 5px;
  display: flex;
  width: 170px;
  justify-content: space-evenly;

  .business-image-container, .partners-image-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 20px;
    line-height: 150px;
    background-color: #ffffff;
    margin: 0 auto;

    img {
      padding: 10px;
    }

    .business-image {
      width: 100%;
    }
  }

  .partners-image-container {
    border: 2px solid #634e70;
  }
}

.become-member {
  position: relative;

  img {
    display: block;
    height: 657px;
    margin: 0 auto;
    max-width: 938px;
    object-fit: cover;
    width: 100%;

    @media (max-width: $md - 1) {
      height: 500px;
      max-width: 713px;
    }
  }

  .become-member--box {
    color: #fff;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    max-width: 500px;
    width: 100%;

    h2 {
      color: #fff;
      margin-bottom: 20px;
      margin-left: 45px;
    }

    p {
      margin-bottom: 45px;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 60px;

      @media (max-width: $md - 1) {
        margin-bottom: 20px;
      }
    }

    .become-member--btn {
      background-image: linear-gradient(
        to right,
        $become_member_btn_gradient_start 0%,
        $become_member_btn_gradient_end 100%
      );
      font-size: 16px;
      height: 50px;
      width: 200px;
    }
  }
}

.flex-slider {
  overflow: visible;
  // overflow-x: scroll;
  margin-left: 32px;
  margin-right: 32px;

  /* width */
  &::-webkit-scrollbar {
    height: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: rgba(#fff, 0.7);
    border-radius: 30px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($default_text_grey, 0.8);
    border-radius: 30px;
  }

  /* Handle on hover */
  &:hover {
    /* width */
    &::-webkit-scrollbar {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
      cursor: pointer;
    }
  }

  @media (min-width: $sm) {
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: $md) {
    max-width: 720px;
  }
  @media (min-width: $lg) {
    max-width: 930px;
    // overflow-x: hidden;
  }

  // @media (max-width: 960px) {
  //     // max-width: 930px;
  //     overflow-x: scroll;
  // }

  .flex-slider--row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 930px;
    padding-bottom: 10px;
    justify-content: space-evenly;
    .flex-slider--item {
      .iframe {
        width: 120px;
        height: 140px;
        @media (max-width: 540px) {
          display: none;
        }
      }
      margin: 0 10px;
      width: 30%;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .flex-slider--row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 930px;
    padding-bottom: 10px;
    justify-content: space-evenly;
    // @media (max-width: 780px) {
    //     flex-wrap: initial;
    // }
    .flex-slider--item {
      flex-wrap: nowrap;
      .iframe {
        width: 120px;
        height: 140px;
        @media (max-width: 540px) {
          display: none;
        }
      }
      margin: 0 10px;
      width: 30%;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .flex-slider--row-favourite {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 930px;
    padding: 0;
    min-height: 150px;
    justify-content: space-evenly;
    @media (max-width: 540px) {
      flex-wrap: nowrap;
    }
    .flex-slider--item {
      width: 100%;
      max-width: 120px;

      .iframe {
        width: 120px;
        height: 140px;
        @media (max-width: 540px) {
        }
      }
      margin: 0 10px;
      width: 30%;
      @media (max-width: 540px) {
        width: 31%;
      }
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.toggle-button {
  display: none;
}

.disclaimer-text-affil-prod {
  width: 80%;
  max-width: 500px;
  margin: -25px auto 25px;
  font-size: 11px;
  color: lightgray;
}

//Carousel
.carousel-indicators li {
  cursor: pointer;
  background-color: darkgray;
  filter: invert(100%);
}
.carousel-indicators .active {
  background-color: red;
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.col-md-3 .footer-logo {
  margin-top: 200px;

  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.col-md-3 .footer-menu {
  margin-top: 200px;

  @media (max-width: 767px) {
    margin-top: 0;
  }
}

.social-media-title {
    text-align: center;
    margin-bottom: 25px;
    font-size: 1.25rem;
}

.social-icons {
  margin-top: 205px;
}

@media (max-width: 769px) {
  .flex-slider .flex-slider--row .flex-slider--item {
    width: 70%;
    margin-bottom: 40px;
  }
}

@media (max-width: 540px) {
  .flex-slider .flex-slider--row .flex-slider--item {
    width: 90%;
    margin-bottom: 20px;
  }
}

@media (max-width: 769px) {
  #homepage .travel-text {
    color: #634e70;
  }
}

@media (max-width: 450px) {
  #homepage {
    & h1,
    h2 {
      font-size: 23px !important;
      text-align: center;
    }

    & .hp-section-text {
      font-size: 18px;
      line-height: 22px;
      text-align: center;
    }

    & .travel-text {
      font-size: 18px;
      text-align: center;
    }

    & .home-sp-text {
      color: #fff !important;
    }

    & .row.blog-listing {
      display: flex;
      justify-content: center;
    }

    & .col-md-6.blog-listing--right {
      align-items: center;
      text-align: justify;
    }

    & .fb-read-button {
      width: 80%;
    }
  }
}
