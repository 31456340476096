#therapist-top-locations {
    
    & .top-loc-container {
        width: 80%;
        max-width: 960px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    & .page-title {
        margin: 50px auto;
    }

    & .loc-list-container {
        width: 100%;
    }

    & .loc-location-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    & .top-loc-item {
        width: 33%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
}

@media (max-width: 480px) {
    #therapist-top-locations {
        
        & .page-title {
            text-align: center;
        }

        & .loc-location-list {
            flex-direction: column;
            align-items: center;
        }

        & .top-loc-item {
            width: 100%;
            text-align: center;
            margin: 10px auto;
        }

    }
}