#reset-password {

    & .page-background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    & .top-left-shape {
        width: 650px;
        position: absolute;
        top: -100px;
        left: -60px;
    }

    & .top-right-ellipse {
        width: 350px;
        position: absolute;
        top: -70px;
        right: -130px;
    }

    & .bottom-right-shape {
        width: 500px;
        position: absolute;
        bottom: -155px;
        right: -60px;
    }

    & .bottom-right-ellipse {
        position: absolute;
        bottom: 75px;
        right: 35px;
        width: 145px;
    }

    & .page-content {
        width: 50%;
        margin: 60px auto 0;
        max-width: 450px;
    }

    & .logo {
        width: 155px;
        display: block;
        margin: 0 auto;
    }

    & .page-title {
        margin-top: 40px;
    }

    & .input-row {
        margin-bottom: 15px;
    }

    & .form-container {
        margin-top: 35px;
    }

    & .reset-row {
        margin-top: 65px;
    }

    & .reset-success {
        margin: 200px auto;
        text-align: center;
    }

}


@media (max-width: 750px) {
    #reset-password {
        & .page-content {
            width: 75%;
        }

        & .input-row input {
            width: 80%;
        }

    }
}

@media (max-width: 615px) {
    #reset-password {
        & .top-left-shape {
            left: -135px;
        }

        & .top-right-ellipse {
            right: -200px;
        }

    }
}