.art-tags-section {
    max-width: 960px;
    width: 80%;
    margin: 0 auto;

    & .art-tags-sec-title {
        color: #634e70;
        font-size: 30px;
        font-weight: bold;
        margin: 0 0 30px;
    }

    & .article-tags-container {
        display: flex;
        flex-wrap: wrap;
    }

    & .art-tag-display {
        padding: 5px 25px;
        background: #f7d7eb;
        border-radius: 20px;
        color: #634e70;
        margin: 0 10px;
        margin-top: 10px;
    }
}
