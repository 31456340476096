@import "../../../variables.scss";

#admin-signin {
    & .page-background {
        position: absolute;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;
    }

    & .left-shape{
        position: absolute;
        width: 325px;
        top: -70px;
        left: -100px;
    }

    & .right-ellipse{
        position: absolute;
        width: 300px;
        top: -100px;
        right: -100px;
    }

    & .right-shape{
        position: absolute;
        bottom: -240px;
        right: -170px;
        width: 550px;
    }

    & .left-ellipse{
        position: absolute;
        width: 120px;
        top: 50px;
        left: 70px;
    }

    & .page-content {
        width: 50%;
        margin: 0 auto;
        max-width: 470px;

        @media (max-width: $md) {
            width: 90%;
        }
    }

    & .logo {
        display: block;
        width: 200px;
        margin: 20px auto 30px;
    }

    & input {
        width: 50%;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    & ::placeholder {
        color: #d8d8d8;
    }

    & .remember-me-row {
        display: flex;
        margin-top: 25px;
        margin-bottom: 5px;
    }

    & .remember-me-row span {
        display: block;
    }

    & .remember-me-row .container-checkbox-round {
        margin-right: 25px;
    }

    & .login-row {
        margin-top: 75px;
    }

    & input.input-error {
        border-bottom: 1px solid red;
    }
}