.thankYouContainer {
  margin: 0 auto;
  max-width: 2560px;


  .thankYouContent {
    margin-top: 70px;
    color: #634e70;
    text-align: center;
    background: url("../../static/images/desktop/home-favourite-products-background-missized.png") no-repeat;
    background-position: center 220px;
    background-size: auto 1500px;
    background-position-y: 150px;
    height: 1400px;
    li {
      display: inline-block;
      font-size: 20px;
      padding: 20px;
    }
    p {
      text-align: center;
      align-self: inherit;
      font-size: 20px;
      margin-bottom: 30px;
    }
    h2{
      margin-bottom: 20px;
    }
    & .SUCCESS_MESSAGE{
      color: red;
    }
  }
}
