.custom-cookies-consent-container {
    position: fixed;
    bottom: 5px;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.3);
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 6px 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 15px;
    font-weight: bold;
    flex-direction: column;

    & .ccc-accept-button {
        padding: 7px 15px;
        background: #007bff;
        color: #fff;
        border-radius: 10px;
        border: 1px solid #007bff;
        cursor: pointer;
    }

    & .ccc-details-button {
        padding: 7px 15px;
        background: #17a2b8;
        border-radius: 10px;
        border: 1px solid #17a2b8;
        color: #fff;
        margin-left: 10px;
        cursor: pointer;
    }

    & .ccc-actions {
        display: flex;
        align-items: center;
    }
    
    & .ccc-details-text {
        margin-right: 7px;
    }

    & .ccc-text-details {
        margin-right: 10px;
        margin-top: 5px;
        font-size: 12px;
        color: #777;
        font-weight: normal;
    }

    & .ccc-main-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}