#therapist-manage-articles {

    background: #f1f2f361;
    position: relative;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .search-row {
        display: flex;
        margin-top: 50px;
        padding-bottom: 25px;
        // border-bottom: 1px solid #ddd;
    }

    & .add-row {
        display: flex;
        margin-top:25px;
        padding-bottom: 35px;
        border-bottom: 1px solid #ddd;
    }

    & .add-row .leftside-content {
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: space-between;
    }

    & .leftside-content {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }

    & .filters {
        display: flex;
        margin-top: 20px;
        justify-content: center;
    }


    & .filters .filter-item {
        width: 150px;
        text-align: center;
        color: #457bd569;
        cursor: pointer;
    }

    & .filters .middle-filter {
        border-right: 3px solid #457bd5a8;
    }

    & .filters .filter-item.active-filter {
        color: #457bd5;
        font-weight: bold;
    }

    & .rightside-content {
        width: 30%;
        display: flex;
        justify-content: flex-end;
    }

    
    & .small-gradient-button {
        width: 90%;
        height: 50px;
    }

    & .small-gradient-button span {
        margin: 0;
    }

    & .article-search-input {
        width: 100%;
    }

    & .results-section {
        margin-top: 30px;
    }

    & .message-span-container {
        padding-left: 30px;
    }

    & .article-list-item {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 30px;
        padding: 20px 35px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        margin: 15px 0;
    }

    & .article-li-name {
        font-weight: 600;
    }

    & .article-li-description {
        font-size: 15px;
    }

    & .article-li-info {
        flex: 1;
        border-right: 1px solid #eee;
        display: flex;
        flex-wrap: wrap;
    }

    & .article-li-actions {
        width: 70px;
        display: flex;
        justify-content: space-evenly;
    }

    & .edit-icon:hover {
        color: #ffc107;
        cursor: pointer;
    }
    & .add-icon:hover {
        color: #28a745;
        cursor: pointer;
    }
    & .delete-icon:hover {
        color: #dc3545;
        cursor: pointer;
    }

    & .edit-sp-name, .edit-sp-description {
        width: 90%;
    }

    & .edit-sp-description{
        margin-top: 15px;
    }

    & ul {
        list-style-type: disc;
        list-style-position: inside;
    }

    & .attached-specialities-list {
        display: flex;
        flex-wrap: wrap;
    }

    & .article-at-item {
        display: flex;
        background: linear-gradient(to right,  #106ff1, #a723ec);
        // padding: 5px 15px;
        border-radius: 25px;
        align-items: center;
        margin: 5px 10px;
        justify-content: space-around;
        padding: 2px;
    }

    & .article-at-outer {
        display: flex;
        background: #fff;
        padding: 5px 15px;
        border-radius: 25px;
        align-items: center;
        height: 45px;
    }

    & .article-at-outer .article-at-info {
        height: 45px;
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    & .article-at-outer .article-at-info h4 {
        margin: 0
    }

    & .article-at-actions {
        height: 45px;
        display: flex;
        align-items: center;
    }

    & .attached-specialities-section {
        margin-top: 25px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 15px;
    }

    .accept-icon {
        color: green;
    }

    .decline-icon {
        color: red;
    }

    .article-tags {
        display: flex;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;
        width: 100%;
    }

    .article-tag {
        background-color: #eee;
        border-radius: 5px;
        color: #126ef1;
        font-weight: bold;
        font-size: 16px;
        margin: 5px;
        padding: 5px 10px;

        .delete-icon{
            margin-left: 10px;
            color: #dc3545;
        }
    }

    & .article-thumbnail-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
    }

    & .add-thumbnail-btn {
        width: 130px;
        height: 45px;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }

    & .art-thb-container {
        height: 150px;
        width: 150px;
        overflow: hidden;
        border-radius: 30px;
    }

    & .art-thb-container img {
        max-width: 100%;
        max-height: 100%;
    }

    & .article-li-info .thumbnail-container {
        width: 150px;
        height: 150px;
        overflow: hidden;
        border-radius: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 1px solid #efefef;
        margin-right: 20px;
    }

    & .article-li-info .thumbnail-container img {
        max-width: 100%;
        max-height: 100%;
    }

    & .article-li-title {
        color: #634e70;
        font-weight: bold;
    }

    & .article-li-body {
        font-size: 16px;
        padding-left: 10px;
        margin-top: 15px;
    }

    & .article-li-details {
        font-size: 13px;
    }

    & .data-container {
        flex: 1;
        max-height: 170px;
        overflow: hidden;
    }

    & .error-message {
        height: 35px;
        margin: 10px auto;
    }

    & .no-access-message {
        padding: 20px;
        font-size: 17px;
    }

    // & .bold-val {
    //     color: #634e70;
    //     font-weight: bold;
    // }

    & .plan-link {
        font-weight: bold;
        color: #126ef1;
    }

    & .pc-options-container {
        width: 100%;
        justify-content: center;
    }
}


@media (max-width: 580px) {
    #therapist-manage-articles {

        & .inner-content {
            width: 95%;
        }

        & .page-title {
            text-align: center;
        }

        & .article-list-item {
            padding: 15px;
        }

        & .article-list-item {
            padding: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        
        & .article-li-actions {
            width: 100%;
            margin-top: 30px;
        }

        & .article-li-info {
            flex-direction: column;
            align-items: center;
            width: 100%;
            border: none;
        }

        & .data-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
        }

        & .cooldown-message {
            text-align: center;
        }

        & .article-li-body {
            text-align: center;
        }
    }
}