
.media-icon-container {

    & .media-icon-outer {
        width: 50px;
        height: 50px;
        background: #eee;
        border-radius: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    & .media-icon-outer:hover {
        width: 52px !important;
        height: 52px !important;
        -webkit-box-shadow: -5px 3px 48px -2px rgba(0,0,0,0.75);
        -moz-box-shadow: -5px 3px 48px -2px rgba(0,0,0,0.75);
        box-shadow: -5px 3px 48px -2px rgba(0,0,0,0.75);
    }


    & .media-icon-outer.mi-gradient {
        padding: 2px
    }

    & .media-icon-icon.mi-gradient {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    & .media-icon-outer.mi-gradient:hover {
        width: 43px !important;
        height: 43px !important;
        -webkit-box-shadow: -5px 3px 48px -2px rgba(0,0,0,0.75);
        -moz-box-shadow: -5px 3px 48px -2px rgba(0,0,0,0.75);
        box-shadow: -5px 3px 48px -2px rgba(0,0,0,0.75);
    }

    & .footer-icon-circle {
        //width: 70px;
    }

    & .header-icon-circle {
        width: 40px;
        height: 40px;
    }
}

.media-icon-container.mi-gradient {
    margin: 5px;
    width: 40px;
}

@media (max-width: 993px) {
    .footer-icon-circle {
        width: 50px !important;
        height: 50px !important;
    }
}

@media (max-width: 370px) {
    .footer-icon-circle {
        width: 45px !important;
        height: 45px !important;
    }
}

