.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6,
.col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
  padding-left: 10px;
  padding-right: 10px;
}

.container {
    @media (min-width: 1200px) {
        max-width: 960px;
    }

    @media (max-width: 575px) {
        padding-left: 32px;
        padding-right: 32px;
    }
}

.row {
    margin-left: -10px;
    margin-right: -10px;
}
