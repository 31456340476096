@import "../../variables.scss";

.formDescription {
    color: #634e70;
    font-weight: 600;
    font-size: 25px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    &.home {
        color: $heading_purple;
    }
    @media (max-width: $md - 1px) {
        color: $heading_purple;
        text-align: center;
        margin-top: 0;
    }
}

.sponsored-therapist-list {
    margin-top: 20px;
    border-radius: 30px;
    padding: 10px 30px;
}

.sponsored-therapist-back {
    background-color: #a2e3f7;

}

.search-panel {
    .search-field--select {
        border-radius: 30px;
        max-width: 334px;
        margin: 0 auto 20px;
        width: 100%;
        padding: 2px;
        position: relative;
        color: dimgrey;
        display: none;
        &.error {
            display: initial;
            p{
            color: red;
            }
            .search-field--label {
                color: $error_red;
            }
            @media (max-width: $md) {
               padding-top: 70px;
            }
        }
        @media (min-width: $md) {
            flex: 0 0 48%;
            max-width: 48%;
            margin: 0 1% 30px;
        }
    }

    .search-field--bg {
        background-image: linear-gradient(to right, $btn_gradient_start 0%, $btn_gradient_end 100%);
        border-radius: 30px;
        max-width: 334px;
        margin: 0 auto 20px;
        width: 100%;
        padding: 2px;
        position: relative;
        color: dimgrey;
        &.error {
            background-image: linear-gradient(to right, $error_red 0%, $error_red 100%);

            .search-field--label {
                color: $error_red;
            }
        }

        @media (min-width: $md) {
            flex: 0 0 48%;
            max-width: 48%;
            margin: 0 1% 30px;
        }
    }

    .search-field--button {
        align-items: center;
        display: flex;
        flex-direction: row;
        text-align: left;

        @media (max-width: $md - 1) {
            margin: 0 auto;
            max-width: 334px;
            width: 100%;
        }
    }

    .search-field--button-container {
        @media (max-width: $md - 1) {
            padding: 0;
        }
    }

    .autocomplete-input {
        position: relative;

        input {
            width: 100%;
        }

        .autocomplete-input--list {
            background: #fff;
            border-radius: 3px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            margin-left: 5px;
            max-height: 250px;
            overflow-y: scroll;
            position: absolute;
            width: 100%;
            z-index: 2;

            .autocomplete-input--list-item {
                padding: 2px 10px;

                &.active {
                    background-image: linear-gradient(to right, $btn_gradient_start 0%, $btn_gradient_end 100%);
                    color: #fff;
                }
            }
        }

    }

    .no-bg-image {
        background-image: none;
    }


    // Search Field Styles
    & .item-suggest-outer {
        width: 100%;
        height: 60px;
        background: linear-gradient(to right, #106ff1, #a723ec);
        padding: 2px;
        border-radius: 30px;
    }

    & .item-suggest-inner, .loc-suggest-inner {
        display: flex;
        justify-content: flex-start;
        padding-left: 50px;
        align-items: center;
        height: 100%;
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
    }

    & .loc-suggest-inner {
        overflow: visible;
    }

    & .item-suggest-label {
        font-size: 25px;
        font-weight: 700;
        color: #126ef1;
        cursor: pointer;
    }

    & .item-suggest-input {
        flex: 1;
        margin-left: 20px;
        border: none;
        outline: none;
    }

    .suggested-specs-container {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 20px;
        position: absolute;
        width: 100%;
        top: 60px;
        z-index: 10;
        padding-top: 30px;
        display: block;
    }

    & .suggested-spec-item {
        display: flex;
        padding: 10px;
        flex-wrap: wrap;
    }

    & .speciality-select-box {
        margin-right: 20px;
    }

    & .google-places-autocomplete__input {
        background: transparent;
        box-shadow: none;
    }

    & .google-places-autocomplete__suggestions-container {
        width: 130%;
        left:-100px;
    }

    & .google-places-autocomplete__suggestion {
        cursor: pointer
    }

    & .google-places-autocomplete__suggestion:hover {
        background: #457bd5;
        color: #fff;
        font-weight: bold;
    }

    #ms-suggested-insurances {
        background: #fff;
        // background: #fff;
        border: 1px solid #ddd;
        border-radius: 20px;
        border-top: none;
        position: absolute;
        width: 100%;
    }

    & .suggested-ins-item {
        display: flex;
        justify-content: space-around;
        height: 40px;
        align-items: center;
        cursor: pointer;
    }

    & .suggested-insurances-list {
        margin: 0;
    }

    & .suggested-ins-item:hover {
        background: #457bd5;
        color: #fff;
        font-weight: bold;
    }

    & .hidden-element{
        display: none;
    }

    & .speciality-placeholder {
        width: 100%;
        display: flex;
        padding-left: 50px;
        justify-content: space-between;
        padding-right: 20px;
        cursor: pointer;
    }

    & .specialit-drop-arrow {
        color: #126ef1;
    }

    & .speciality-placeholder-text {
        color: dimgrey;
    }

    & .spec-list-close-btn {
        width: 100%;
        border: 1px solid #eee;
        margin-top: -30px;
        /* border-radius: 15px; */
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        justify-content: center;
    }

    & .spec-list-clos-btn-item {
        margin: 0 10px;
    }

    & .spec-list-close-icon {
        color: #dc3545;
    }

    & .spec-list-close-text {
        font-weight: bold;
        color: #ccc;
    }

}

.no-results-container {
    width: 80%;
    margin-top: 50px;
}

.no-results-text h3 {
    color: #634e70;
    font-size: 25px;
    font-weight: bold;
}

.no-results-text h4 {
    color: #634e70;
    font-size: 20px;
    line-height: 1.4;
    text-align: justify;
    padding-bottom: 15px;
}

.small-btn-container {
    width: 200px;
    margin-top: 15px;
}

.speciality-select-name {
    margin-bottom: 0;
}


@media (max-width: 580px) {
    .search-panel {

        // & .item-suggest-label, .riwl-label {
        //     display: none;
        // }

        & .item-suggest-inner, .loc-suggest-inner, .round-input-w-label-inner, .rd-input-inner {
            padding-left: 15px;
        }

        & .speciality-placeholder {
            padding: 10px;
        }

        & .riwl-label-text span {
            display: none;
        }

        & .riwl-label-text:after {
            content: 'Ins.';
        }

        & .google-places-autocomplete__suggestions-container {
            left: -65px;
        }

        & .suggested-spec-item {
            align-items: center;
            font-size: 15px;
        }

        & .speciality-select-name {
            flex: 1;
        }

    }
}
