.share-section {
    max-width: 960px;
    width: 80%;
    margin: 0 auto;
    a:hover {
      color: white;
  }


    & .share-sec-title {
        color: #634e70;
        font-size: 30px;
        font-weight: bold;
        margin: 0 0 30px;
    }

    & .share-container {
        display: flex;
    }

    & .share-display {
        padding: 5px 25px;
        background: #f7d7eb;
        border-radius: 20px;
        color: #634e70;
        margin: 0 10px;
    }

    & .mix-button {
        display: inline-block;
        color: #fff;
        background-color: purple;
        border-radius: 5px;
        line-height: 1.2em;
        margin: 0.5em;
        transition: 50ms ease-out;
        padding: 0.5em 0.75em;
        border: 1px solid #eee;
        outline: none;
    }

    & .mix-button:hover {
        background-color: darkviolet;
        color: white;
    }

    & .mix-image {
        width: 20px;
    }

}
