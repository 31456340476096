@import "../../variables.scss";

#productsPage {
    margin-top: 40px;

    .blog-text-ontop {
        padding-right: 40px; padding-top: 10px;
    }

    .page-title {
        text-align: center;
        font-size: 43px;
        margin-bottom: 130px;
        color: #ffff;
    }

    & input {
        outline: none;
    }

    .article-search--container {

        .article-search--input-background {
            background-image: linear-gradient(to right, $article_search_gradient_start 0%, $article_search_gradient_end 100%);
            border-radius: 30px;
            padding: 2px;
            position: relative;

            input {
                border: 0 none;
                border-radius: 30px;
                height: 54px;
                padding-left: 30px;
                padding-right: 70px;
                width: 100%;

                @media (max-width: $sm - 1) {
                    padding-left: 15px;
                    padding-right: 15px;
                }


                &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color: $link_blue;
                }
                &::-moz-placeholder { /* Firefox 19+ */
                    color: $link_blue;
                }
                &:-ms-input-placeholder { /* IE 10+ */
                    color: $link_blue;
                }
                &:-moz-placeholder { /* Firefox 18- */
                    color: $link_blue;
                }
            }

            .search-icon {
                cursor: pointer;
                position: absolute;
                right: 30px;
                top: 50%;
                transform: translateY(-50%);

                @media (max-width: $sm - 1) {
                    display: none;
                }
            }

        }
    }
    .filter-icon {
        cursor: pointer;
        margin: auto;

        @media (max-width: $sm - 1) {
            margin: auto 0 auto auto;
        }
    }

    & .current-page {
        color: black;
        cursor: default;
        background-color: white;
        
    }

    & .load-more-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 35px auto;
    }

    & .invisible-btn {
        display: none;
    }
    .pagination {
        justify-content: center;
    }

    .page-link {
        border: 0;
    }

    .page-item {
        cursor: pointer;
    }
}

@media (max-width: 450px) {
    #productsPage {

        & .article-card {
            max-width: 300px;
        }

        .articles-list {
            padding-left: 0;
        }
        
    }
}

@media (max-width: 750px) {
    #productsPage {

        & .blog-text-ontop {
            padding: 0;
        }

        & .page-title {
            text-align: center;
            padding-top: 5px;
            color: white;
            margin-bottom: 55px;
        }

        & .article-card {
            height: auto;
            
        }

        & .article-title {
            font-size: 20px;
            
        }

        & .article-content {
            flex-basis: 55%;
            padding: 20px 0px;
            flex-direction: column;
            align-items: center;
        }

        & .article--button-container {
            max-width: 100%;
            width: 100%;
        }

        & .art-small-thb {
            width: 100%;
        }

        & .article--content {
            width: 100%;
        }

        & .article--short-content {
            padding: 0;
            margin: 0 30px;
        }

    }
}
