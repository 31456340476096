@import "../../variables.scss";

.travel {
    &:hover {
        a {
            color: $heading_purple;
        }

        .travel--image {
            // box-shadow: 0 37px 48px rgba(0, 0, 0, 0.2);
            box-shadow: 0 10px 48px rgba(0, 0, 0, .7);
        }
    }

    &.travel-card {
        background-color: #fff;
        border: 1px solid $card_border_color;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;

        &:hover {
            border-color: #fff;
            box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);


            .travel--image {
                box-shadow: none;
            }
        }

        @media(max-width: $sm - 1) {
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
        }

        .travel--tag {
            padding: 0 35px;
            font-size: 16px;
        }

        .travel--title {
            color: $heading_purple;
            display: block;
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 auto;
            padding: 0 35px;
        }

        .travel--button-container {
            margin-top: 30px;
            padding: 0 35px;
            padding: 0 35px 35px;

            .travel--button {
                line-height: 48px;
            }
        }
    }

    .travel--title {
        color: $logo_blue;
        font-weight: 700;
    }

    .travel--image {
        margin-top: 20px;
        border-radius: 30px;
        margin-bottom: 20px;
        overflow: hidden;
    }
}
