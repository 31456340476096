.round-input-w-label-outer {
    width: 100%;
    height: 60px;
    background: linear-gradient(to right, #106ff1, #a723ec);
    padding: 2px;
    border-radius: 30px;

    & .round-input-w-label-inner {
        display: flex;
        justify-content: flex-start;
        padding-left: 50px;
        align-items: center;
        height: 100%;
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
    }

    & .riwl-label {
        font-size: 25px;
        font-weight: 700;
        color: #126ef1;
    }

    & .riwl-input-container {
        flex: 1 1;
        margin-left: 20px;
    }

    & .riwl-input {
        border:none;
        outline: none;
    }
}