@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import "./globalStyle.scss";

body {
    overflow-x: hidden;
    width: 100%;
  }

.grey-text {
    color: #969696;
    font-family: 'Montserrat', sans-serif;
}

.white-text {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.bold-text {
    font-weight: bold;
}

.underline-text {
    text-decoration: underline;
}
h2{
    font-family: 'Montserrat', sans-serif;
}
h1{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
}
.link-text {
    color: #5e8ddb;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
}

a {
    text-decoration: none;
}
p {
    line-height: 1.6;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
}
.error-message {
    color: red;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
}

.success-message {
    color: green;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
}

input.basic-input {
    outline: none;
    border: none;
    border-bottom: 1px solid #d8d8d8;
    padding: 15px 10px 0 10px;
    font-size: 16px;
    color: #888;
    font-family: 'Montserrat', sans-serif;
    background: transparent
}

.page-title {
    font-family: 'Montserrat', sans-serif;
    color: #634e70;
    font-size: 30px;
    font-weight: 600;
}

::placeholder {
    color: dimgrey;
    font-family: 'Montserrat', sans-serif;
}

.visible-item {
    display: block;
}

.hidden-item {
    display: none;
}

.flex-item {
    display: flex;
}

ul {
    //list-style-type: none;
    margin: 0 0 10px 0;
    font-family: 'Montserrat', sans-serif;
}

ol {
    margin-bottom: 10px;
}

.btn:hover {
    font-weight: bold;
    box-shadow: 0 20px 23px rgba(0, 0, 0, 0.5) !important;
    // height: 63px !important;
}

.ck h2 {
    font-size: 35px !important;
    font-weight: 300 !important;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 575px) {
        font-size: 28px!important;
    }
}

.ck.ck-editor__editable_inline {
    padding: 0;
}
