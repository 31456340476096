#admin-dashboard {

    background: #f1f2f361;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .cards-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 30px;
        margin-bottom: 60px;
    }

    & .top-row {
        display: flex;
        justify-content: space-between;

    }

    & .charts-background {
        background-color: #ffff;
        border: 1px solid #eee;
        box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 370px;
        border-radius: 30px;
        margin-bottom: 60px;
    }

    & .charts-names {
        display: flex;
        margin: 30px 45px 0 45px;
        justify-content: space-between;


    }

    & .chart-title {
        text-align: center;
        color: #b1b1b1;
        font-size: 16px;
        font-weight: 400;
        width: 25%;
        
        p {
            margin-bottom: 10px;
        }
    }

    & .chart-title:hover {
        color: #457bd5;
        font-weight: 700;
        cursor: pointer;
    }

    & .active {
        color: #457bd5;
        font-weight: 700;
    }

    & .chart {
        max-width: 750px;
        height: 153px;
        margin: 0 auto;
        margin-top: -70px;
    }
}

@media (max-width: 490px) {
    #admin-dashboard {

        & .title-row {
            flex-direction: column;
            align-items: center;
        }
        
    }
}