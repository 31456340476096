#faq {
   & .accordion{
     cursor: pointer;
   }
  & .faq-container {
    padding-top: 20px;
    width: 80%;
    max-width: 960px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 20px auto;
    flex-direction: column;
  }

  & .page-title {
    margin: 50px auto;
    color: white;
  }

  & .more-therapists{
    padding-top: 50px;
    text-align: center;
  }

  h2 {
    font-size: 1.5em !important;
  }

  h1 {
    display: block;
    font-size: 2em !important;
    font-weight: bold;
  }
}

@media (max-width: 780px) {
  #faq{

    & .page-title {
      margin: 0px auto;
      text-align: center;
    }

    h1 {
      font-size: 2em !important;
      font-weight: bold;
    }

    h2 {
      font-size: 1.5em !important;
      font-weight: bold;
      margin-left: -120px;
    }

    h2:nth-of-type(2) {
      margin-left: -190px;
    }

    .accordion {
      margin-left: 5px;
      width: 95%;
    }

    & .faq-container {
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

  }
}

@media (max-width: 480px) {
  #faq{

    h1 {
      display: block;
      font-size: 2em !important;
      font-weight: bold;
    }

    h2:first-of-type {
      margin-top: 140px;
      padding: 15px;
      font-size: 1.5em !important;
      margin-left: 0px; 
    }

    h2 {
      padding: 10px;
      font-size: 1.5em !important;
      margin-left: 0px !important; 
    }

    & .page-title {
      text-align: center;
    }

    & .faq-container {
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }

  }
}
