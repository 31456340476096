#therapist-public-profile {
  & .profile-details {
    max-width: 960px;
    width: 80%;
    margin: 30px auto 0 auto;
  }

  .write-review-section {
    max-width: 960px;
    width: 80%;
    margin: 20px auto;
  }

  & .leftside-content {
    width: 150px;
    margin-top: 200px;
  }

  & .leftside-content {
    & .avatar-container {
      width: 150px;
      height: 150px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 10px;
      line-height: 150px;
    }
  }

  & .avatar-container img {
    width: 150px;
    height: auto;
  }

//   & .profile-details {
//     display: flex;
//   }

  & .profile-section {
    width: 100%;
    display: flex;
  }

  & .more-therapists {
    margin: 40px auto 20px auto;
    text-align: center;
  }

  & .rightside-content {
    flex: 1;
    margin-left: 100px;
    max-width: 810px;
  }

  & .section-title {
    color: #634e70;
    // font-size: 35px;
    font-weight: 700;
    font-family: Montserrat;
  }

  & .profile-title {
    color: #ffff;
    font-size: 35px;
    font-weight: 700;
    font-family: Montserrat;
    margin-right: 10px;
    text-align: center;
  }

  & .profile-title-long {
    @media (max-width: 1024px) {
      font-size: 25px;
    }
  }

  & .subsection-title {
    color: #634e70;
    font-size: 25px;
    font-weight: 700;
    font-family: Montserrat;
    margin-bottom: 15px;
  }

  & .rev-list-item-name {
    font-weight: bold;
    color: #634e70;
    margin-bottom: 5px;
  }

  & .rev-list-item-comment {
    font-size: 17px;
  }

  & .rev-list-item-reply {
    cursor: pointer;
    color: #457bd5;
    max-width: max-content;
    margin-left: 2rem;
  }

  & .therapist-flag {
    font-size: 15px;
    margin: 10px auto;
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    color: #28a745;
    .green-icon {
      font-size: 25px;
    }
  }

  & .page-title {
      color: #ffff;
      margin-top: 50px;
      // justify-content: center;
  }

  .contact-form-section .input-container {
    margin-top: 30px;
  }

  .contact-form-section .input-row {
    margin-bottom: 30px;
  }

  .modal-imput {
    text-align: center;

    .basic-input {
      width: 90%;
    }
  }

  .networkin-subsection {
    margin-top: 20px;
  }

  .bio-data button {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }

  .contact-form-section {
    margin-top: 50px;
    width: 90%;
  }

  .modal-input {
    text-align: center;
  }

  .modal-input input {
    width: 80%;
  }

  .claim-button {
    margin-top: 40px;

    @media (max-width: 480px) {
      text-align: center;
    }
  }

  & .online-flag {
    font-size: 15px;
    margin: 10px auto;
    width: 70%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    color: #53d6d6;
    .green-icon {
      font-size: 25px;
    }
  }

  & .therapist-flag-text {
    margin-left: 5px;
  }

  & .reply-section {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }

  & .arrow-replies {
    margin-left: 10px;
    font-size: 2rem;
    cursor: pointer;
  }

  & .visible {
    display: inline;
  }

  & .hidden {
    display: none;
  }

  & .reply-form {
    margin-bottom: 20px;
  }

  & .review-row {
    margin-top: 10px;
    margin-bottom: 75px;
    color: #ffff;
  }

  & .section-title {
    font-size: 30px;
    font-weight: bold;
  }

  & .rev-f-row {
    width: 100%;
    margin: 0 auto 30px;
  }

  & .rev-f-half-input-container {
    width: 45%;
  }

  & .rev-f-half-input-container .basic-input {
    width: 100%;
  }

  & .rev-f-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  & .rev-f-full-input-container {
    width: 100%;
  }

  & .rev-f-full-input-container .basic-input {
    width: 100%;
  }

  & .art-rev-submit-btn {
    width: 250px;
    height: 55px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  & .ar-form-response-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  & .basic-input-err {
    border-bottom: 1px solid red;
  }

  & .review-row .review-data {
    margin-left: 15px;
  }

  & .specialities-section {
    margin-top: 20px;
  }

  & .specialities-list {
    display: flex;
    flex-wrap: wrap;
  }

  & .insurances-row {
    margin-top: 25px;
  }

  & .insurances-list {
    display: flex;
  }

  & .contact-details-section {
    margin-top: 25px;
  }

  & .phone-row {
    margin: 20px 0;
  }

  & .phone-row .cd-value,
  .email-row .cd-value,
  .web-row .cd-value {
    margin-left: 20px;
  }

  & .contact-row {
    margin-top: 25px;
  }

  & .small-btn {
    width: 200px;
    height: 50px;
  }

  & .submit-btn {
    margin-top: 30px;
  }

  & .full-width {
    width: 100%;
  }

  & .section-title {
      color: #634e70;
    }
  

  // & .rightside-input-container, .leftside-input-container {
  //     padding: 20px;
  // }

  & .review-form .input-row {
    display: flex;
    margin-bottom: 50px;
    margin-top: 30px;

    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }

  & .half-input {
    width: 400px;
    margin-right: 40px;
  }

  & .full-input {
    width: 840px;

    @media (max-width: 1023px) {
      width: 400px;
    }
  }

  & .error-message {
    color: #dc3545;
  }

  & .success-message {
    color: #28a745;
  }

  & .info-row {
    text-align: left;
  }
  & input.input-error {
    border-bottom: 1px solid red;
  }

  & .review-card {
    margin: 20px 20px 0 20px;
    padding: 10px;
    // border-bottom: 1px solid #457bd5;
    // padding-bottom: 40px;
  }

  & .review-card .full-name {
    font-weight: bold;
    color: #634e70;
    margin-bottom: 5px;
  }

  & .review-card .rating-container {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }

  & .review-card .date-container {
    font-size: 13px;
    margin-left: 15px;
  }

  & .review-card .review-container {
    font-size: 15px;
  }

  & .location-data {
    width: 80%;
    margin: 0 auto;
  }

  & .location-list {
    display: flex;
    flex-wrap: wrap;
  }

  & .location-item-small .sp-at-name {
    font-size: 15px;
  }

  & .location-item-small {
    cursor: pointer;
  }

  & .map-container {
    height: 300px;
    width: 100%;
    margin-top: 20px;
    border-radius: 20px;
    overflow: hidden;
  }
}

@media (max-width: 580px) {
  #therapist-public-profile {
    font-size: 22px;

    & .page-title {
      text-align: center;
      font-size: 22px;
      height: 160px;
      display: flex;
      overflow: hidden;
      justify-content: center;
    }

    & .profile-details {
      margin: 20px auto 0 auto;
    }

    & .profile-section {
      flex-direction: column;
      align-items: center;
    }

    & .avatar-container {
      margin-bottom: 40px;
    }
    & .leftside-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
    }

    & .rightside-content {
      margin: 0;
      width: 100%;
    }

    & .title-row {
      margin-top: -50px;
      flex-direction: column;
      align-items: center;
    }

    & .review-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #634e70;
      margin-bottom: 10px;
    }

    & .review-data {
      margin: 5px auto;
      text-align: center;
    }

    & .bio-section {
      display: flex;
      justify-content: center;
    }

    & .specialities-section {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    & .specialities-list {
      flex-direction: column;
      align-items: center;
      width: 95%;
      margin: 0 auto;
    }

    & .rlb-item {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    & .sp-at-name {
      font-size: 18px;
      text-align: center;
    }

    & .sp-at-outer {
      width: 100%;
    }
    & .section-title {
      font-weight: 300;
      color: #634e70;
      // font-size: 35px;
      font-family: Montserrat;
      text-align: center;
    }

    & .subsection-title {
      text-align: center;
    }

    & .contact-details-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .email-row,
    .phone-row,
    .web-row {
      max-width: 500px;
      overflow: auto;
      width: 85%;
      display: flex;
      align-items: center;
    }

    & .contact-row {
      width: 90%;
      display: flex;
      justify-content: center;
    }

    & .review-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & .input-row {
      width: 100%;
      flex-direction: column;
      align-items: center;
    }

    & .leftside-input-container,
    .rightside-input-container {
      width: 85%;
      display: flex;
      justify-content: center;
    }

    & .review-row {
      width: 85%;
      display: flex;
      justify-content: center;
    }

    & .basic-input.full-input {
      width: 100%;
    }

    & .specialities-list {
      flex-wrap: nowrap;
    }

    & .location-data {
      width: 85%;
      margin-top: 40px;
    }

    & .write-review-section {
      width: 85%;
    }
  }
}
