@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Overpass:400,700&display=swap);
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6,
.col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
  padding-left: 10px;
  padding-right: 10px; }

@media (min-width: 1200px) {
  .container {
    max-width: 960px; } }

@media (max-width: 575px) {
  .container {
    padding-left: 32px;
    padding-right: 32px; } }

.row {
  margin-left: -10px;
  margin-right: -10px; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Regular.ee653992.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-SemiBold.c641dbee.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Bold.ade91f47.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

body {
  color: #634e70;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  height: auto; }
  body.overflow-hidden {
    overflow: hidden; }
  body p {
    text-align: left !important; }

a {
  color: #457bd5;
  font-family: 'Montserrat', sans-serif; }
  a:hover {
    text-decoration: none; }

h1 {
  color: #634e70;
  font-size: 45px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif; }
  @media (max-width: 767px) {
    h1 {
      font-size: 35px; } }

h2 {
  color: #634e70;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 60px;
  font-family: 'Montserrat', sans-serif; }
  @media (max-width: 767px) {
    h2 {
      font-size: 35px; } }

.menu-navigation {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd; }
  .menu-navigation .navigate-links {
    display: flex;
    justify-content: space-between; }
  .menu-navigation .navigate-right {
    margin-right: 10px;
    text-align: right; }
  .menu-navigation .position-right {
    display: flex;
    justify-content: flex-end; }
  .menu-navigation .navigate-left {
    margin-left: 10px; }
  .menu-navigation .icon-left {
    display: none; }
  .menu-navigation .position-left {
    display: flex;
    justify-content: flex-start; }

.info-text {
  color: #634e70;
  display: flex;
  font-size: 12px;
  margin-top: 5px; }
  .info-text p {
    margin-left: 3px; }

.published-date {
  width: 170px; }

.btn.btn-default {
  background-image: -webkit-gradient(linear, left top, right top, from(#2441d1), to(#c380d6));
  background-image: linear-gradient(to right, #2441d1 0%, #c380d6 100%);
  border: 0 none;
  border-radius: 38px;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
  max-width: 300px;
  width: 100%;
  font-family: 'Montserrat', sans-serif; }
  .btn.btn-default.btn-small {
    max-width: 220px; }
  .btn.btn-default a {
    color: #fff;
    font-family: 'Montserrat', sans-serif; }
    .btn.btn-default a:hover {
      text-decoration: none; }

.m-width-600 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mb-30 {
  margin-bottom: 30px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-60 {
  margin-bottom: 60px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-50 {
  margin-top: 50px; }

.mt-80 {
  margin-top: 80px; }

.mt-120 {
  margin-top: 120px; }

.mt-180 {
  margin-top: 180px; }

.mt-200 {
  margin-top: 180px; }

@media (max-width: 767px) {
  .mt-ltmd-80 {
    margin-top: 80px; } }

.text-purple {
  color: #634e70;
  font-family: 'Montserrat', sans-serif; }

.text-white {
  color: #fff;
  font-family: 'Montserrat', sans-serif; }

@media (max-width: 575px) {
  .text-xs-left {
    padding-left: 32px;
    padding-right: 32px;
    text-align: left; } }

.container .text-xs-left {
  padding-left: 0;
  padding-right: 0; }

.custom-input {
  border-color: #d8d8d8;
  border-width: 0 0 1px 0;
  font-family: "Montserrat";
  font-size: 20px;
  margin-bottom: 60px;
  width: 100%; }
  .custom-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #d8d8d8; }
  .custom-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #d8d8d8; }
  .custom-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #d8d8d8; }
  .custom-input:-moz-placeholder {
    /* Firefox 18- */
    color: #d8d8d8; }
  @media (max-width: 767px) {
    .custom-input {
      margin-bottom: 30px; } }

.custom-radio--row {
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .custom-radio--row {
      margin-bottom: 30px; } }

body {
  overflow-x: hidden;
  width: 100%; }

.grey-text {
  color: #969696;
  font-family: 'Montserrat', sans-serif; }

.white-text {
  color: #fff;
  font-family: 'Montserrat', sans-serif; }

.bold-text {
  font-weight: bold; }

.underline-text {
  text-decoration: underline; }

h2 {
  font-family: 'Montserrat', sans-serif; }

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700; }

h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem; }

.link-text {
  color: #5e8ddb;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif; }

a {
  text-decoration: none; }

p {
  line-height: 1.6;
  text-align: left;
  font-family: 'Montserrat', sans-serif; }

.error-message {
  color: red;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px; }

.success-message {
  color: green;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px; }

input.basic-input {
  outline: none;
  border: none;
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 10px 0 10px;
  font-size: 16px;
  color: #888;
  font-family: 'Montserrat', sans-serif;
  background: transparent; }

.page-title {
  font-family: 'Montserrat', sans-serif;
  color: #634e70;
  font-size: 30px;
  font-weight: 600; }

::-webkit-input-placeholder {
  color: dimgrey;
  font-family: 'Montserrat', sans-serif; }

:-ms-input-placeholder {
  color: dimgrey;
  font-family: 'Montserrat', sans-serif; }

::-ms-input-placeholder {
  color: dimgrey;
  font-family: 'Montserrat', sans-serif; }

::placeholder {
  color: dimgrey;
  font-family: 'Montserrat', sans-serif; }

.visible-item {
  display: block; }

.hidden-item {
  display: none; }

.flex-item {
  display: flex; }

ul {
  margin: 0 0 10px 0;
  font-family: 'Montserrat', sans-serif; }

ol {
  margin-bottom: 10px; }

.btn:hover {
  font-weight: bold;
  box-shadow: 0 20px 23px rgba(0, 0, 0, 0.5) !important; }

.ck h2 {
  font-size: 35px !important;
  font-weight: 300 !important;
  margin-bottom: 1.5rem;
  margin-top: 3rem;
  font-family: 'Montserrat', sans-serif; }
  @media (max-width: 575px) {
    .ck h2 {
      font-size: 28px !important; } }

.ck.ck-editor__editable_inline {
  padding: 0; }

#admin-signin .page-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; }

#admin-signin .left-shape {
  position: absolute;
  width: 325px;
  top: -70px;
  left: -100px; }

#admin-signin .right-ellipse {
  position: absolute;
  width: 300px;
  top: -100px;
  right: -100px; }

#admin-signin .right-shape {
  position: absolute;
  bottom: -240px;
  right: -170px;
  width: 550px; }

#admin-signin .left-ellipse {
  position: absolute;
  width: 120px;
  top: 50px;
  left: 70px; }

#admin-signin .page-content {
  width: 50%;
  margin: 0 auto;
  max-width: 470px; }
  @media (max-width: 768px) {
    #admin-signin .page-content {
      width: 90%; } }

#admin-signin .logo {
  display: block;
  width: 200px;
  margin: 20px auto 30px; }

#admin-signin input {
  width: 50%;
  margin-bottom: 10px;
  margin-top: 5px; }

#admin-signin ::-webkit-input-placeholder {
  color: #d8d8d8; }

#admin-signin :-ms-input-placeholder {
  color: #d8d8d8; }

#admin-signin ::-ms-input-placeholder {
  color: #d8d8d8; }

#admin-signin ::placeholder {
  color: #d8d8d8; }

#admin-signin .remember-me-row {
  display: flex;
  margin-top: 25px;
  margin-bottom: 5px; }

#admin-signin .remember-me-row span {
  display: block; }

#admin-signin .remember-me-row .container-checkbox-round {
  margin-right: 25px; }

#admin-signin .login-row {
  margin-top: 75px; }

#admin-signin input.input-error {
  border-bottom: 1px solid red; }

#admin-sidebar {
  width: 0;
  height: 100%;
  position: absolute;
  background: #fff;
  z-index: 100; }
  #admin-sidebar ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  #admin-sidebar .sidebar-item {
    display: flex;
    justify-content: space-around;
    height: 50px;
    align-items: center;
    cursor: pointer; }
  #admin-sidebar .sidebar-item :hover {
    color: #126ef1; }
  #admin-sidebar .active-item {
    color: #126ef1; }
  #admin-sidebar .sidebar-items {
    width: 75px;
    background: #fff;
    height: 100%; }
  #admin-sidebar .sidebar-items a {
    color: #aaa; }
  #admin-sidebar .sdb-hamburger {
    height: 65px;
    width: 75px;
    margin: 0 auto;
    cursor: pointer;
    padding-top: 30px; }
  #admin-sidebar .sd-h-row {
    width: 30%;
    height: 4px;
    border-radius: 3px;
    margin: 3px auto;
    background: #126ef1; }
  #admin-sidebar .sidebar-hidden {
    display: none; }
  #admin-sidebar .hamburger-open {
    background: #fff; }

#admin-dashboard {
  background: #f1f2f361; }
  #admin-dashboard .page-background {
    width: 100%;
    min-height: 100vh; }
  #admin-dashboard h1 {
    margin: 0; }
  #admin-dashboard .page-content {
    display: flex; }
  #admin-dashboard .inner-content-container {
    flex: 1 1; }
  #admin-dashboard .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #admin-dashboard .cards-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 60px; }
  #admin-dashboard .top-row {
    display: flex;
    justify-content: space-between; }
  #admin-dashboard .charts-background {
    background-color: #ffff;
    border: 1px solid #eee;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 370px;
    border-radius: 30px;
    margin-bottom: 60px; }
  #admin-dashboard .charts-names {
    display: flex;
    margin: 30px 45px 0 45px;
    justify-content: space-between; }
  #admin-dashboard .chart-title {
    text-align: center;
    color: #b1b1b1;
    font-size: 16px;
    font-weight: 400;
    width: 25%; }
    #admin-dashboard .chart-title p {
      margin-bottom: 10px; }
  #admin-dashboard .chart-title:hover {
    color: #457bd5;
    font-weight: 700;
    cursor: pointer; }
  #admin-dashboard .active {
    color: #457bd5;
    font-weight: 700; }
  #admin-dashboard .chart {
    max-width: 750px;
    height: 153px;
    margin: 0 auto;
    margin-top: -70px; }

@media (max-width: 490px) {
  #admin-dashboard .title-row {
    flex-direction: column;
    align-items: center; } }

#header-user {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-left: 30px;
  width: 100%;
  background: #fff; }
  #header-user img.align-self {
    align-self: center; }
  #header-user .logo {
    width: 35px; }
  #header-user .header-content {
    width: 80%;
    display: flex;
    margin: 0 auto; }
  #header-user .avatar-img-container {
    width: 57px;
    height: 57px;
    overflow: hidden;
    border-radius: 50%;
    /* text-align: center; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 7px solid #eee;
    z-index: 1;
    cursor: pointer; }
  #header-user .avatar-img-container img {
    width: 100%;
    height: 100%; }
  #header-user .avatar-container {
    display: flex;
    align-items: center;
    position: relative; }
  #header-user .name-container {
    width: 57px;
    height: 57px;
    background: #eee;
    border-radius: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #126ef1;
    position: absolute;
    right: 45px;
    z-index: 1;
    cursor: pointer;
    border: 6px solid #fff; }
  #header-user .user-aac-options-container {
    position: absolute;
    width: 110px;
    height: 100px;
    background: #eee;
    border-radius: 25px;
    bottom: -90px;
    right: 10px; }
  #header-user ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  #header-user .aac-options {
    margin-top: 20px;
    text-align: center;
    list-style-type: none; }
  #header-user .spacer {
    flex: 1 1; }

.data-card {
  width: 20%;
  min-width: 185px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 30px;
  padding: 15px 35px 30px;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3); }
  .data-card .dc-icon-row {
    display: flex;
    justify-content: space-around; }
  .data-card .dc-icon-container {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 50%; }
  .data-card .dc-value-row {
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
  .data-card .dc-value {
    font-size: 35px;
    font-weight: bold; }
  .data-card .dc-label {
    font-size: 15px;
    text-align: center;
    color: #ccc; }
  .data-card .dc-label-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5px; }
  .data-card .dc-pink-background {
    background: rgba(255, 53, 153, 0.15); }
  .data-card .dc-pink-color {
    color: #ff3599; }
  .data-card .dc-blue-background {
    background: rgba(18, 110, 241, 0.15); }
  .data-card .dc-blue-color {
    color: #126ef1; }
  .data-card .dc-purple-background {
    background: rgba(165, 36, 236, 0.15); }
  .data-card .dc-purple-color {
    color: #a524ec; }
  .data-card .dc-orange-background {
    background: rgba(253, 149, 87, 0.15); }
  .data-card .dc-orange-color {
    color: #fd9557; }

@media (max-width: 490px) {
  .data-card {
    width: 100%;
    margin-bottom: 35px; } }

#admin-profile {
  background: #f1f2f361; }
  #admin-profile .page-background {
    width: 100%;
    min-height: 100vh; }
  #admin-profile h1 {
    margin: 0; }
  #admin-profile .page-content {
    display: flex; }
  #admin-profile .inner-content-container {
    flex: 1 1; }
  #admin-profile .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #admin-profile .second-row {
    display: flex; }
  #admin-profile .leftside-content {
    width: 30%; }
  #admin-profile .rightside-content {
    width: 70%; }
  #admin-profile .inner-content .leftside-content .avatar-container {
    width: 125px;
    height: 125px;
    background: #ddd;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    overflow: hidden;
    border: 1px solid #efefef;
    position: relative; }
  #admin-profile .inner-content .add-photo-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 31px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 15px auto; }
  #admin-profile .input-row input {
    width: 80%;
    max-width: 380px; }
  #admin-profile .form-container {
    margin-top: 50px; }
  #admin-profile .input-row {
    margin-bottom: 35px; }
  #admin-profile .submit-row {
    margin-top: 25px;
    margin-bottom: 50px; }
  #admin-profile input.input-error {
    border-bottom: 1px solid red; }
  #admin-profile .profile-img-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #admin-profile .profile-tabs {
    display: flex;
    flex-wrap: wrap; }
  #admin-profile textarea.basic-input {
    width: 80%;
    max-width: 380px;
    border: 1px solid #dddddd;
    outline: none;
    background: transparent;
    color: #cccccc;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 160px;
    text-indent: 5px; }
  #admin-profile .profile-tabs h1 {
    margin-right: 10px; }
  #admin-profile .tab-item {
    cursor: pointer; }
  #admin-profile .inactive-tab {
    color: #ddd; }
  #admin-profile .password-tab {
    width: 100%;
    margin-top: 50px; }

@media (max-width: 580px) {
  #admin-profile .tabs-bar {
    display: none; }
  #admin-profile .profile-tabs {
    justify-content: space-around; }
  #admin-profile .second-row {
    flex-direction: column;
    align-items: center; }
  #admin-profile .leftside-content {
    width: 80%;
    display: flex;
    justify-content: space-around; }
  #admin-profile .rightside-content {
    width: 90%; }
  #admin-profile .form-container .input-row {
    display: flex;
    justify-content: space-around; }
  #admin-profile .submit-row {
    display: flex;
    justify-content: space-around; }
  #admin-profile .page-title {
    text-align: center; } }

#admin-create-admin {
  background: #f1f2f361; }
  #admin-create-admin .page-background {
    width: 100%;
    min-height: 100vh; }
  #admin-create-admin h1 {
    font-weight: 600;
    margin: 0; }
  #admin-create-admin .page-content {
    display: flex; }
  #admin-create-admin .inner-content-container {
    flex: 1 1; }
  #admin-create-admin .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #admin-create-admin textarea.basic-input {
    width: 80%;
    max-width: 380px;
    border: 1px solid #dddddd;
    outline: none;
    background: transparent;
    color: #cccccc;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 160px;
    text-indent: 5px; }
  #admin-create-admin .second-row {
    display: flex; }
  #admin-create-admin .leftside-content {
    width: 30%; }
  #admin-create-admin .rightside-content {
    width: 70%; }
  #admin-create-admin .inner-content .leftside-content .avatar-container {
    width: 125px;
    height: 125px;
    background: #ddd;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    overflow: hidden;
    position: relative; }
  #admin-create-admin .inner-content .add-photo-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 31px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 15px auto; }
  #admin-create-admin .input-row input {
    width: 80%;
    max-width: 380px; }
  #admin-create-admin .form-container {
    margin-top: 50px; }
  #admin-create-admin .input-row {
    margin-bottom: 35px; }
  #admin-create-admin .submit-row {
    margin-top: 75px;
    margin-bottom: 50px; }
  #admin-create-admin .inner-content .leftside-content .avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  #admin-create-admin .inner-content .avatar-container .change-image-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    justify-content: space-around;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-family: "Montserrat";
    cursor: pointer; }
  #admin-create-admin .visible-item {
    display: block; }
  #admin-create-admin .hidden-item {
    display: none; }
  #admin-create-admin .flex-item {
    display: flex; }
  #admin-create-admin .selected-specialities .attached-specialities-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0; }
    #admin-create-admin .selected-specialities .attached-specialities-list .sp-at-item {
      display: flex;
      justify-content: space-around;
      padding: 2px; }
      #admin-create-admin .selected-specialities .attached-specialities-list .sp-at-item .sp-at-outer {
        display: flex;
        align-items: center; }
      #admin-create-admin .selected-specialities .attached-specialities-list .sp-at-item .sp-at-actions {
        display: flex;
        align-items: center;
        margin-left: 5px; }
  #admin-create-admin #add-locations .attached-locations-section ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0; }
  #admin-create-admin #add-locations .loc-at-item {
    list-style: none;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-right: 10px; }
  #admin-create-admin #add-locations .loc-at-outer {
    display: flex;
    align-items: center; }
  #admin-create-admin #add-locations .loc-at-info {
    display: flex;
    align-items: center;
    padding-right: 10px; }
  #admin-create-admin .search-select {
    margin-top: 20px; }
    #admin-create-admin .search-select .search-field--select {
      max-width: 100%;
      margin: 0 auto 20px;
      width: 100%;
      padding: 2px;
      position: relative;
      color: dimgrey;
      display: none; }
      #admin-create-admin .search-select .search-field--select.error {
        display: inline;
        display: initial; }
        #admin-create-admin .search-select .search-field--select.error p {
          color: red; }
        #admin-create-admin .search-select .search-field--select.error .search-field--label {
          color: #dc3545; }
        @media (max-width: 768px) {
          #admin-create-admin .search-select .search-field--select.error {
            padding-top: 70px; } }
      @media (min-width: 768px) {
        #admin-create-admin .search-select .search-field--select {
          flex: 0 0 48%;
          max-width: 100%; } }
    #admin-create-admin .search-select .search-field--bg {
      max-width: 100%;
      width: 100%;
      position: relative;
      color: dimgrey; }
      #admin-create-admin .search-select .search-field--bg.error {
        background-image: -webkit-gradient(linear, left top, right top, from(#dc3545), to(#dc3545));
        background-image: linear-gradient(to right, #dc3545 0%, #dc3545 100%); }
        #admin-create-admin .search-select .search-field--bg.error .search-field--label {
          color: #dc3545; }
      @media (min-width: 768px) {
        #admin-create-admin .search-select .search-field--bg {
          flex: 0 0 48%;
          max-width: 100%; } }
    #admin-create-admin .search-select .search-field--button {
      align-items: center;
      display: flex;
      flex-direction: row;
      text-align: left; }
      @media (max-width: 767px) {
        #admin-create-admin .search-select .search-field--button {
          margin: 0 auto;
          max-width: 334px;
          width: 100%; } }
    @media (max-width: 767px) {
      #admin-create-admin .search-select .search-field--button-container {
        padding: 0; } }
    #admin-create-admin .search-select .autocomplete-input {
      position: relative; }
      #admin-create-admin .search-select .autocomplete-input input {
        width: 100%; }
      #admin-create-admin .search-select .autocomplete-input .autocomplete-input--list {
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        margin-left: 5px;
        max-height: 250px;
        overflow-y: scroll;
        position: absolute;
        width: 100%;
        z-index: 2; }
        #admin-create-admin .search-select .autocomplete-input .autocomplete-input--list .autocomplete-input--list-item {
          padding: 2px 10px; }
          #admin-create-admin .search-select .autocomplete-input .autocomplete-input--list .autocomplete-input--list-item.active {
            color: #fff; }
    #admin-create-admin .search-select .no-bg-image {
      background-image: none; }
    #admin-create-admin .search-select .item-suggest-outer {
      width: 100%;
      height: 40px;
      padding: 2px;
      border-radius: 25px;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09); }
    #admin-create-admin .search-select .item-suggest-inner,
    #admin-create-admin .search-select .loc-suggest-inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 21px 0;
      background: #fff;
      border-radius: 25px;
      overflow: hidden; }
    #admin-create-admin .search-select .loc-suggest-inner {
      overflow: visible; }
    #admin-create-admin .search-select .item-suggest-label {
      display: none; }
    #admin-create-admin .search-select .item-suggest-input {
      flex: 1 1;
      margin-left: 20px;
      border: none;
      outline: none; }
    #admin-create-admin .search-select .suggested-specs-container {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 20px;
      position: absolute;
      width: 100%;
      z-index: 10;
      padding-top: 20px;
      display: block; }
    #admin-create-admin .search-select .suggested-spec-item {
      display: flex;
      padding: 3px;
      flex-wrap: wrap; }
    #admin-create-admin .search-select .speciality-select-box {
      margin-right: 20px; }
    #admin-create-admin .search-select .hidden-element {
      display: none; }
    #admin-create-admin .search-select .speciality-placeholder {
      width: 100%;
      display: flex;
      padding-left: 10px;
      justify-content: space-between;
      padding-right: 20px;
      cursor: pointer; }
    #admin-create-admin .search-select .specialit-drop-arrow {
      color: #126ef1; }
    #admin-create-admin .search-select .speciality-placeholder-text {
      color: dimgrey; }
    #admin-create-admin .search-select .spec-list-close-btn {
      width: 100%;
      border: 1px solid #eee;
      margin-top: -30px;
      /* border-radius: 15px; */
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      justify-content: center; }
    #admin-create-admin .search-select .spec-list-clos-btn-item {
      margin: 0 10px; }
    #admin-create-admin .search-select .spec-list-close-icon {
      color: #dc3545; }
    #admin-create-admin .search-select .spec-list-close-text {
      font-weight: bold;
      color: #ccc; }

@media (max-width: 580px) {
  #admin-create-admin .second-row {
    flex-direction: column; }
  #admin-create-admin .leftside-content,
  #admin-create-admin .rightside-content {
    width: 100%; }
  #admin-create-admin .avatar-container {
    margin: 0 auto 50px auto; }
  #admin-create-admin .search-panel .item-suggest-inner,
  #admin-create-admin .search-panel .loc-suggest-inner,
  #admin-create-admin .search-panel .round-input-w-label-inner,
  #admin-create-admin .search-panel .rd-input-inner {
    padding-left: 15px; }
  #admin-create-admin .search-panel .speciality-placeholder {
    padding: 10px; }
  #admin-create-admin .search-panel .riwl-label-text span {
    display: none; }
  #admin-create-admin .search-panel .riwl-label-text:after {
    content: "Ins."; }
  #admin-create-admin .search-panel .suggested-spec-item {
    align-items: center;
    font-size: 15px; }
  #admin-create-admin .search-panel .speciality-select-name {
    flex: 1 1; }
  #admin-create-admin .search-panel .page-title {
    text-align: center; }
  #admin-create-admin .search-panel .rightside-content {
    width: 100%; }
  #admin-create-admin .search-panel .input-row {
    display: flex;
    justify-content: space-around; }
  #admin-create-admin .search-panel .submit-row {
    display: flex;
    justify-content: space-around; } }

.container-checkbox-round .round {
  position: relative;
  display: flex;
  align-items: center; }

.container-checkbox-round .round input[type="checkbox"] {
  margin-right: 10px; }

.container-checkbox-round .round input[type="checkbox"]:hover {
  cursor: pointer; }

.container-checkbox-round .round label:hover {
  cursor: pointer; }

.container-checkbox-round body {
  background-color: #f1f2f3; }

#admin-manage-specialities {
  background: #f1f2f361;
  position: relative; }
  #admin-manage-specialities .page-background {
    width: 100%;
    min-height: 100vh; }
  #admin-manage-specialities h1 {
    margin: 0; }
  #admin-manage-specialities .page-content {
    display: flex; }
  #admin-manage-specialities .inner-content-container {
    flex: 1 1; }
  #admin-manage-specialities .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #admin-manage-specialities .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd; }
  #admin-manage-specialities .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd; }
  #admin-manage-specialities .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between; }
  #admin-manage-specialities .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #admin-manage-specialities .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #admin-manage-specialities .small-gradient-button {
    width: 90%;
    height: 50px;
    text-align: center; }
  #admin-manage-specialities .small-gradient-button span {
    margin: 0; }
  #admin-manage-specialities .sp-search-input {
    width: 90%; }
  #admin-manage-specialities .results-section {
    margin-top: 30px; }
  #admin-manage-specialities .message-span-container {
    padding-left: 30px; }
  #admin-manage-specialities .sp-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #admin-manage-specialities .sp-li-name {
    font-weight: 600; }
  #admin-manage-specialities .sp-li-description {
    font-size: 15px; }
  #admin-manage-specialities .sp-li-info {
    flex: 1 1;
    border-right: 1px solid #eee; }
  #admin-manage-specialities .sp-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #admin-manage-specialities .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #admin-manage-specialities .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #admin-manage-specialities .update-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #admin-manage-specialities .edit-sp-name, #admin-manage-specialities .edit-sp-description {
    width: 90%; }
  #admin-manage-specialities .edit-sp-description {
    margin-top: 15px; }

@media (max-width: 580px) {
  #admin-manage-specialities .page-title {
    text-align: center; }
  #admin-manage-specialities .search-row {
    flex-direction: column;
    align-items: center; }
  #admin-manage-specialities .leftside-content {
    width: 100%;
    justify-content: space-around; }
  #admin-manage-specialities .rightside-content {
    width: 100%;
    justify-content: space-around;
    margin-top: 20px; }
  #admin-manage-specialities .add-row {
    flex-direction: column;
    align-items: center; }
  #admin-manage-specialities .add-row .leftside-content {
    align-items: center; } }

.round-input-gradient {
  width: 350px;
  position: relative; }
  .round-input-gradient .input-gradient-border-container {
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    padding: 1px;
    border-radius: 30px; }
  .round-input-gradient .input-gradient-inner-container {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 10px 0;
    border-radius: 30px;
    padding-left: 25px;
    overflow: hidden;
    display: flex; }
  .round-input-gradient .input-gradient-inner-container label {
    margin-right: 15px;
    color: #126ef1;
    font-size: 25px;
    max-width: 100px; }
  .round-input-gradient .input-gradient-inner-container input {
    border: none;
    outline: none;
    font-size: 20px;
    width: 100%; }
  .round-input-gradient .invalid-input {
    background: red; }

.search-field--button {
  background-image: -webkit-gradient(linear, right top, left top, from(#a723ec), to(#106ff1));
  background-image: linear-gradient(270deg, #a723ec 0%, #106ff1 100%);
  font-size: 20px;
  text-align: left; }
  .search-field--button span {
    margin-left: 85px; }
    @media (max-width: 767px) {
      .search-field--button span {
        margin-left: auto; } }
  .search-field--button img {
    margin-left: 45px; }
    @media (max-width: 767px) {
      .search-field--button img {
        margin-left: 45px;
        margin-right: auto; } }

#admin-manage-insurances {
  background: #f1f2f361;
  position: relative; }
  #admin-manage-insurances .page-background {
    width: 100%;
    min-height: 100vh; }
  #admin-manage-insurances h1 {
    margin: 0; }
  #admin-manage-insurances .page-content {
    display: flex; }
  #admin-manage-insurances .inner-content-container {
    flex: 1 1; }
  #admin-manage-insurances .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #admin-manage-insurances .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd; }
  #admin-manage-insurances .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd; }
  #admin-manage-insurances .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: space-between; }
  #admin-manage-insurances .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #admin-manage-insurances .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #admin-manage-insurances .small-gradient-button {
    width: 90%;
    height: 50px; }
  #admin-manage-insurances .small-gradient-button span {
    margin: 0; }
  #admin-manage-insurances .sp-search-input {
    width: 90%; }
  #admin-manage-insurances .results-section {
    margin-top: 30px; }
  #admin-manage-insurances .message-span-container {
    padding-left: 30px; }
  #admin-manage-insurances .sp-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #admin-manage-insurances .sp-li-name {
    font-weight: 600; }
  #admin-manage-insurances .sp-li-description {
    font-size: 15px; }
  #admin-manage-insurances .sp-li-info {
    flex: 1 1;
    border-right: 1px solid #eee; }
  #admin-manage-insurances .sp-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #admin-manage-insurances .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #admin-manage-insurances .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #admin-manage-insurances .update-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #admin-manage-insurances .edit-sp-name, #admin-manage-insurances .edit-sp-description {
    width: 90%; }
  #admin-manage-insurances .edit-sp-description {
    margin-top: 15px; }

@media (max-width: 580px) {
  #admin-manage-insurances .page-title {
    text-align: center; }
  #admin-manage-insurances .search-row, #admin-manage-insurances .add-row {
    flex-direction: column;
    align-items: center; }
  #admin-manage-insurances .leftside-content {
    width: 100%;
    justify-content: space-around; }
  #admin-manage-insurances .rightside-content {
    width: 80%;
    justify-content: space-around;
    margin-top: 25px; } }

#admin-manage-articles {
  background: #f1f2f361;
  position: relative; }
  #admin-manage-articles .page-background {
    width: 100%;
    min-height: 100vh; }
  #admin-manage-articles h1 {
    margin: 0; }
  #admin-manage-articles .page-content {
    display: flex; }
  #admin-manage-articles .inner-content-container {
    flex: 1 1; }
  #admin-manage-articles .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #admin-manage-articles .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd; }
  #admin-manage-articles .filters {
    display: flex;
    margin-top: 20px;
    justify-content: center; }
  #admin-manage-articles .filters .filter-item {
    width: 150px;
    text-align: center;
    color: #457bd569;
    cursor: pointer; }
  #admin-manage-articles .input-width {
    width: 80%; }
  #admin-manage-articles .type-input-width {
    width: 30%; }
  #admin-manage-articles .textarea-row {
    flex-direction: column;
    align-items: center; }
  #admin-manage-articles .filters .filter-item.active-filter {
    color: #457bd5;
    font-weight: bold; }
  #admin-manage-articles .filters .middle-filter {
    border-right: 3px solid #457bd5a8; }
  #admin-manage-articles .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd; }
  #admin-manage-articles .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: space-between; }
  #admin-manage-articles .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #admin-manage-articles .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #admin-manage-articles .small-gradient-button {
    width: 90%;
    height: 50px;
    text-align: center; }
  #admin-manage-articles .small-gradient-button span {
    margin: 0; }
  #admin-manage-articles .sp-search-input {
    width: 90%; }
  #admin-manage-articles ul {
    list-style-type: disc;
    list-style-position: inside; }
  #admin-manage-articles .results-section {
    margin-top: 30px; }
  #admin-manage-articles .message-span-container {
    padding-left: 30px; }
  #admin-manage-articles .sp-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #admin-manage-articles .sp-li-name {
    font-weight: 600; }
  #admin-manage-articles .sp-li-description {
    font-size: 15px; }
  #admin-manage-articles .sp-li-info {
    flex: 1 1;
    border-right: 1px solid #eee; }
  #admin-manage-articles .sp-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #admin-manage-articles .accept-icon {
    cursor: pointer; }
    #admin-manage-articles .accept-icon.green {
      color: green; }
    #admin-manage-articles .accept-icon:hover {
      color: green; }
  #admin-manage-articles .decline-icon {
    cursor: pointer; }
    #admin-manage-articles .decline-icon.red {
      color: red; }
    #admin-manage-articles .decline-icon:hover {
      color: red; }
  #admin-manage-articles .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #admin-manage-articles .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #admin-manage-articles .update-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #admin-manage-articles .edit-sp-name, #admin-manage-articles .edit-sp-description {
    width: 90%; }
  #admin-manage-articles .edit-sp-description {
    margin-top: 15px; }
  #admin-manage-articles .article-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #admin-manage-articles .article-li-info {
    flex: 1 1;
    border-right: 1px solid #eee;
    display: flex;
    flex-wrap: wrap; }
  #admin-manage-articles .article-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #admin-manage-articles .article-tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    width: 100%; }
  #admin-manage-articles .article-tag {
    background-color: #eee;
    border-radius: 5px;
    color: #126ef1;
    font-weight: bold;
    font-size: 16px;
    margin: 5px;
    padding: 5px 10px; }
    #admin-manage-articles .article-tag .delete-icon {
      margin-left: 10px;
      color: #dc3545; }
  #admin-manage-articles .article-thumbnail-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px; }
  #admin-manage-articles .add-thumbnail-btn {
    width: 130px;
    height: 45px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
  #admin-manage-articles .art-thb-container {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 30px; }
  #admin-manage-articles .art-thb-container img {
    max-width: 100%;
    max-height: 100%; }
  #admin-manage-articles .article-li-info .thumbnail-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #efefef;
    margin-right: 20px; }
  #admin-manage-articles .article-li-info .thumbnail-container img {
    max-width: 100%;
    max-height: 100%; }
  #admin-manage-articles .article-li-title {
    color: #634e70;
    font-weight: bold; }
  #admin-manage-articles .article-li-body {
    font-size: 16px;
    padding-left: 10px;
    margin-top: 15px; }
  #admin-manage-articles .article-li-details {
    font-size: 13px; }
  #admin-manage-articles .data-container {
    flex: 1 1;
    max-height: 170px;
    overflow: hidden;
    max-width: 500px; }
  #admin-manage-articles .error-message {
    height: 35px;
    margin: 10px auto; }

@media (max-width: 580px) {
  #admin-manage-articles .page-title {
    text-align: center; }
  #admin-manage-articles .add-article-btn-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px; }
  #admin-manage-articles .search-row {
    flex-direction: column;
    align-items: center; }
  #admin-manage-articles .leftside-content {
    width: 100%;
    justify-content: space-around; }
  #admin-manage-articles .rightside-content {
    width: 100%;
    justify-content: space-around;
    margin-top: 20px; }
  #admin-manage-articles .article-list-item {
    flex-direction: column;
    align-items: center; }
  #admin-manage-articles .art-item-actions-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 35px; }
  #admin-manage-articles .article-li-actions {
    width: 50%; }
  #admin-manage-articles .article-li-info {
    flex-direction: column;
    align-items: center;
    border: none;
    width: 100%; }
  #admin-manage-articles .data-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px; } }

.ck h2 {
  margin-bottom: 1.5rem;
  margin-top: 3rem; }

#manage-admins {
  background: #f1f2f361;
  position: relative; }
  #manage-admins .page-background {
    width: 100%;
    min-height: 100vh; }
  #manage-admins h1 {
    margin: 0; }
  #manage-admins .page-content {
    display: flex; }
  #manage-admins .inner-content-container {
    flex: 1 1; }
  #manage-admins .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #manage-admins .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd; }
  #manage-admins .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #manage-admins .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #manage-admins .small-gradient-button {
    width: 90%;
    height: 50px;
    text-align: center; }
  #manage-admins .small-gradient-button span {
    margin: 0; }
  #manage-admins .sp-search-input {
    width: 90%; }
  #manage-admins .results-section {
    margin-top: 30px; }
  #manage-admins .message-span-container {
    padding-left: 30px; }
  #manage-admins .ai-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 30px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #manage-admins .ai-image-container {
    width: 130px;
    height: 130px;
    border-radius: 15px;
    overflow: hidden; }
  #manage-admins .ai-image-container img {
    width: 100%;
    height: 100%; }
  #manage-admins .ai-info-container {
    flex: 1 1;
    padding-left: 50px; }
  #manage-admins .ai-actions-container {
    width: 120px; }
  #manage-admins .ai-main-info {
    font-size: 25px;
    display: flex;
    justify-content: flex-start;
    color: #634e70; }
  #manage-admins .ai-main-info .ai-u-name {
    font-weight: 600; }
  #manage-admins .ai-details-info {
    margin-top: 10px;
    font-size: 17px;
    color: #634e70; }
  #manage-admins .ai-detail-container {
    display: flex; }
  #manage-admins .ai-detail-label {
    width: 75px;
    font-weight: 600; }
  #manage-admins .ai-actions-container {
    display: flex;
    justify-content: space-evenly; }
  #manage-admins .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #manage-admins .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #manage-admins .update-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #manage-admins .edit-sp-name, #manage-admins .edit-sp-description {
    width: 90%; }
  #manage-admins .edit-sp-description {
    margin-top: 15px; }

@media (max-width: 580px) {
  #manage-admins .page-title {
    text-align: center; }
  #manage-admins .search-row {
    flex-direction: column;
    align-items: center; }
  #manage-admins .search-row .leftside-content {
    width: 100%;
    justify-content: space-around; }
  #manage-admins .search-row .rightside-content {
    width: 80%;
    justify-content: space-around;
    margin-top: 25px; }
  #manage-admins .ai-list-item {
    flex-direction: column;
    align-items: center; }
  #manage-admins .ai-info-container {
    padding-left: 0; }
  #manage-admins .ai-main-info {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px; } }

#admin-signin .page-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1; }

#admin-signin .left-shape {
  position: absolute;
  width: 325px;
  top: -70px;
  left: -100px; }

#admin-signin .right-ellipse {
  position: absolute;
  width: 300px;
  top: -100px;
  right: -100px; }

#admin-signin .right-shape {
  position: absolute;
  bottom: -240px;
  right: -170px;
  width: 550px; }

#admin-signin .left-ellipse {
  position: absolute;
  width: 120px;
  top: 50px;
  left: 70px; }

#admin-signin .page-content {
  width: 50%;
  margin: 0 auto;
  max-width: 470px; }
  @media (max-width: 768px) {
    #admin-signin .page-content {
      width: 90%;
      text-align: center; } }

#admin-signin .logo {
  display: block;
  width: 200px;
  margin: 20px auto 30px; }

#admin-signin input {
  width: 50%;
  margin-bottom: 10px;
  margin-top: 5px; }

#admin-signin ::-webkit-input-placeholder {
  color: #d8d8d8; }

#admin-signin :-ms-input-placeholder {
  color: #d8d8d8; }

#admin-signin ::-ms-input-placeholder {
  color: #d8d8d8; }

#admin-signin ::placeholder {
  color: #d8d8d8; }

#admin-signin .remember-me-row {
  display: flex;
  margin-top: 25px;
  margin-bottom: 5px; }

#admin-signin .remember-me-row span {
  display: block; }

#admin-signin .remember-me-row .container-checkbox-round {
  margin-right: 25px; }

#admin-signin .login-row {
  margin-top: 75px; }

#admin-signin input.input-error {
  border-bottom: 1px solid red; }

#admin-signin .page-description {
  margin: 50px auto 30px; }

#admin-signin .successfull-text {
  margin-top: 200px; }

#support {
  background: #f1f2f361; }
  #support .page-background {
    width: 100%;
    min-height: 100vh; }
  #support h1 {
    margin: 0; }
  #support .page-content {
    display: flex; }
  #support .inner-content-container {
    flex: 1 1; }
  #support .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #support .input-row input {
    width: 80%;
    max-width: 380px; }
  #support .form-container {
    margin-top: 50px; }
  #support .input-row {
    margin-bottom: 35px; }
  #support .submit-row {
    margin-top: 75px; }
  #support input.input-error {
    border-bottom: 1px solid red; }
  #support .submit-button {
    width: 200px;
    height: 50px; }

@media (max-width: 580px) {
  #support .page-title {
    text-align: center; }
  #support .input-row {
    display: flex;
    justify-content: space-around; } }

#manage-therapists {
  background: #f1f2f361;
  position: relative; }
  #manage-therapists .page-background {
    width: 100%;
    min-height: 100vh; }
  #manage-therapists h1 {
    margin: 0; }
  #manage-therapists .page-content {
    display: flex; }
  #manage-therapists .inner-content-container {
    flex: 1 1; }
  #manage-therapists .datepicker {
    display: flex;
    flex-wrap: wrap; }
  #manage-therapists .date-input {
    margin-right: 10px; }
  #manage-therapists .load-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px auto; }
  #manage-therapists .invisible-btn {
    display: none; }
  #manage-therapists .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #manage-therapists .search-row {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap; }
  #manage-therapists .search-row .content-half {
    width: calc(50% - 20px);
    padding: 10px; }
  #manage-therapists .content-half .t-search-input {
    width: 100%;
    margin-bottom: 25px; }
  #manage-therapists .t-search-button {
    width: 200px;
    height: 50px;
    text-align: center; }
  #manage-therapists .t-search-button span {
    margin: 0; }
  #manage-therapists .search-position {
    font-size: 10px; }
  #manage-therapists .sp-search-input {
    width: 90%; }
  #manage-therapists .results-section {
    margin-top: 30px; }
  #manage-therapists .message-span-container {
    padding-left: 30px; }
  #manage-therapists .ai-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 30px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #manage-therapists .ai-image-container {
    width: 130px;
    height: 130px;
    border-radius: 15px;
    overflow: hidden; }
  #manage-therapists .ai-image-container img {
    width: 100%;
    height: 100%; }
  #manage-therapists .ai-info-container {
    flex: 1 1;
    padding-left: 50px; }
  #manage-therapists .ai-actions-container {
    width: 120px; }
  #manage-therapists .ai-main-info {
    font-size: 25px;
    display: flex;
    justify-content: flex-start;
    color: #634e70; }
  #manage-therapists .ai-main-info .ai-u-name {
    font-weight: 600; }
  #manage-therapists .ai-details-info {
    margin-top: 10px;
    font-size: 17px;
    color: #634e70; }
  #manage-therapists .ai-detail-container {
    display: flex; }
  #manage-therapists .ai-detail-label {
    width: 75px;
    font-weight: 600; }
  #manage-therapists .ai-actions-container {
    display: flex;
    justify-content: space-evenly; }
  #manage-therapists .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #manage-therapists .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #manage-therapists .update-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #manage-therapists .view-icon {
    color: #aaa; }
  #manage-therapists .green-icon {
    color: #28a745;
    cursor: pointer; }
  #manage-therapists .red-icon {
    color: #dc3545;
    cursor: pointer; }
  #manage-therapists .yellow-icon {
    color: #ffc107;
    cursor: pointer; }
  #manage-therapists .view-icon:hover {
    color: #007bff;
    cursor: pointer; }
  #manage-therapists .edit-sp-name, #manage-therapists .edit-sp-description {
    width: 90%; }
  #manage-therapists .edit-sp-description {
    margin-top: 15px; }
  #manage-therapists .suggest-container {
    position: relative; }
  #manage-therapists .autosugest-input {
    z-index: 2; }
  #manage-therapists .suggested-specs-container {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 20px;
    position: absolute;
    width: 100%;
    top: 25px;
    z-index: 1;
    padding-top: 30px;
    display: block; }
  #manage-therapists .hidden-element {
    display: none; }
  #manage-therapists .suggested-spec-item {
    display: flex;
    padding: 10px;
    flex-wrap: wrap; }
  #manage-therapists .suggested-specs-container .speciality-select-box {
    margin-right: 20px; }
  #manage-therapists .tr-reviews-value {
    margin-left: 20px; }
  #manage-therapists .tr-reviews-row {
    margin-bottom: 15px; }

@media (max-width: 580px) {
  #manage-therapists .admin-list {
    padding-left: 0; }
  #manage-therapists .page-title {
    text-align: center; }
  #manage-therapists .inner-content-container {
    max-width: 100%; }
  #manage-therapists .search-row {
    flex-direction: column;
    align-items: center; }
  #manage-therapists .search-row .content-half {
    width: 100%; }
  #manage-therapists .search-row .rightside-content {
    flex-direction: column;
    align-items: center; }
  #manage-therapists .suggest-container {
    width: 100%; }
  #manage-therapists .ai-list-item {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-therapists .ai-info-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-therapists .ai-main-info {
    flex-direction: column;
    align-items: center; }
  #manage-therapists .ai-details-info {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-therapists .tr-reviews-row {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-therapists .tr-reviews-value {
    margin: 5px auto; }
  #manage-therapists .tr-details-container {
    margin-bottom: 25px; } }

#manage-bloggers {
  background: #f1f2f361;
  position: relative; }
  #manage-bloggers .page-background {
    width: 100%;
    min-height: 100vh; }
  #manage-bloggers h1 {
    margin: 0; }
  #manage-bloggers .page-content {
    display: flex; }
  #manage-bloggers .inner-content-container {
    flex: 1 1; }
  #manage-bloggers .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #manage-bloggers .search-row {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap; }
  #manage-bloggers .search-row .content-half {
    width: calc(50% - 20px);
    padding: 10px; }
  #manage-bloggers .content-half .t-search-input {
    width: 100%;
    margin-bottom: 25px; }
  #manage-bloggers .t-search-button {
    width: 200px;
    height: 50px;
    text-align: center; }
  #manage-bloggers .t-search-button span {
    margin: 0; }
  #manage-bloggers .sp-search-input {
    width: 90%; }
  #manage-bloggers .results-section {
    margin-top: 30px; }
  #manage-bloggers .message-span-container {
    padding-left: 30px; }
  #manage-bloggers .ai-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 30px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #manage-bloggers .ai-image-container {
    width: 130px;
    height: 130px;
    border-radius: 15px;
    overflow: hidden; }
  #manage-bloggers .ai-image-container img {
    width: 100%;
    height: 100%; }
  #manage-bloggers .ai-info-container {
    flex: 1 1;
    padding-left: 50px; }
  #manage-bloggers .ai-actions-container {
    width: 120px; }
  #manage-bloggers .ai-main-info {
    font-size: 25px;
    display: flex;
    justify-content: flex-start;
    color: #634e70; }
  #manage-bloggers .ai-main-info .ai-u-name {
    font-weight: 600; }
  #manage-bloggers .ai-details-info {
    margin-top: 10px;
    font-size: 17px;
    color: #634e70; }
  #manage-bloggers .ai-detail-container {
    display: flex; }
  #manage-bloggers .ai-detail-label {
    width: 75px;
    font-weight: 600; }
  #manage-bloggers .ai-actions-container {
    display: flex;
    justify-content: space-evenly; }
  #manage-bloggers .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #manage-bloggers .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #manage-bloggers .update-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #manage-bloggers .view-icon {
    color: #aaa; }
  #manage-bloggers .green-icon {
    color: #28a745;
    cursor: pointer; }
  #manage-bloggers .red-icon {
    color: #dc3545;
    cursor: pointer; }
  #manage-bloggers .yellow-icon {
    color: #ffc107;
    cursor: pointer; }
  #manage-bloggers .view-icon:hover {
    color: #007bff;
    cursor: pointer; }
  #manage-bloggers .edit-sp-name, #manage-bloggers .edit-sp-description {
    width: 90%; }
  #manage-bloggers .edit-sp-description {
    margin-top: 15px; }
  #manage-bloggers .suggest-container {
    position: relative; }
  #manage-bloggers .autosugest-input {
    z-index: 2; }
  #manage-bloggers .suggested-specs-container {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 20px;
    position: absolute;
    width: 100%;
    top: 25px;
    z-index: 1;
    padding-top: 30px;
    display: block; }
  #manage-bloggers .hidden-element {
    display: none; }
  #manage-bloggers .suggested-spec-item {
    display: flex;
    padding: 10px;
    flex-wrap: wrap; }
  #manage-bloggers .suggested-specs-container .speciality-select-box {
    margin-right: 20px; }
  #manage-bloggers .tr-reviews-value {
    margin-left: 20px; }
  #manage-bloggers .tr-reviews-row {
    margin-bottom: 15px; }

@media (max-width: 580px) {
  #manage-bloggers .page-title {
    text-align: center; }
  #manage-bloggers .inner-content-container {
    max-width: 100%; }
  #manage-bloggers .search-row {
    flex-direction: column;
    align-items: center; }
  #manage-bloggers .search-row .content-half {
    width: 100%; }
  #manage-bloggers .search-row .rightside-content {
    flex-direction: column;
    align-items: center; }
  #manage-bloggers .suggest-container {
    width: 100%; }
  #manage-bloggers .ai-list-item {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-bloggers .ai-info-container {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-bloggers .ai-main-info {
    flex-direction: column;
    align-items: center; }
  #manage-bloggers .ai-details-info {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-bloggers .tr-reviews-row {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #manage-bloggers .tr-reviews-value {
    margin: 5px auto; }
  #manage-bloggers .tr-details-container {
    margin-bottom: 25px; } }

#therapist-sidebar {
  width: 0;
  height: 100%;
  position: absolute;
  background: #fff;
  z-index: 100; }
  #therapist-sidebar ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  #therapist-sidebar .sidebar-item {
    display: flex;
    justify-content: space-around;
    height: 50px;
    align-items: center;
    cursor: pointer;
    position: relative; }
  #therapist-sidebar .dot-position {
    position: absolute;
    top: 10px;
    right: 20px; }
  #therapist-sidebar .red-dot {
    border: 4px solid red;
    border-radius: 50px; }
  #therapist-sidebar .sidebar-item :hover {
    color: #126ef1; }
  #therapist-sidebar .active-item {
    color: #126ef1; }
  #therapist-sidebar .sidebar-items {
    width: 75px;
    background: #fff;
    height: 100%; }
  #therapist-sidebar .sidebar-items a {
    color: #aaa; }
  #therapist-sidebar .sdb-hamburger {
    height: 35px;
    width: 75px;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 30px; }
  #therapist-sidebar .sd-h-row {
    width: 30%;
    height: 4px;
    border-radius: 3px;
    margin: 3px auto;
    background: #126ef1; }
  #therapist-sidebar .sidebar-hidden {
    display: none; }
  #therapist-sidebar .hamburger-open {
    background: #fff; }

#therapist-manage-specialities {
  background: #f1f2f361;
  position: relative; }
  #therapist-manage-specialities .page-background {
    width: 100%;
    min-height: 100vh; }
  #therapist-manage-specialities h1 {
    margin: 0; }
  #therapist-manage-specialities .page-content {
    display: flex; }
  #therapist-manage-specialities .inner-content-container {
    flex: 1 1; }
  #therapist-manage-specialities .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #therapist-manage-specialities .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px; }
  #therapist-manage-specialities .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd; }
  #therapist-manage-specialities .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between; }
  #therapist-manage-specialities .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #therapist-manage-specialities .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #therapist-manage-specialities .small-gradient-button {
    width: 90%;
    height: 50px; }
  #therapist-manage-specialities .small-gradient-button span {
    margin: 0; }
  #therapist-manage-specialities .sp-search-input {
    width: 100%; }
  #therapist-manage-specialities .results-section {
    margin-top: 30px; }
  #therapist-manage-specialities .message-span-container {
    padding-left: 30px; }
  #therapist-manage-specialities .sp-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #therapist-manage-specialities .sp-li-name {
    font-weight: 600; }
  #therapist-manage-specialities .sp-li-description {
    font-size: 15px; }
  #therapist-manage-specialities .sp-li-info {
    flex: 1 1;
    border-right: 1px solid #eee; }
  #therapist-manage-specialities .sp-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #therapist-manage-specialities .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #therapist-manage-specialities .add-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #therapist-manage-specialities .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #therapist-manage-specialities .edit-sp-name, #therapist-manage-specialities .edit-sp-description {
    width: 90%; }
  #therapist-manage-specialities .edit-sp-description {
    margin-top: 15px; }
  #therapist-manage-specialities ul {
    list-style-type: none; }
  #therapist-manage-specialities .attached-specialities-list {
    display: flex;
    flex-wrap: wrap; }
  #therapist-manage-specialities .sp-at-item {
    display: flex;
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    border-radius: 25px;
    align-items: center;
    margin: 5px 10px;
    justify-content: space-around;
    padding: 2px; }
  #therapist-manage-specialities .sp-at-outer {
    display: flex;
    background: #fff;
    padding: 5px 15px;
    border-radius: 25px;
    align-items: center;
    height: 45px; }
  #therapist-manage-specialities .sp-at-outer .sp-at-info {
    height: 45px;
    display: flex;
    align-items: center;
    padding-right: 10px; }
  #therapist-manage-specialities .sp-at-outer .sp-at-info h4 {
    margin: 0; }
  #therapist-manage-specialities .sp-at-actions {
    height: 45px;
    display: flex;
    align-items: center; }
  #therapist-manage-specialities .attached-specialities-section {
    margin-top: 25px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px; }

@media (max-width: 580px) {
  #therapist-manage-specialities .top-row {
    display: flex;
    justify-content: space-around; }
  #therapist-manage-specialities .sp-at-outer .sp-at-info .sp-at-name {
    font-size: 15px;
    text-align: center; }
  #therapist-manage-specialities .sp-at-info {
    overflow: hidden;
    flex: 1 1;
    justify-content: space-around; }
  #therapist-manage-specialities .inner-content {
    width: 95%; }
  #therapist-manage-specialities .sp-at-item {
    width: 100%; }
  #therapist-manage-specialities .sp-at-outer {
    width: 100%; }
  #therapist-manage-specialities .attached-specialities-list {
    justify-content: space-around; } }

#therapist-manage-insurances {
  background: #f1f2f361;
  position: relative; }
  #therapist-manage-insurances .page-background {
    width: 100%;
    min-height: 100vh; }
  #therapist-manage-insurances h1 {
    margin: 0; }
  #therapist-manage-insurances .page-content {
    display: flex; }
  #therapist-manage-insurances .inner-content-container {
    flex: 1 1; }
  #therapist-manage-insurances .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #therapist-manage-insurances .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px; }
  #therapist-manage-insurances .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between; }
  #therapist-manage-insurances .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #therapist-manage-insurances .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #therapist-manage-insurances .small-gradient-button {
    width: 90%;
    height: 50px; }
  #therapist-manage-insurances .small-gradient-button span {
    margin: 0; }
  #therapist-manage-insurances .ins-add-input {
    width: 100%; }
  #therapist-manage-insurances .add-section {
    margin-top: 30px; }
  #therapist-manage-insurances .message-span-container {
    padding-left: 30px; }
  #therapist-manage-insurances .ins-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #therapist-manage-insurances .ins-li-name {
    font-weight: 600; }
  #therapist-manage-insurances .ins-li-description {
    font-size: 15px; }
  #therapist-manage-insurances .ins-li-info {
    flex: 1 1;
    border-right: 1px solid #eee; }
  #therapist-manage-insurances .ins-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #therapist-manage-insurances .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #therapist-manage-insurances .add-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #therapist-manage-insurances .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #therapist-manage-insurances .edit-sp-name, #therapist-manage-insurances .edit-sp-description {
    width: 90%; }
  #therapist-manage-insurances .edit-sp-description {
    margin-top: 15px; }
  #therapist-manage-insurances ul {
    list-style-type: none; }
  #therapist-manage-insurances .attached-specialities-list {
    display: flex;
    flex-wrap: wrap; }
  #therapist-manage-insurances .ins-at-item {
    display: flex;
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    border-radius: 25px;
    align-items: center;
    margin: 5px 10px;
    justify-content: space-around;
    padding: 2px; }
  #therapist-manage-insurances .ins-at-outer {
    display: flex;
    background: #fff;
    padding: 5px 15px;
    border-radius: 25px;
    align-items: center;
    height: 45px; }
  #therapist-manage-insurances .ins-at-outer .ins-at-info {
    height: 45px;
    display: flex;
    align-items: center;
    padding-right: 10px; }
  #therapist-manage-insurances .ins-at-outer .ins-at-info h4 {
    margin: 0; }
  #therapist-manage-insurances .ins-at-actions {
    height: 45px;
    display: flex;
    align-items: center; }
  #therapist-manage-insurances .attached-specialities-section {
    margin-top: 25px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px; }
  #therapist-manage-insurances .add-ins-btn {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 180px;
    margin-left: 15px; }
  #therapist-manage-insurances .bold-val {
    color: #634e70;
    font-weight: bold; }
  #therapist-manage-insurances .not-authorized-description {
    padding: 20px;
    font-size: 18px; }

@media (max-width: 580px) {
  #therapist-manage-insurances .inner-content {
    width: 95%; }
  #therapist-manage-insurances .page-title {
    text-align: center; }
  #therapist-manage-insurances .ins-at-item {
    width: 100%; }
  #therapist-manage-insurances .ins-at-outer {
    width: 100%; }
  #therapist-manage-insurances .ins-at-info {
    flex: 1 1;
    justify-content: space-around;
    overflow: hidden;
    text-align: center;
    font-size: 15px; }
  #therapist-manage-insurances .add-row {
    flex-direction: column;
    align-items: center; }
  #therapist-manage-insurances .add-ins-btn {
    width: 80%;
    margin-top: 20px; }
  #therapist-manage-insurances .ins-at-name {
    font-size: 17px; } }

#signin {
  width: 100vw; }
  #signin .page-background {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1; }
  #signin .bck-left-shape {
    position: absolute;
    width: 600px;
    top: -300px;
    left: -200px; }
  #signin .bck-right-top-ellipse {
    width: 400px;
    position: absolute;
    right: -200px;
    top: -200px; }
  #signin .bck-bottom-shape {
    width: 730px;
    position: absolute;
    bottom: -225px;
    right: -200px; }
  #signin .bck-bottom-ellipse {
    position: absolute;
    bottom: 45px;
    right: 15px;
    width: 175px; }
  #signin .page-content {
    width: 50%;
    margin: 0 auto;
    max-width: 470px; }
  #signin .logo {
    display: block;
    width: 200px;
    margin: 20px auto 30px; }
  #signin input {
    width: 50%;
    margin-bottom: 10px;
    margin-top: 5px; }
  #signin ::-webkit-input-placeholder {
    color: dimgrey; }
  #signin :-ms-input-placeholder {
    color: dimgrey; }
  #signin ::-ms-input-placeholder {
    color: dimgrey; }
  #signin ::placeholder {
    color: dimgrey; }
  #signin .remember-me-row {
    display: flex;
    margin-top: 25px;
    margin-bottom: 5px; }
    #signin .remember-me-row .sign-in-checkbox {
      width: 20px;
      margin: 10px;
      cursor: pointer; }
    #signin .remember-me-row .grey-text {
      cursor: pointer; }
  #signin .remember-me-row span {
    display: block; }
  #signin .remember-me-row .container-checkbox-round {
    margin-right: 25px; }
  #signin .login-row {
    margin-top: 75px; }
  #signin .signin-row {
    margin-top: 30px; }
  #signin input.input-error {
    border-bottom: 1px solid red; }

@media (max-width: 795px) {
  #signin input {
    width: calc(100% - 20px);
    display: block;
    margin: 15px auto; }
  #signin .page-content {
    width: 85%; }
  #signin .bck-left-shape {
    left: -350px; } }

@media (max-width: 480px) {
  #signin {
    position: absolute;
    width: 100vw; }
    #signin .page-title {
      text-align: center;
      font-size: 21px; }
    #signin .remember-me-row {
      justify-content: center;
      align-items: center; }
    #signin .container-checkbox-round {
      margin-top: -15px; }
    #signin .forgot-password-row {
      display: flex;
      justify-content: center; }
    #signin .login-row {
      display: flex;
      justify-content: center; }
    #signin .signin-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
      text-align: center;
      margin-top: 20px; }
    #signin .bck-bottom-shape {
      bottom: -195px;
      right: -280px; }
    #signin .signin-row .grey-text {
      color: #fff; } }

#signup .page-background {
  width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden; }

#signup .bck-left-ellipse {
  position: absolute;
  top: -70px;
  left: -70px;
  width: 141px; }

#signup .sign-in-checkbox {
  width: 20px;
  margin: 10px;
  cursor: pointer; }

#signup .bck-right-ellipse {
  position: absolute;
  right: -111px;
  top: -111px;
  width: 222px; }

#signup .bck-bottom-shape {
  position: absolute;
  bottom: -231px;
  right: -187px;
  width: 700px; }

#signup .page-content {
  width: 90%;
  margin: 0 auto;
  display: flex;
  margin-top: 60px; }

#signup .half-panel {
  width: 50%; }

#signup .leftside-panel {
  text-align: center; }

#signup .logo {
  width: 25%;
  display: block;
  margin: 0 auto; }

#signup .people-group {
  width: 65%;
  display: block;
  margin: 50px auto; }

#signup .page-title {
  font-size: 45px;
  font-weight: 700;
  margin-top: 0; }

#signup .basic-paragraph {
  color: #bfbfbf; }

#signup .inputs-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px; }

#signup .inputs-row input {
  width: calc(50% - 40px); }

#signup .classic-text {
  color: #969696; }

#signup .checkbox-text-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; }

#signup .checkbox-text-row p {
  margin: 7px; }

#signup .checkbox-text-row input[type="checkbox"] {
  margin-right: 15px; }

#signup .login-row {
  display: flex;
  margin-top: 15px; }

#signup .login-row p {
  margin-right: 10px; }

#signup .link-text {
  color: #126ef1; }

#signup .signup-row {
  margin-top: 30px; }

#signup input.input-error {
  border-bottom: 1px solid red; }

#signup .error-message {
  color: red;
  text-align: center; }

#signup .role-options-container {
  position: relative; }

#signup .role-options-select {
  border: 1px solid #eee;
  outline: none;
  background: #fff;
  height: 45px;
  color: #ccc; }

#signup .role-options-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding: 0 25px; }

@media (max-width: 851px) {
  #signup .page-content {
    flex-direction: column;
    align-items: center; }
  #signup .half-panel {
    width: 80%; }
  #signup .inputs-row input {
    width: 100%;
    margin-bottom: 15px; }
  #signup .inputs-row {
    margin-bottom: 0; }
  #signup .bck-left-ellipse {
    display: none; }
  #signup .bck-right-ellipse {
    display: none; }
  #signup .bck-bottom-shape {
    display: none; } }

@media (max-width: 480px) {
  #signup .page-title {
    text-align: center;
    font-size: 21px; }
  #signup .login-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
  #signup .logo {
    width: 80%; }
  #signup .people-group {
    width: 95%; } }

#therapist-sidebar {
  width: 0;
  height: 100%;
  position: absolute;
  background: #fff;
  z-index: 100; }
  #therapist-sidebar ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  #therapist-sidebar .sidebar-item {
    display: flex;
    justify-content: space-around;
    height: 50px;
    align-items: center;
    cursor: pointer; }
  #therapist-sidebar .sidebar-item :hover {
    color: #126ef1; }
  #therapist-sidebar .active-item {
    color: #126ef1; }
  #therapist-sidebar .sidebar-items {
    width: 75px;
    background: #fff;
    height: 100%; }
  #therapist-sidebar .sidebar-items a {
    color: #aaa; }
  #therapist-sidebar .sdb-hamburger {
    height: 35px;
    width: 75px;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 30px; }
  #therapist-sidebar .sd-h-row {
    width: 30%;
    height: 4px;
    border-radius: 3px;
    margin: 3px auto;
    background: #126ef1; }
  #therapist-sidebar .sidebar-hidden {
    display: none; }
  #therapist-sidebar .hamburger-open {
    background: #fff; }

#therapist-details {
  background: #f1f2f361; }
  #therapist-details .page-background {
    width: 100%;
    min-height: 100vh; }
  #therapist-details h1 {
    margin: 0; }
  #therapist-details .page-content {
    display: flex; }
  #therapist-details .inner-content-container {
    flex: 1 1; }
  #therapist-details .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #therapist-details .second-row {
    display: flex; }
  #therapist-details .leftside-content {
    width: 30%; }
  #therapist-details .rightside-content {
    width: 70%; }
  #therapist-details .inner-content .leftside-content .avatar-container {
    width: 125px;
    height: 125px;
    background: #ddd;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    overflow: hidden;
    border: 1px solid #efefef;
    position: relative; }
  #therapist-details .inner-content .avatar-container .avatar-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent; }
  #therapist-details .inner-content .avatar-container .avatar-cover:hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #eee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px; }
  #therapist-details .inner-content .avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  #therapist-details .inner-content .add-photo-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 31px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 15px auto; }
  #therapist-details .input-row input {
    width: 80%;
    max-width: 380px; }
  #therapist-details .form-container {
    margin-top: 50px; }
  #therapist-details .input-row {
    margin-bottom: 35px; }
  #therapist-details .textarea-row.input-row {
    display: flex; }
  #therapist-details .submit-row {
    margin-top: 25px; }
  #therapist-details input.input-error {
    border-bottom: 1px solid red; }
  #therapist-details .submit-btn-medium {
    width: 200px;
    height: 50px; }
  #therapist-details textarea.basic-input {
    width: 80%;
    max-width: 380px;
    border: 1px solid #dddddd;
    outline: none;
    background: transparent;
    color: #cccccc;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 160px;
    text-indent: 5px; }
  #therapist-details .input-icon {
    margin-right: 15px; }
  #therapist-details .profile-img-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center; }

@media (max-width: 580px) {
  #therapist-details .inner-content {
    width: 95%; }
  #therapist-details .page-title {
    text-align: center; }
  #therapist-details .second-row {
    flex-direction: column;
    align-items: center; }
  #therapist-details .leftside-content {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  #therapist-details .rightside-content {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  #therapist-details .form-container {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  #therapist-details .form-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #therapist-details .input-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center; }
  #therapist-details .input-row input {
    flex: 1 1; }
  #therapist-details .input-icon {
    margin-top: 15px; }
  #therapist-details .textarea-row {
    flex-direction: column;
    align-items: center; }
  #therapist-details .textarea-row .basic-input {
    margin-top: 10px;
    width: 100%; }
  #therapist-details .submit-row {
    margin-top: 35px;
    margin-bottom: 30px; } }

.media-icon-container .media-icon-outer {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.media-icon-container .media-icon-outer:hover {
  width: 52px !important;
  height: 52px !important;
  box-shadow: -5px 3px 48px -2px rgba(0, 0, 0, 0.75); }

.media-icon-container .media-icon-outer.mi-gradient {
  padding: 2px; }

.media-icon-container .media-icon-icon.mi-gradient {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.media-icon-container .media-icon-outer.mi-gradient:hover {
  width: 43px !important;
  height: 43px !important;
  box-shadow: -5px 3px 48px -2px rgba(0, 0, 0, 0.75); }

.media-icon-container .header-icon-circle {
  width: 40px;
  height: 40px; }

.media-icon-container.mi-gradient {
  margin: 5px;
  width: 40px; }

@media (max-width: 993px) {
  .footer-icon-circle {
    width: 50px !important;
    height: 50px !important; } }

@media (max-width: 370px) {
  .footer-icon-circle {
    width: 45px !important;
    height: 45px !important; } }

.footer {
  background-image: url(/static/media/Shape_4.f4aed18b.png);
  background-position-x: center;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  font-size: 16px;
  min-height: 630px;
  margin-top: 100px; }
  @media (max-width: 767px) {
    .footer {
      background-position: left -175px;
      padding-top: 400px;
      background-image: url(/static/media/footer-background-extended.7165b3f1.png); } }
  .footer .container {
    margin-top: auto; }
    @media (max-width: 767px) {
      .footer .container {
        width: 100%; } }
    @media (max-width: 575px) {
      .footer .container {
        max-width: 100%;
        width: 100%; } }
  .footer .footer-logo {
    display: block;
    max-width: 100%;
    margin: 0 auto 20px;
    width: auto;
    height: auto; }
  .footer .footer-menu {
    display: block;
    list-style: none;
    margin-bottom: 0;
    padding: 0; }
    .footer .footer-menu .footer-menu li {
      text-align: left; }
    @media (max-width: 767px) {
      .footer .footer-menu {
        -webkit-columns: 3;
                columns: 3; } }
    @media (max-width: 575px) {
      .footer .footer-menu {
        -webkit-columns: 1;
                columns: 1; } }
    .footer .footer-menu li {
      padding: 4px 0; }
      .footer .footer-menu li a {
        padding: 4px 0;
        color: #fff; }
  .footer .social-icons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
    .footer .social-icons .iconsContainer {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center; }
      @media (max-width: 767px) {
        .footer .social-icons .iconsContainer {
          justify-content: space-around; } }
    @media (max-width: 767px) {
      .footer .social-icons {
        justify-content: space-around;
        margin: 0px auto 0;
        max-width: 290px;
        width: 100%; } }
    .footer .social-icons img:hover {
      opacity: 0.7;
      -webkit-transition: opacity 500ms;
      transition: opacity 500ms; }
    @media (max-width: 991px) {
      .footer .social-icons img {
        width: 45px;
        height: 45px; } }
    .footer .social-icons .media-icon-container {
      margin-right: 25px;
      width: 50px;
      height: 50px; }
      @media (min-width: 992px) {
        .footer .social-icons .media-icon-container {
          width: 50px; } }
      @media (max-width: 767px) {
        .footer .social-icons .media-icon-container {
          width: 50px;
          margin-right: 0px; } }
  .footer .copyright {
    font-size: 10px;
    margin: 40px auto 35px; }
    @media (max-width: 767px) {
      .footer .copyright {
        margin: 0px auto 10px; } }
    .footer .copyright a {
      color: white; }
  .footer .disclaimer-bottom-affil {
    width: 80%;
    max-width: 500px;
    margin: 20px auto;
    font-size: 11px; }
  @media (max-width: 766px) {
    .footer .social-icons {
      width: 100%;
      max-width: 100%; } }
  @media (max-width: 575px) {
    .footer .footer-menu li {
      text-align: center; } }

#therapist-public-profile .profile-details {
  max-width: 960px;
  width: 80%;
  margin: 30px auto 0 auto; }

#therapist-public-profile .write-review-section {
  max-width: 960px;
  width: 80%;
  margin: 20px auto; }

#therapist-public-profile .leftside-content {
  width: 150px;
  margin-top: 200px; }

#therapist-public-profile .leftside-content .avatar-container {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 10px;
  line-height: 150px; }

#therapist-public-profile .avatar-container img {
  width: 150px;
  height: auto; }

#therapist-public-profile .profile-section {
  width: 100%;
  display: flex; }

#therapist-public-profile .more-therapists {
  margin: 40px auto 20px auto;
  text-align: center; }

#therapist-public-profile .rightside-content {
  flex: 1 1;
  margin-left: 100px;
  max-width: 810px; }

#therapist-public-profile .section-title {
  color: #634e70;
  font-weight: 700;
  font-family: Montserrat; }

#therapist-public-profile .profile-title {
  color: #ffff;
  font-size: 35px;
  font-weight: 700;
  font-family: Montserrat;
  margin-right: 10px;
  text-align: center; }

@media (max-width: 1024px) {
  #therapist-public-profile .profile-title-long {
    font-size: 25px; } }

#therapist-public-profile .subsection-title {
  color: #634e70;
  font-size: 25px;
  font-weight: 700;
  font-family: Montserrat;
  margin-bottom: 15px; }

#therapist-public-profile .rev-list-item-name {
  font-weight: bold;
  color: #634e70;
  margin-bottom: 5px; }

#therapist-public-profile .rev-list-item-comment {
  font-size: 17px; }

#therapist-public-profile .rev-list-item-reply {
  cursor: pointer;
  color: #457bd5;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin-left: 2rem; }

#therapist-public-profile .therapist-flag {
  font-size: 15px;
  margin: 10px auto;
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  color: #28a745; }
  #therapist-public-profile .therapist-flag .green-icon {
    font-size: 25px; }

#therapist-public-profile .page-title {
  color: #ffff;
  margin-top: 50px; }

#therapist-public-profile .contact-form-section .input-container {
  margin-top: 30px; }

#therapist-public-profile .contact-form-section .input-row {
  margin-bottom: 30px; }

#therapist-public-profile .modal-imput {
  text-align: center; }
  #therapist-public-profile .modal-imput .basic-input {
    width: 90%; }

#therapist-public-profile .networkin-subsection {
  margin-top: 20px; }

#therapist-public-profile .bio-data button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer; }

#therapist-public-profile .contact-form-section {
  margin-top: 50px;
  width: 90%; }

#therapist-public-profile .modal-input {
  text-align: center; }

#therapist-public-profile .modal-input input {
  width: 80%; }

#therapist-public-profile .claim-button {
  margin-top: 40px; }
  @media (max-width: 480px) {
    #therapist-public-profile .claim-button {
      text-align: center; } }

#therapist-public-profile .online-flag {
  font-size: 15px;
  margin: 10px auto;
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  color: #53d6d6; }
  #therapist-public-profile .online-flag .green-icon {
    font-size: 25px; }

#therapist-public-profile .therapist-flag-text {
  margin-left: 5px; }

#therapist-public-profile .reply-section {
  margin-left: 2rem;
  margin-bottom: 1rem; }

#therapist-public-profile .arrow-replies {
  margin-left: 10px;
  font-size: 2rem;
  cursor: pointer; }

#therapist-public-profile .visible {
  display: inline; }

#therapist-public-profile .hidden {
  display: none; }

#therapist-public-profile .reply-form {
  margin-bottom: 20px; }

#therapist-public-profile .review-row {
  margin-top: 10px;
  margin-bottom: 75px;
  color: #ffff; }

#therapist-public-profile .section-title {
  font-size: 30px;
  font-weight: bold; }

#therapist-public-profile .rev-f-row {
  width: 100%;
  margin: 0 auto 30px; }

#therapist-public-profile .rev-f-half-input-container {
  width: 45%; }

#therapist-public-profile .rev-f-half-input-container .basic-input {
  width: 100%; }

#therapist-public-profile .rev-f-row {
  width: 100%;
  display: flex;
  justify-content: space-between; }

#therapist-public-profile .rev-f-full-input-container {
  width: 100%; }

#therapist-public-profile .rev-f-full-input-container .basic-input {
  width: 100%; }

#therapist-public-profile .art-rev-submit-btn {
  width: 250px;
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

#therapist-public-profile .ar-form-response-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px; }

#therapist-public-profile .basic-input-err {
  border-bottom: 1px solid red; }

#therapist-public-profile .review-row .review-data {
  margin-left: 15px; }

#therapist-public-profile .specialities-section {
  margin-top: 20px; }

#therapist-public-profile .specialities-list {
  display: flex;
  flex-wrap: wrap; }

#therapist-public-profile .insurances-row {
  margin-top: 25px; }

#therapist-public-profile .insurances-list {
  display: flex; }

#therapist-public-profile .contact-details-section {
  margin-top: 25px; }

#therapist-public-profile .phone-row {
  margin: 20px 0; }

#therapist-public-profile .phone-row .cd-value,
#therapist-public-profile .email-row .cd-value,
#therapist-public-profile .web-row .cd-value {
  margin-left: 20px; }

#therapist-public-profile .contact-row {
  margin-top: 25px; }

#therapist-public-profile .small-btn {
  width: 200px;
  height: 50px; }

#therapist-public-profile .submit-btn {
  margin-top: 30px; }

#therapist-public-profile .full-width {
  width: 100%; }

#therapist-public-profile .section-title {
  color: #634e70; }

#therapist-public-profile .review-form .input-row {
  display: flex;
  margin-bottom: 50px;
  margin-top: 30px; }
  @media (max-width: 1023px) {
    #therapist-public-profile .review-form .input-row {
      flex-direction: column; } }

#therapist-public-profile .half-input {
  width: 400px;
  margin-right: 40px; }

#therapist-public-profile .full-input {
  width: 840px; }
  @media (max-width: 1023px) {
    #therapist-public-profile .full-input {
      width: 400px; } }

#therapist-public-profile .error-message {
  color: #dc3545; }

#therapist-public-profile .success-message {
  color: #28a745; }

#therapist-public-profile .info-row {
  text-align: left; }

#therapist-public-profile input.input-error {
  border-bottom: 1px solid red; }

#therapist-public-profile .review-card {
  margin: 20px 20px 0 20px;
  padding: 10px; }

#therapist-public-profile .review-card .full-name {
  font-weight: bold;
  color: #634e70;
  margin-bottom: 5px; }

#therapist-public-profile .review-card .rating-container {
  margin-bottom: 15px;
  display: flex;
  align-items: center; }

#therapist-public-profile .review-card .date-container {
  font-size: 13px;
  margin-left: 15px; }

#therapist-public-profile .review-card .review-container {
  font-size: 15px; }

#therapist-public-profile .location-data {
  width: 80%;
  margin: 0 auto; }

#therapist-public-profile .location-list {
  display: flex;
  flex-wrap: wrap; }

#therapist-public-profile .location-item-small .sp-at-name {
  font-size: 15px; }

#therapist-public-profile .location-item-small {
  cursor: pointer; }

#therapist-public-profile .map-container {
  height: 300px;
  width: 100%;
  margin-top: 20px;
  border-radius: 20px;
  overflow: hidden; }

@media (max-width: 580px) {
  #therapist-public-profile {
    font-size: 22px; }
    #therapist-public-profile .page-title {
      text-align: center;
      font-size: 22px;
      height: 160px;
      display: flex;
      overflow: hidden;
      justify-content: center; }
    #therapist-public-profile .profile-details {
      margin: 20px auto 0 auto; }
    #therapist-public-profile .profile-section {
      flex-direction: column;
      align-items: center; }
    #therapist-public-profile .avatar-container {
      margin-bottom: 40px; }
    #therapist-public-profile .leftside-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px; }
    #therapist-public-profile .rightside-content {
      margin: 0;
      width: 100%; }
    #therapist-public-profile .title-row {
      margin-top: -50px;
      flex-direction: column;
      align-items: center; }
    #therapist-public-profile .review-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #634e70;
      margin-bottom: 10px; }
    #therapist-public-profile .review-data {
      margin: 5px auto;
      text-align: center; }
    #therapist-public-profile .bio-section {
      display: flex;
      justify-content: center; }
    #therapist-public-profile .specialities-section {
      display: flex;
      align-items: center;
      flex-direction: column; }
    #therapist-public-profile .specialities-list {
      flex-direction: column;
      align-items: center;
      width: 95%;
      margin: 0 auto; }
    #therapist-public-profile .rlb-item {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center; }
    #therapist-public-profile .sp-at-name {
      font-size: 18px;
      text-align: center; }
    #therapist-public-profile .sp-at-outer {
      width: 100%; }
    #therapist-public-profile .section-title {
      font-weight: 300;
      color: #634e70;
      font-family: Montserrat;
      text-align: center; }
    #therapist-public-profile .subsection-title {
      text-align: center; }
    #therapist-public-profile .contact-details-section {
      display: flex;
      flex-direction: column;
      align-items: center; }
    #therapist-public-profile .email-row,
    #therapist-public-profile .phone-row,
    #therapist-public-profile .web-row {
      max-width: 500px;
      overflow: auto;
      width: 85%;
      display: flex;
      align-items: center; }
    #therapist-public-profile .contact-row {
      width: 90%;
      display: flex;
      justify-content: center; }
    #therapist-public-profile .review-form {
      display: flex;
      flex-direction: column;
      align-items: center; }
    #therapist-public-profile .input-row {
      width: 100%;
      flex-direction: column;
      align-items: center; }
    #therapist-public-profile .leftside-input-container,
    #therapist-public-profile .rightside-input-container {
      width: 85%;
      display: flex;
      justify-content: center; }
    #therapist-public-profile .review-row {
      width: 85%;
      display: flex;
      justify-content: center; }
    #therapist-public-profile .basic-input.full-input {
      width: 100%; }
    #therapist-public-profile .specialities-list {
      flex-wrap: nowrap; }
    #therapist-public-profile .location-data {
      width: 85%;
      margin-top: 40px; }
    #therapist-public-profile .write-review-section {
      width: 85%; } }

.rlb-item {
  display: flex;
  background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
  background: linear-gradient(to right, #106ff1, #a723ec);
  border-radius: 25px;
  align-items: center;
  margin: 5px 10px;
  justify-content: space-around;
  padding: 2px; }
  .rlb-item .sp-at-outer {
    background: #fff;
    border-radius: 25px;
    padding: 5px 20px; }
  .rlb-item .sp-at-name {
    margin: 0; }

.header {
  margin-bottom: -30px;
  background-position-x: center;
  background-repeat: no-repeat;
  padding-top: 50px;
  padding-bottom: 5px;
  position: relative; }
  .header .close-mobile-menu {
    display: none; }
    @media (max-width: 767px) {
      .header .close-mobile-menu {
        display: none; } }
    @media (max-width: 576px) {
      .header .close-mobile-menu {
        display: block;
        position: absolute;
        right: 10px;
        color: white;
        top: 31px;
        font-size: 20px;
        font-weight: 700 !important; } }
  .header .sub-menu-item {
    margin: 0; }
  .header .sub-menu-link {
    color: #ffff;
    cursor: pointer; }
    .header .sub-menu-link .sub-menu-item {
      margin: 0; }
      @media (max-width: 991px) {
        .header .sub-menu-link .sub-menu-item {
          margin: 0 auto; } }
    @media (max-width: 991px) {
      .header .sub-menu-link .sub-menu-name {
        display: none; } }
    .header .sub-menu-link .sub-menu-item a {
      color: #437cd0; }
      @media (max-width: 991px) {
        .header .sub-menu-link .sub-menu-item a {
          color: #ffff; } }
    .header .sub-menu-link .sub-menu-item a:hover {
      border-bottom-color: #437cd0; }
  .header .sub-menu {
    position: absolute;
    display: none;
    flex-direction: column;
    background-color: #eeeeee;
    padding: 10px 10px 10px 20px;
    border-radius: 20px;
    z-index: 100; }
    @media (max-width: 991px) {
      .header .sub-menu {
        position: relative;
        background: none;
        display: block;
        padding: 0; } }
  .header .show-sub-menu {
    display: flex; }
  @media (max-width: 768px) {
    .header .greeting {
      margin: 0px; } }
  @media (max-width: 500px) {
    .header {
      padding-top: 30px; } }
  .header .menu li {
    font-size: 20px;
    font-weight: 600; }
  .header .burger-menu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAASBAMAAACzwgOtAAAAAXNSR0IB2cksfwAAABhQTFRFAAAAFXD0Em/xEm7xE3DyEm/xE27yE2/yc7wIkAAAAAh0Uk5TADDv/2DwwL8sNGSoAAAAJUlEQVR4nGNgYFQ2hgATBgYGxmAkDlVBsjEcmCNzzKhtES7/AAC1bgyEre4qGwAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    display: block;
    -webkit-filter: drop-shadow(0px 0px 1px #fff);
            filter: drop-shadow(0px 0px 1px #fff);
    height: 18px;
    padding: 20px;
    position: absolute;
    right: 20px;
    top: 70px;
    width: 24px; }
    @media (min-width: 576px) {
      .header .burger-menu {
        display: none; } }
    @media (max-width: 350px) {
      .header .burger-menu {
        right: 20px; } }
    .header .burger-menu .toggle-button__line {
      display: none; }
  .header bg-image {
    height: 1200px;
    left: 50%;
    max-width: 3020px;
    object-fit: cover;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    z-index: -1;
    margin-top: -350px; }
    @media (max-width: 575px) {
      .header bg-image {
        headerBackground: url(/static/media/header-background.2a875404.png);
        margin-top: 0px;
        height: 730px;
        background-size: 90px;
        object-position: -350px -60px; }
        .header bg-image .d-md-flex {
          display: none; } }
  .header .menu {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    font-size: 16px;
    margin-bottom: 0;
    padding-left: 0;
    margin-top: 20px; }
    @media (min-width: 768px) {
      .header .menu {
        justify-content: space-between; } }
    @media (max-width: 991px) {
      .header .menu {
        width: 100%; } }
    @media (max-width: 767px) {
      .header .menu {
        background-color: rgba(99, 78, 112, 0.8);
        flex-direction: column;
        height: 100vh;
        flex-wrap: nowrap;
        left: 0;
        padding: 20px 0;
        position: fixed;
        top: 0;
        visibility: hidden;
        width: 100vw;
        z-index: 10;
        margin-top: 0px; } }
    .header .menu li {
      list-style: none;
      margin-left: 15px;
      margin-right: 15px; }
      @media (max-width: 780px) {
        .header .menu li:last-of-type {
          margin: 0 auto; } }
      @media (max-width: 991px) {
        .header .menu li {
          margin-left: 7px;
          margin-right: 7px; } }
      @media (max-width: 991px) {
        .header .menu li {
          text-align: center;
          width: 100%;
          margin: 0 auto; } }
      .header .menu li.login-btn {
        margin-left: 40px; }
        .header .menu li.login-btn img {
          margin-right: 10px; }
        @media (max-width: 767px) {
          .header .menu li.login-btn {
            margin-left: 0; } }
      .header .menu li a {
        color: #fff;
        padding: 5px 0;
        border: solid 1px transparent; }
        .header .menu li a:hover, .header .menu li a.active {
          border-bottom-color: #fff; }
        @media (max-width: 767px) {
          .header .menu li a {
            display: block;
            padding: 10px; } }
  .header .logo {
    margin-right: auto;
    height: auto; }
    @media (max-width: 991px) {
      .header .logo {
        padding-bottom: 20px;
        text-align: center;
        width: 230px;
        max-width: 230px;
        height: auto; } }
    @media (max-width: 767px) {
      .header .logo {
        margin: 10px 0 60px;
        text-align: left;
        padding-bottom: 0; } }
    @media (max-width: 575px) {
      .header .logo img {
        width: 230px;
        height: auto; } }
  .header .media-icons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -50px;
    height: 45px; }
  @media (max-width: 766px) {
    .header .media-icons-container {
      justify-content: space-around; } }

#icon-user {
  display: flex;
  align-items: center;
  padding-left: 30px;
  width: 30%; }
  #icon-user img.align-self {
    align-self: center; }
  #icon-user .logo {
    width: 35px; }
  #icon-user .header-content {
    width: 80%;
    display: flex;
    margin: 0 auto; }
  #icon-user .avatar-img-container {
    width: 57px;
    height: 57px;
    overflow: hidden;
    border-radius: 50%;
    /* text-align: center; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 7px solid #fff;
    z-index: 1;
    cursor: pointer; }
  #icon-user .avatar-img-container img {
    width: 100%;
    height: 100%; }
  #icon-user .avatar-container {
    display: flex;
    align-items: center;
    position: relative; }
  #icon-user .name-container {
    width: 57px;
    height: 57px;
    background: #eee;
    border-radius: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    color: #126ef1;
    position: absolute;
    right: 45px;
    z-index: 1;
    cursor: pointer;
    border: 6px solid #fff; }
  #icon-user .aac-options-container {
    position: absolute;
    width: 130px;
    height: 100px;
    background: #eee;
    border-radius: 25px;
    bottom: -90px;
    right: 10px; }
    @media (max-width: 768px) {
      #icon-user .aac-options-container {
        right: -12px; } }
  #icon-user ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  #icon-user .aac-options {
    margin-top: 25px;
    text-align: center;
    list-style-type: none; }
    @media (max-width: 768px) {
      #icon-user .aac-options {
        margin-top: 5px; } }
  #icon-user .aac-option {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto; }
  #icon-user .spacer {
    flex: 1 1; }
  @media (max-width: 768px) {
    #icon-user {
      margin-top: 20px; } }

.toggle-button {
    height: 30px;
    width: 30px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.toggle-button__line {
    width: 90%;
    height: 3px;
    background: #126ef1;
    margin: 4px auto
}

.toggle-button__line:nth-child(2) {
    width: 100%;
}
.message {
  color: red; }

#therapist-profile {
  background: #f1f2f361; }
  #therapist-profile .page-background {
    width: 100%;
    min-height: 100vh; }
  #therapist-profile .disabled-input {
    opacity: 0.5; }
  #therapist-profile h1 {
    margin: 0; }
  #therapist-profile .page-content {
    display: flex; }
  #therapist-profile .inner-content-container {
    flex: 1 1; }
  #therapist-profile .info-text-red {
    color: red; }
  #therapist-profile .networking-checkbox {
    width: 20px;
    margin-right: 10px;
    margin-bottom: 30px;
    cursor: pointer; }
  #therapist-profile .info-icon {
    margin-right: 3px; }
  #therapist-profile .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #therapist-profile .second-row {
    display: flex;
    margin-bottom: 50px; }
  #therapist-profile .display-none {
    display: none; }
  #therapist-profile .networking-info {
    font-size: 12px;
    margin-left: 3px; }
  #therapist-profile .user-name {
    color: #634e70;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    cursor: default;
    margin-bottom: 0; }
  #therapist-profile .leftside-content {
    width: 30%; }
  #therapist-profile .rightside-content {
    width: 70%; }
  #therapist-profile .inner-content .leftside-content .avatar-container {
    width: 125px;
    height: 125px;
    background: #ddd;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    overflow: hidden;
    border: 1px solid #efefef;
    position: relative; }
  #therapist-profile .inner-content .avatar-container .avatar-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    color: transparent; }
  #therapist-profile .inner-content .avatar-container .avatar-cover:hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #eee;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 18px; }
  #therapist-profile .inner-content .avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  #therapist-profile .inner-content .add-photo-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 31px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 15px auto; }
  #therapist-profile .input-row input {
    width: 80%;
    max-width: 380px; }
  #therapist-profile .form-container {
    margin-top: 50px; }
  #therapist-profile .input-row {
    margin-bottom: 35px; }
    #therapist-profile .input-row .checkStyle {
      color: red;
      font-size: 15px;
      padding-top: 5px; }
  #therapist-profile .grey-input::-webkit-input-placeholder {
    color: #aaaaaa;
    cursor: default; }
  #therapist-profile .grey-input:-ms-input-placeholder {
    color: #aaaaaa;
    cursor: default; }
  #therapist-profile .grey-input::-ms-input-placeholder {
    color: #aaaaaa;
    cursor: default; }
  #therapist-profile .grey-input, #therapist-profile .grey-input::placeholder {
    color: #aaaaaa;
    cursor: default; }
  #therapist-profile .submit-row {
    display: flex;
    justify-content: space-between;
    margin-top: 25px; }
    @media (min-width: 1081px) {
      #therapist-profile .submit-row a {
        line-height: 70px; } }
  #therapist-profile input.input-error {
    border-bottom: 1px solid red; }
  #therapist-profile .submit-btn-medium {
    width: 200px;
    height: 20px; }
  #therapist-profile textarea.basic-input {
    width: 80%;
    max-width: 380px;
    border: 1px solid #dddddd;
    outline: none;
    background: transparent;
    color: #cccccc;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 160px;
    text-indent: 5px; }
  #therapist-profile .input-icon {
    margin-right: 15px; }
  #therapist-profile .profile-img-sub-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #therapist-profile .profile-tabs {
    display: flex;
    flex-wrap: wrap; }
  #therapist-profile .profile-tabs h1 {
    margin-right: 10px; }
  #therapist-profile .tab-item {
    cursor: pointer; }
  #therapist-profile .inactive-tab {
    color: #ddd; }
  #therapist-profile .password-tab {
    width: 100%;
    margin-top: 50px; }

@media (max-width: 580px) {
  #therapist-profile .inner-content-container {
    width: 100%; }
  #therapist-profile .inner-content {
    width: 95%; }
  #therapist-profile .page-title {
    text-align: center; }
  #therapist-profile .tabs-bar {
    display: none; }
  #therapist-profile .profile-tabs {
    justify-content: space-around; }
  #therapist-profile .profile-tabs h1 {
    margin: 0; }
  #therapist-profile .second-row {
    flex-direction: column;
    align-items: center; }
  #therapist-profile .leftside-content {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  #therapist-profile .rightside-content {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  #therapist-profile .form-container {
    width: 100%;
    display: flex;
    justify-content: space-around; }
  #therapist-profile .form-container form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #therapist-profile .input-row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center; }
  #therapist-profile .input-row input {
    flex: 1 1; }
  #therapist-profile .input-icon {
    margin-top: 15px; }
  #therapist-profile .textarea-row {
    flex-direction: column;
    align-items: center; }
  #therapist-profile .textarea-row .basic-input {
    margin-top: 10px;
    width: 100%; }
  #therapist-profile .submit-row {
    margin-top: 35px;
    margin-bottom: 30px; } }

.percent {
  padding-top: 40px; }

#therapist-profile {
  background: #f1f2f361; }
  #therapist-profile .page-background {
    width: 100%;
    min-height: 100vh; }
  #therapist-profile h1 {
    margin: 0; }
  #therapist-profile .page-content {
    display: flex; }
  #therapist-profile .inner-content-container {
    flex: 1 1; }
  #therapist-profile .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #therapist-profile .second-row {
    display: flex; }
  #therapist-profile .leftside-content {
    width: 30%; }
  #therapist-profile .rightside-content {
    width: 70%; }
  #therapist-profile .inner-content .leftside-content .avatar-container {
    width: 125px;
    height: 125px;
    background: #ddd;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px; }
  #therapist-profile .inner-content .add-photo-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 31px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 15px auto; }
  #therapist-profile .input-row input {
    width: 80%;
    max-width: 380px; }
  #therapist-profile .form-container {
    margin-top: 50px; }
  #therapist-profile .input-row {
    margin-bottom: 35px; }
  #therapist-profile .submit-row {
    margin-top: 25px; }
  #therapist-profile input.input-error {
    border-bottom: 1px solid red; }
  #therapist-profile .submit-btn-medium {
    width: 200px;
    height: 70px;
    margin-right: 20px; }
  #therapist-profile .profile-tabs {
    display: flex;
    flex-wrap: wrap; }
  #therapist-profile .profile-tabs h1 {
    margin-right: 10px; }
  #therapist-profile .tab-item {
    cursor: pointer; }
  #therapist-profile .inactive-tab {
    color: #ddd; }
  #therapist-profile .password-tab {
    width: 100%;
    margin-top: 50px; }

@media (max-width: 580px) {
  #therapist-profile .inner-content-container {
    width: 100%; }
  #therapist-profile .inner-content {
    width: 95%; }
  #therapist-profile .page-title {
    text-align: center; }
  #therapist-profile .tabs-bar {
    display: none; }
  #therapist-profile .profile-tabs {
    justify-content: space-around; }
  #therapist-profile .profile-tabs h1 {
    margin: 0; }
  #therapist-profile .rightside-content {
    width: 100%; }
  #therapist-profile .input-row {
    display: flex;
    justify-content: space-around; }
  #therapist-profile .submit-row {
    display: flex;
    justify-content: space-around; } }

#therapist-manage-articles {
  background: #f1f2f361;
  position: relative; }
  #therapist-manage-articles .page-background {
    width: 100%;
    min-height: 100vh; }
  #therapist-manage-articles h1 {
    margin: 0; }
  #therapist-manage-articles .page-content {
    display: flex; }
  #therapist-manage-articles .inner-content-container {
    flex: 1 1; }
  #therapist-manage-articles .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #therapist-manage-articles .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px; }
  #therapist-manage-articles .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd; }
  #therapist-manage-articles .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between; }
  #therapist-manage-articles .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #therapist-manage-articles .filters {
    display: flex;
    margin-top: 20px;
    justify-content: center; }
  #therapist-manage-articles .filters .filter-item {
    width: 150px;
    text-align: center;
    color: #457bd569;
    cursor: pointer; }
  #therapist-manage-articles .filters .middle-filter {
    border-right: 3px solid #457bd5a8; }
  #therapist-manage-articles .filters .filter-item.active-filter {
    color: #457bd5;
    font-weight: bold; }
  #therapist-manage-articles .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #therapist-manage-articles .small-gradient-button {
    width: 90%;
    height: 50px; }
  #therapist-manage-articles .small-gradient-button span {
    margin: 0; }
  #therapist-manage-articles .article-search-input {
    width: 100%; }
  #therapist-manage-articles .results-section {
    margin-top: 30px; }
  #therapist-manage-articles .message-span-container {
    padding-left: 30px; }
  #therapist-manage-articles .article-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #therapist-manage-articles .article-li-name {
    font-weight: 600; }
  #therapist-manage-articles .article-li-description {
    font-size: 15px; }
  #therapist-manage-articles .article-li-info {
    flex: 1 1;
    border-right: 1px solid #eee;
    display: flex;
    flex-wrap: wrap; }
  #therapist-manage-articles .article-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #therapist-manage-articles .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #therapist-manage-articles .add-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #therapist-manage-articles .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #therapist-manage-articles .edit-sp-name, #therapist-manage-articles .edit-sp-description {
    width: 90%; }
  #therapist-manage-articles .edit-sp-description {
    margin-top: 15px; }
  #therapist-manage-articles ul {
    list-style-type: disc;
    list-style-position: inside; }
  #therapist-manage-articles .attached-specialities-list {
    display: flex;
    flex-wrap: wrap; }
  #therapist-manage-articles .article-at-item {
    display: flex;
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    border-radius: 25px;
    align-items: center;
    margin: 5px 10px;
    justify-content: space-around;
    padding: 2px; }
  #therapist-manage-articles .article-at-outer {
    display: flex;
    background: #fff;
    padding: 5px 15px;
    border-radius: 25px;
    align-items: center;
    height: 45px; }
  #therapist-manage-articles .article-at-outer .article-at-info {
    height: 45px;
    display: flex;
    align-items: center;
    padding-right: 10px; }
  #therapist-manage-articles .article-at-outer .article-at-info h4 {
    margin: 0; }
  #therapist-manage-articles .article-at-actions {
    height: 45px;
    display: flex;
    align-items: center; }
  #therapist-manage-articles .attached-specialities-section {
    margin-top: 25px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px; }
  #therapist-manage-articles .accept-icon {
    color: green; }
  #therapist-manage-articles .decline-icon {
    color: red; }
  #therapist-manage-articles .article-tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    width: 100%; }
  #therapist-manage-articles .article-tag {
    background-color: #eee;
    border-radius: 5px;
    color: #126ef1;
    font-weight: bold;
    font-size: 16px;
    margin: 5px;
    padding: 5px 10px; }
    #therapist-manage-articles .article-tag .delete-icon {
      margin-left: 10px;
      color: #dc3545; }
  #therapist-manage-articles .article-thumbnail-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px; }
  #therapist-manage-articles .add-thumbnail-btn {
    width: 130px;
    height: 45px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
  #therapist-manage-articles .art-thb-container {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 30px; }
  #therapist-manage-articles .art-thb-container img {
    max-width: 100%;
    max-height: 100%; }
  #therapist-manage-articles .article-li-info .thumbnail-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #efefef;
    margin-right: 20px; }
  #therapist-manage-articles .article-li-info .thumbnail-container img {
    max-width: 100%;
    max-height: 100%; }
  #therapist-manage-articles .article-li-title {
    color: #634e70;
    font-weight: bold; }
  #therapist-manage-articles .article-li-body {
    font-size: 16px;
    padding-left: 10px;
    margin-top: 15px; }
  #therapist-manage-articles .article-li-details {
    font-size: 13px; }
  #therapist-manage-articles .data-container {
    flex: 1 1;
    max-height: 170px;
    overflow: hidden; }
  #therapist-manage-articles .error-message {
    height: 35px;
    margin: 10px auto; }
  #therapist-manage-articles .no-access-message {
    padding: 20px;
    font-size: 17px; }
  #therapist-manage-articles .plan-link {
    font-weight: bold;
    color: #126ef1; }
  #therapist-manage-articles .pc-options-container {
    width: 100%;
    justify-content: center; }

@media (max-width: 580px) {
  #therapist-manage-articles .inner-content {
    width: 95%; }
  #therapist-manage-articles .page-title {
    text-align: center; }
  #therapist-manage-articles .article-list-item {
    padding: 15px; }
  #therapist-manage-articles .article-list-item {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #therapist-manage-articles .article-li-actions {
    width: 100%;
    margin-top: 30px; }
  #therapist-manage-articles .article-li-info {
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: none; }
  #therapist-manage-articles .data-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; }
  #therapist-manage-articles .cooldown-message {
    text-align: center; }
  #therapist-manage-articles .article-li-body {
    text-align: center; } }

#blogger-manage-articles {
  background: #f1f2f361;
  position: relative; }
  #blogger-manage-articles .page-background {
    width: 100%;
    min-height: 100vh; }
  #blogger-manage-articles .fa-angle-right {
    display: none; }
  #blogger-manage-articles h1 {
    margin: 0; }
  #blogger-manage-articles .page-content {
    display: flex; }
  #blogger-manage-articles .inner-content-container {
    flex: 1 1; }
  #blogger-manage-articles .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #blogger-manage-articles .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px; }
  #blogger-manage-articles .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd; }
  #blogger-manage-articles .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between; }
  #blogger-manage-articles .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #blogger-manage-articles .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #blogger-manage-articles .small-gradient-button {
    width: 90%;
    height: 50px; }
  #blogger-manage-articles .small-gradient-button span {
    margin: 0; }
  #blogger-manage-articles .article-search-input {
    width: 100%; }
  #blogger-manage-articles .results-section {
    margin-top: 30px; }
  #blogger-manage-articles .message-span-container {
    padding-left: 30px; }
  #blogger-manage-articles .article-list-item {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #blogger-manage-articles .article-li-name {
    font-weight: 600; }
  #blogger-manage-articles .article-li-description {
    font-size: 15px; }
  #blogger-manage-articles .article-li-info {
    flex: 1 1;
    border-right: 1px solid #eee;
    display: flex;
    flex-wrap: wrap; }
  #blogger-manage-articles .article-li-actions {
    width: 70px;
    display: flex;
    justify-content: space-evenly; }
  #blogger-manage-articles .edit-icon:hover {
    color: #ffc107;
    cursor: pointer; }
  #blogger-manage-articles .add-icon:hover {
    color: #28a745;
    cursor: pointer; }
  #blogger-manage-articles .delete-icon:hover {
    color: #dc3545;
    cursor: pointer; }
  #blogger-manage-articles .edit-sp-name, #blogger-manage-articles .edit-sp-description {
    width: 90%; }
  #blogger-manage-articles .edit-sp-description {
    margin-top: 15px; }
  #blogger-manage-articles ul {
    list-style-type: disc;
    list-style-position: inside; }
  #blogger-manage-articles .attached-specialities-list {
    display: flex;
    flex-wrap: wrap; }
  #blogger-manage-articles .article-at-item {
    display: flex;
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    border-radius: 25px;
    align-items: center;
    margin: 5px 10px;
    justify-content: space-around;
    padding: 2px; }
  #blogger-manage-articles .article-at-outer {
    display: flex;
    background: #fff;
    padding: 5px 15px;
    border-radius: 25px;
    align-items: center;
    height: 45px; }
  #blogger-manage-articles .article-at-outer .article-at-info {
    height: 45px;
    display: flex;
    align-items: center;
    padding-right: 10px; }
  #blogger-manage-articles .article-at-outer .article-at-info h4 {
    margin: 0; }
  #blogger-manage-articles .article-at-actions {
    height: 45px;
    display: flex;
    align-items: center; }
  #blogger-manage-articles .attached-specialities-section {
    margin-top: 25px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 15px; }
  #blogger-manage-articles .accept-icon {
    color: green; }
  #blogger-manage-articles .decline-icon {
    color: red; }
  #blogger-manage-articles .article-tags {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    width: 100%; }
  #blogger-manage-articles .article-tag {
    background-color: #eee;
    border-radius: 5px;
    color: #126ef1;
    font-weight: bold;
    font-size: 16px;
    margin: 5px;
    padding: 5px 10px; }
    #blogger-manage-articles .article-tag .delete-icon {
      margin-left: 10px;
      color: #dc3545; }
  #blogger-manage-articles .article-thumbnail-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px; }
  #blogger-manage-articles .add-thumbnail-btn {
    width: 130px;
    height: 45px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
  #blogger-manage-articles .art-thb-container {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 30px; }
  #blogger-manage-articles .art-thb-container img {
    max-width: 100%;
    max-height: 100%; }
  #blogger-manage-articles .article-li-info .thumbnail-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #efefef;
    margin-right: 20px; }
  #blogger-manage-articles .article-li-info .thumbnail-container img {
    max-width: 100%;
    max-height: 100%; }
  #blogger-manage-articles .article-li-title {
    color: #634e70;
    font-weight: bold; }
  #blogger-manage-articles .article-li-body {
    font-size: 16px;
    padding-left: 10px;
    margin-top: 15px; }
  #blogger-manage-articles .article-li-details {
    font-size: 13px; }
  #blogger-manage-articles .data-container {
    flex: 1 1;
    max-height: 170px;
    overflow: hidden; }
  #blogger-manage-articles .error-message {
    height: 35px;
    margin: 10px auto; }
  #blogger-manage-articles .no-access-message {
    padding: 20px;
    font-size: 17px; }
  #blogger-manage-articles .plan-link {
    font-weight: bold;
    color: #126ef1; }
  #blogger-manage-articles .pc-options-container {
    width: 100%;
    justify-content: center; }

@media (max-width: 580px) {
  #blogger-manage-articles .inner-content {
    width: 95%; }
  #blogger-manage-articles .page-title {
    text-align: center; }
  #blogger-manage-articles .article-list-item {
    padding: 15px; }
  #blogger-manage-articles .article-list-item {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center; }
  #blogger-manage-articles .article-li-actions {
    width: 100%;
    margin-top: 30px; }
  #blogger-manage-articles .article-li-info {
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: none; }
  #blogger-manage-articles .data-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px; }
  #blogger-manage-articles .cooldown-message {
    text-align: center; }
  #blogger-manage-articles .article-li-body {
    text-align: center; } }

.thankYouContainer {
  margin: 0 auto;
  max-width: 2560px; }
  .thankYouContainer .thankYouContent {
    margin-top: 70px;
    color: #634e70;
    text-align: center;
    background: url(/static/media/home-favourite-products-background-missized.9f68054c.png) no-repeat;
    background-position: center 220px;
    background-size: auto 1500px;
    background-position-y: 150px;
    height: 1400px; }
    .thankYouContainer .thankYouContent li {
      display: inline-block;
      font-size: 20px;
      padding: 20px; }
    .thankYouContainer .thankYouContent p {
      text-align: center;
      align-self: inherit;
      font-size: 20px;
      margin-bottom: 30px; }
    .thankYouContainer .thankYouContent h2 {
      margin-bottom: 20px; }
    .thankYouContainer .thankYouContent .SUCCESS_MESSAGE {
      color: red; }

.apply-button-container .apply-button {
  line-height: 48px; }

.signature {
  box-shadow: none !important;
  width: 250px; }

.thank-you {
  color: darkgreen;
  font-weight: 700 !important; }

.product-card {
  background-color: #fff;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  /* height: 100%; */
  /* overflow: hidden; */
  flex-wrap: wrap;
  cursor: pointer; }
  @media (max-width: 575px) {
    .product-card {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; } }
  .product-card .product--image {
    width: 100%;
    height: 200px;
    border-radius: 30px 30px 0 0; }
  .product-card .product--tag {
    padding: 20px 35px 0;
    font-size: 16px; }
  .product-card .product-title {
    text-align: center;
    margin: 20px 5px;
    font-size: 20px;
    font-weight: bold;
    color: #634e70;
    height: 90px;
    overflow: hidden; }
  .product-card .product--short-content {
    color: #634e70;
    padding: 0 15px;
    margin-bottom: auto;
    height: 150px;
    overflow: hidden;
    font-size: 15px;
    text-align: center; }
  .product-card .product--price {
    color: #457bd5;
    display: block;
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    padding: 15px 35px 0;
    text-align: center; }
  .product-card .product--button-container {
    margin-top: 30px;
    padding: 0 35px;
    padding: 0 35px 35px; }
    .product-card .product--button-container .product--button {
      box-shadow: none;
      line-height: 48px; }
  .product-card .product--content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 297px; }
  .product-card.product--first {
    border: 0 none;
    display: flex;
    flex-direction: row;
    flex-flow: row-reverse;
    height: 375px; }
    @media (max-width: 575px) {
      .product-card.product--first {
        flex-direction: column; } }
    .product-card.product--first .product--image-container {
      flex-basis: 50%; }
      @media (max-width: 991px) {
        .product-card.product--first .product--image-container {
          flex-basis: 45%; } }
      .product-card.product--first .product--image-container .product--image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0 30px 30px 0; }
        @media (max-width: 600px) {
          .product-card.product--first .product--image-container .product--image {
            border-radius: 30px 30px 0 0;
            height: 200px; } }
    .product-card.product--first .product--content {
      padding: 20px 25px;
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      max-width: 500px; }
      @media (max-width: 991px) {
        .product-card.product--first .product--content {
          flex-basis: 55%;
          padding: 20px 0px; } }
      .product-card.product--first .product--content .product--tag {
        font-size: 20px;
        margin-top: auto;
        padding-top: 0; }
      .product-card.product--first .product--content .product--title {
        font-size: 25px;
        padding: 10px 35px 20px;
        height: 60px; }
      .product-card.product--first .product--content .product--short-content {
        line-height: 25px; }
      .product-card.product--first .product--content .product--button-container {
        margin-bottom: auto;
        max-width: 290px;
        padding-bottom: 0; }
  .product-card .art-small-thb {
    height: 200px;
    overflow: hidden; }

#productsPage {
  margin-top: 40px; }
  #productsPage .blog-text-ontop {
    padding-right: 40px;
    padding-top: 10px; }
  #productsPage .page-title {
    text-align: center;
    font-size: 43px;
    margin-bottom: 130px;
    color: #ffff; }
  #productsPage input {
    outline: none; }
  #productsPage .article-search--container .article-search--input-background {
    background-image: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a724ec));
    background-image: linear-gradient(to right, #106ff1 0%, #a724ec 100%);
    border-radius: 30px;
    padding: 2px;
    position: relative; }
    #productsPage .article-search--container .article-search--input-background input {
      border: 0 none;
      border-radius: 30px;
      height: 54px;
      padding-left: 30px;
      padding-right: 70px;
      width: 100%; }
      @media (max-width: 575px) {
        #productsPage .article-search--container .article-search--input-background input {
          padding-left: 15px;
          padding-right: 15px; } }
      #productsPage .article-search--container .article-search--input-background input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #457bd5; }
      #productsPage .article-search--container .article-search--input-background input::-moz-placeholder {
        /* Firefox 19+ */
        color: #457bd5; }
      #productsPage .article-search--container .article-search--input-background input:-ms-input-placeholder {
        /* IE 10+ */
        color: #457bd5; }
      #productsPage .article-search--container .article-search--input-background input:-moz-placeholder {
        /* Firefox 18- */
        color: #457bd5; }
    #productsPage .article-search--container .article-search--input-background .search-icon {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      @media (max-width: 575px) {
        #productsPage .article-search--container .article-search--input-background .search-icon {
          display: none; } }
  #productsPage .filter-icon {
    cursor: pointer;
    margin: auto; }
    @media (max-width: 575px) {
      #productsPage .filter-icon {
        margin: auto 0 auto auto; } }
  #productsPage .current-page {
    color: black;
    cursor: default;
    background-color: white; }
  #productsPage .load-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px auto; }
  #productsPage .invisible-btn {
    display: none; }
  #productsPage .pagination {
    justify-content: center; }
  #productsPage .page-link {
    border: 0; }
  #productsPage .page-item {
    cursor: pointer; }

@media (max-width: 450px) {
  #productsPage .article-card {
    max-width: 300px; }
  #productsPage .articles-list {
    padding-left: 0; } }

@media (max-width: 750px) {
  #productsPage .blog-text-ontop {
    padding: 0; }
  #productsPage .page-title {
    text-align: center;
    padding-top: 5px;
    color: white;
    margin-bottom: 55px; }
  #productsPage .article-card {
    height: auto; }
  #productsPage .article-title {
    font-size: 20px; }
  #productsPage .article-content {
    flex-basis: 55%;
    padding: 20px 0px;
    flex-direction: column;
    align-items: center; }
  #productsPage .article--button-container {
    max-width: 100%;
    width: 100%; }
  #productsPage .art-small-thb {
    width: 100%; }
  #productsPage .article--content {
    width: 100%; }
  #productsPage .article--short-content {
    padding: 0;
    margin: 0 30px; } }

.blog-listing .blog-listing--image {
  border-radius: 20px;
  height: 100%;
  max-width: 100%;
  object-fit: cover; }
  @media (max-width: 767px) {
    .blog-listing .blog-listing--image {
      display: block;
      margin-left: auto;
      margin-right: auto; } }

.blog-listing .blog-listing--right {
  display: flex;
  flex-direction: column; }
  @media (max-width: 767px) {
    .blog-listing .blog-listing--right {
      margin-top: 20px; } }
  .blog-listing .blog-listing--right .blog-listing--date {
    font-family: 'Montserrat', sans-serif; }
  .blog-listing .blog-listing--right .blog-listing--title {
    color: #634e70;
    font-family: 'Montserrat', sans-serif;
    font-size: 23px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: auto; }
    @media (max-width: 767px) {
      .blog-listing .blog-listing--right .blog-listing--title {
        text-align: center; } }
  @media (max-width: 767px) {
    .blog-listing .blog-listing--right .blog-feat-snippet {
      text-align: left; } }
  .blog-listing .blog-listing--right .read-button {
    margin-top: 15px; }
  .blog-listing .blog-listing--right .btn.btn-default {
    font-size: 20px; }

.blog-listing .f-art-thb-container {
  width: 250px;
  height: 250px; }

login-btn {
  margin-left: 40px; }
  login-btn img {
    margin-right: 10px; }
  @media (max-width: 767px) {
    login-btn {
      margin-left: 0; } }

.header {
  background-position-x: center;
  background-repeat: no-repeat;
  padding-top: 50px;
  position: relative; }
  .header h1 {
    font-weight: 600; }
  .header .search-form-container p {
    color: white; }
  @media (max-width: 768px) {
    .header .search-form-container {
      margin-top: 10px;
      margin-bottom: 40px; } }
  .header .greeting {
    margin-left: 180px; }
    @media (max-width: 768px) {
      .header .greeting {
        margin-left: 0; } }
    @media (max-width: 780px) {
      .header .greeting {
        margin: 0px; } }
  .header #searchForm {
    margin-top: 0; }
  .header .close-mobile-menu {
    display: none; }
    @media (max-width: 991px) {
      .header .close-mobile-menu {
        display: block;
        position: absolute;
        right: 10px;
        color: white;
        top: 31px;
        font-size: 20px;
        font-weight: 900; } }
  .header .menu li {
    font-size: 20px;
    font-weight: 600; }
    @media (max-width: 992px) {
      .header .menu li {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important; } }
  .header.header-home {
    background-image: url(/static/media/homepage-header-background_missized.aed965dd.png);
    padding-top: 20px;
    background-size: cover; }
    @media (max-width: 1566px) {
      .header.header-home {
        background-size: contain; } }
    @media (max-width: 991px) {
      .header.header-home {
        background-image: url(/static/media/home-bg-md.d02fb84d.png);
        background-size: contain; } }
    @media (max-width: 767px) {
      .header.header-home {
        background-image: url(/static/media/home-mobile-bg.f366228b.png);
        background-size: contain;
        padding-top: 220px; } }
    @media (max-width: 350px) {
      .header.header-home {
        background-size: auto 480px;
        padding-top: 170px; } }
    .header.header-home .search-panel .search-field--bg {
      padding: 2px; }
    .header.header-home .search-form-container p {
      color: #634e70; }
    .header.header-home .menu {
      margin-bottom: 50px;
      width: 100%; }
      .header.header-home .menu .sub-menu-link {
        color: #437cd0; }
      .header.header-home .menu li a {
        color: #457bd5; }
        @media (max-width: 991px) {
          .header.header-home .menu li a {
            color: #fff;
            font-weight: bold; } }
        .header.header-home .menu li a:hover {
          border-bottom-color: transparent; }
  .header .search-white-text {
    color: #ffff;
    margin-top: 40px; }
    @media (max-width: 767px) {
      .header .search-white-text {
        margin-top: -10px; } }
  .header .burger-menu {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAASBAMAAACzwgOtAAAAAXNSR0IB2cksfwAAABhQTFRFAAAAFXD0Em/xEm7xE3DyEm/xE27yE2/yc7wIkAAAAAh0Uk5TADDv/2DwwL8sNGSoAAAAJUlEQVR4nGNgYFQ2hgATBgYGxmAkDlVBsjEcmCNzzKhtES7/AAC1bgyEre4qGwAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    -webkit-filter: drop-shadow(0px 0px 1px #fff);
            filter: drop-shadow(0px 0px 1px #fff);
    height: 18px;
    padding: 20px;
    position: absolute;
    text-align: center;
    right: 20px;
    top: 70px;
    width: 24px; }
    @media (min-width: 992px) {
      .header .burger-menu {
        display: none; } }
    @media (max-width: 350px) {
      .header .burger-menu {
        right: 20px; } }
    .header .burger-menu .toggle-button__line {
      display: none; }
  .header .bg-image {
    height: 1200px;
    left: 50%;
    max-width: 3020px;
    object-fit: cover;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    z-index: -1;
    margin-top: -350px; }
    @media (max-width: 575px) {
      .header .bg-image {
        headerBackground: url(/static/media/header-background.2a875404.png);
        margin-top: 0px;
        height: 730px;
        background-size: 90px;
        object-position: -370px -93px; } }
  .header .menu {
    align-items: center;
    display: flex;
    flex-flow: wrap;
    font-size: 16px;
    margin-bottom: 0;
    padding-left: 0; }
    @media (min-width: 768px) {
      .header .menu {
        justify-content: space-between; } }
    @media (max-width: 991px) {
      .header .menu {
        width: 100%; } }
    @media (max-width: 991px) {
      .header .menu {
        background-color: rgba(99, 78, 112, 0.8);
        flex-direction: column;
        flex-wrap: nowrap;
        left: 0;
        padding: 20px 0;
        position: fixed;
        top: 0;
        visibility: hidden;
        width: 100vw;
        z-index: 10; } }
    .header .menu .btn.btn-default {
      height: 50px;
      width: 150px; }
      @media (max-width: 991px) {
        .header .menu .btn.btn-default {
          width: 160px; } }
    .header .menu li {
      list-style: none;
      margin-left: 15px;
      margin-right: 15px; }
      .header .menu li:first-of-type {
        margin-left: 0; }
      @media (max-width: 991px) {
        .header .menu li {
          margin-left: 10px;
          margin-right: 10px; } }
      @media (max-width: 767px) {
        .header .menu li {
          text-align: center;
          width: 100%;
          margin: 0 auto; } }
      .header .menu li.login-btn {
        margin-left: 40px; }
        .header .menu li.login-btn img {
          margin-right: 10px; }
        @media (max-width: 767px) {
          .header .menu li.login-btn {
            margin-left: 0; } }
      .header .menu li a {
        color: #fff;
        padding: 5px 0;
        border: solid 1px transparent; }
        .header .menu li a:hover, .header .menu li a.active {
          border-bottom-color: #fff; }
        @media (max-width: 767px) {
          .header .menu li a {
            display: block;
            padding: 10px; } }
  .header .logo {
    margin-right: auto;
    height: auto; }
    @media (max-width: 991px) {
      .header .logo {
        padding-bottom: 20px;
        text-align: center;
        width: 230px;
        max-width: 230px; } }
    @media (max-width: 767px) {
      .header .logo {
        margin: 10px 0 40px;
        text-align: left;
        padding-bottom: 0; } }
    @media (max-width: 575px) {
      .header .logo img {
        width: 230px; } }
  .header .media-icons-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -50px;
    height: 45px; }
  @media (max-width: 766px) {
    .header .media-icons-container {
      justify-content: space-around; } }

.round-input-w-label-outer {
  width: 100%;
  height: 60px;
  background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
  background: linear-gradient(to right, #106ff1, #a723ec);
  padding: 2px;
  border-radius: 30px; }
  .round-input-w-label-outer .round-input-w-label-inner {
    display: flex;
    justify-content: flex-start;
    padding-left: 50px;
    align-items: center;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    overflow: hidden; }
  .round-input-w-label-outer .riwl-label {
    font-size: 25px;
    font-weight: 700;
    color: #126ef1; }
  .round-input-w-label-outer .riwl-input-container {
    flex: 1 1;
    margin-left: 20px; }
  .round-input-w-label-outer .riwl-input {
    border: none;
    outline: none; }

.google-places-autocomplete__input {
  border: none;
  margin-left: 25px; }

.google-places-autocomplete__suggestion {
  cursor: pointer;
  background-color: #fff; }

.google-places-autocomplete__suggestion:hover {
  cursor: pointer;
  background: #457bd5;
  color: #fff;
  font-weight: bold; }

.google-places-autocomplete .google-places-autocomplete__input {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 25px;
  text-indent: 10px; }

input:focus {
  outline: none; }

#-google-places-suggestions-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  position: absolute;
  top: 50px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  min-width: 100%; }

.tc-list-item {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 30px;
  padding: 20px 35px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  margin: 25px 0; }
  .tc-list-item .like-link {
    color: #457bd5; }
  .tc-list-item .tc-image-container {
    width: 130px;
    height: 130px;
    line-height: 130px;
    border-radius: 15px;
    overflow: hidden; }
  .tc-list-item .tc-image-container img {
    width: 100%; }
  .tc-list-item .tc-info-container {
    flex: 1 1;
    padding-left: 50px; }
  .tc-list-item .tc-actions-container {
    width: 120px; }
  .tc-list-item .right-col {
    display: flex;
    align-items: center; }
  .tc-list-item .tc-main-info {
    font-size: 25px;
    color: #634e70;
    flex-wrap: wrap; }
  .tc-list-item .tc-main-info .ai-u-name {
    font-weight: 600; }
  .tc-list-item .tc-details-info {
    font-size: 17px;
    color: #634e70; }
  .tc-list-item .tc-detail-container {
    display: flex; }
  .tc-list-item .tc-detail-label {
    width: 75px;
    font-weight: 600; }
  .tc-list-item .tc-actions-container {
    display: flex;
    justify-content: space-evenly; }
  .tc-list-item .tr-reviews-value {
    margin-left: 20px; }
  .tc-list-item .contact-info-item {
    margin: 5px 0 5px;
    align-items: center; }
    @media (max-width: 768px) {
      .tc-list-item .contact-info-item {
        max-width: 250px;
        width: 100%;
        overflow: hidden;
        display: flex; } }
  .tc-list-item .contact-info-value {
    margin-left: 15px; }
  .tc-list-item .left-col {
    width: 130px; }
  .tc-list-item .tr-reviews-row {
    margin-bottom: 20px; }
  .tc-list-item .therapist-flag {
    font-size: 13px;
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    color: #28a745; }
  .tc-list-item .online-flag {
    font-size: 13px;
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #53d6d6; }
  .tc-list-item .therapist-flag-text {
    margin-left: 5px; }
  .tc-list-item .green-icon {
    font-size: 30px; }
  .tc-list-item .yellow-icon {
    font-size: 30px;
    color: #ebbd34; }
  .tc-list-item .therapist-insurance-flag {
    color: #ebbd34;
    margin-left: 5px; }

.hide-distance {
  display: none !important; }

.flags-row {
  padding-top: 5px;
  display: block;
  margin-left: -20px !important; }

@media (max-width: 576px) {
  .tc-list-item {
    padding: 20px 20px;
    flex-direction: column;
    align-items: center; }
    .tc-list-item .tc-info-container {
      padding: 0;
      margin-left: 0px;
      padding-left: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 3; }
    .tc-list-item .tc-main-info {
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 10px;
      text-align: center; }
    .tc-list-item .tc-u-title {
      text-align: center;
      margin-left: -15px; }
    .tc-list-item .tc-details-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 15px;
      width: 90%; }
    .tc-list-item .tr-reviews-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 0; }
    .tc-list-item .tr-reviews-value {
      margin: 5px auto;
      text-align: center; }
    .tc-list-item .contact-info-list {
      width: 100%; }
    .tc-list-item .left-col {
      order: 1; }
    .tc-list-item .right-col {
      order: 2; } }

.rd-container .rd-input-outer {
  width: 100%;
  height: 60px;
  background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
  background: linear-gradient(to right, #106ff1, #a723ec);
  padding: 2px;
  border-radius: 30px; }

.rd-container .rd-input-inner {
  display: flex;
  justify-content: flex-start;
  padding-left: 50px;
  align-items: center;
  height: 100%;
  background: #fff;
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer; }

.rd-container .rd-input-placeholder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 15px;
  align-items: center; }

.rd-container .rd-input-placeholder-text {
  flex: 1 1;
  padding-left: 15px; }

.rd-container .rd-label {
  font-size: 25px;
  font-weight: 700;
  color: #126ef1; }

.rd-container .rd-options-open {
  position: absolute;
  z-index: 1000;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 100%;
  border-top: none; }

.rd-container .rd-options-hidden {
  display: none; }

.rd-container .rd-option-item {
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  color: #634e70; }

.rd-container .rd-option-item:hover {
  background-image: -webkit-gradient(linear, left top, right top, from(#2441d1), to(#c380d6));
  background-image: linear-gradient(to right, #2441d1 0%, #c380d6 100%);
  color: #fff;
  cursor: pointer; }

#therapist-profiles .therapist-profiles-content {
  max-width: 960px;
  margin: 80px auto; }

#therapist-profiles .search-form-container {
  margin-bottom: 0px; }

#therapist-profiles .load-more-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 35px auto; }

#therapist-profiles .invisible-btn {
  display: none; }

#therapist-profiles .search-by-name-section {
  margin: 35px auto;
  max-width: 960px; }

#therapist-profiles .search-by-name-input-container {
  display: flex;
  justify-content: space-between; }

#therapist-profiles .search-by-name-btn {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center; }

#therapist-profiles .search-by-name-input {
  flex: 1 1;
  margin-right: 35px; }

@media only screen and (max-width: 660px) {
  .tc-u-name {
    text-align: center; }
  .tc-b-name {
    text-align: center; } }

@media (max-width: 460px) {
  #therapist-profiles .search-by-name-section {
    width: 100%;
    padding: 50px 0px;
    margin: 0; }
  #therapist-profiles .page-title {
    text-align: center; }
  #therapist-profiles .search-by-name-input-container {
    flex-direction: column;
    align-items: center; }
  #therapist-profiles .round-input-gradient.search-by-name-input {
    margin: 15px 0;
    width: 100%; }
  #therapist-profiles .therapist-profiles-content {
    margin-top: 0; } }

.formDescription {
  color: #634e70;
  font-weight: 600;
  font-size: 25px;
  margin-bottom: 2rem;
  margin-top: 2rem; }
  .formDescription.home {
    color: #634e70; }
  @media (max-width: 767px) {
    .formDescription {
      color: #634e70;
      text-align: center;
      margin-top: 0; } }

.sponsored-therapist-list {
  margin-top: 20px;
  border-radius: 30px;
  padding: 10px 30px; }

.sponsored-therapist-back {
  background-color: #a2e3f7; }

.search-panel .search-field--select {
  border-radius: 30px;
  max-width: 334px;
  margin: 0 auto 20px;
  width: 100%;
  padding: 2px;
  position: relative;
  color: dimgrey;
  display: none; }
  .search-panel .search-field--select.error {
    display: inline;
    display: initial; }
    .search-panel .search-field--select.error p {
      color: red; }
    .search-panel .search-field--select.error .search-field--label {
      color: #dc3545; }
    @media (max-width: 768px) {
      .search-panel .search-field--select.error {
        padding-top: 70px; } }
  @media (min-width: 768px) {
    .search-panel .search-field--select {
      flex: 0 0 48%;
      max-width: 48%;
      margin: 0 1% 30px; } }

.search-panel .search-field--bg {
  background-image: -webkit-gradient(linear, left top, right top, from(#2441d1), to(#c380d6));
  background-image: linear-gradient(to right, #2441d1 0%, #c380d6 100%);
  border-radius: 30px;
  max-width: 334px;
  margin: 0 auto 20px;
  width: 100%;
  padding: 2px;
  position: relative;
  color: dimgrey; }
  .search-panel .search-field--bg.error {
    background-image: -webkit-gradient(linear, left top, right top, from(#dc3545), to(#dc3545));
    background-image: linear-gradient(to right, #dc3545 0%, #dc3545 100%); }
    .search-panel .search-field--bg.error .search-field--label {
      color: #dc3545; }
  @media (min-width: 768px) {
    .search-panel .search-field--bg {
      flex: 0 0 48%;
      max-width: 48%;
      margin: 0 1% 30px; } }

.search-panel .search-field--button {
  align-items: center;
  display: flex;
  flex-direction: row;
  text-align: left; }
  @media (max-width: 767px) {
    .search-panel .search-field--button {
      margin: 0 auto;
      max-width: 334px;
      width: 100%; } }

@media (max-width: 767px) {
  .search-panel .search-field--button-container {
    padding: 0; } }

.search-panel .autocomplete-input {
  position: relative; }
  .search-panel .autocomplete-input input {
    width: 100%; }
  .search-panel .autocomplete-input .autocomplete-input--list {
    background: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-left: 5px;
    max-height: 250px;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    z-index: 2; }
    .search-panel .autocomplete-input .autocomplete-input--list .autocomplete-input--list-item {
      padding: 2px 10px; }
      .search-panel .autocomplete-input .autocomplete-input--list .autocomplete-input--list-item.active {
        background-image: -webkit-gradient(linear, left top, right top, from(#2441d1), to(#c380d6));
        background-image: linear-gradient(to right, #2441d1 0%, #c380d6 100%);
        color: #fff; }

.search-panel .no-bg-image {
  background-image: none; }

.search-panel .item-suggest-outer {
  width: 100%;
  height: 60px;
  background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
  background: linear-gradient(to right, #106ff1, #a723ec);
  padding: 2px;
  border-radius: 30px; }

.search-panel .item-suggest-inner, .search-panel .loc-suggest-inner {
  display: flex;
  justify-content: flex-start;
  padding-left: 50px;
  align-items: center;
  height: 100%;
  background: #fff;
  border-radius: 30px;
  overflow: hidden; }

.search-panel .loc-suggest-inner {
  overflow: visible; }

.search-panel .item-suggest-label {
  font-size: 25px;
  font-weight: 700;
  color: #126ef1;
  cursor: pointer; }

.search-panel .item-suggest-input {
  flex: 1 1;
  margin-left: 20px;
  border: none;
  outline: none; }

.search-panel .suggested-specs-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  position: absolute;
  width: 100%;
  top: 60px;
  z-index: 10;
  padding-top: 30px;
  display: block; }

.search-panel .suggested-spec-item {
  display: flex;
  padding: 10px;
  flex-wrap: wrap; }

.search-panel .speciality-select-box {
  margin-right: 20px; }

.search-panel .google-places-autocomplete__input {
  background: transparent;
  box-shadow: none; }

.search-panel .google-places-autocomplete__suggestions-container {
  width: 130%;
  left: -100px; }

.search-panel .google-places-autocomplete__suggestion {
  cursor: pointer; }

.search-panel .google-places-autocomplete__suggestion:hover {
  background: #457bd5;
  color: #fff;
  font-weight: bold; }

.search-panel #ms-suggested-insurances {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  border-top: none;
  position: absolute;
  width: 100%; }

.search-panel .suggested-ins-item {
  display: flex;
  justify-content: space-around;
  height: 40px;
  align-items: center;
  cursor: pointer; }

.search-panel .suggested-insurances-list {
  margin: 0; }

.search-panel .suggested-ins-item:hover {
  background: #457bd5;
  color: #fff;
  font-weight: bold; }

.search-panel .hidden-element {
  display: none; }

.search-panel .speciality-placeholder {
  width: 100%;
  display: flex;
  padding-left: 50px;
  justify-content: space-between;
  padding-right: 20px;
  cursor: pointer; }

.search-panel .specialit-drop-arrow {
  color: #126ef1; }

.search-panel .speciality-placeholder-text {
  color: dimgrey; }

.search-panel .spec-list-close-btn {
  width: 100%;
  border: 1px solid #eee;
  margin-top: -30px;
  /* border-radius: 15px; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  justify-content: center; }

.search-panel .spec-list-clos-btn-item {
  margin: 0 10px; }

.search-panel .spec-list-close-icon {
  color: #dc3545; }

.search-panel .spec-list-close-text {
  font-weight: bold;
  color: #ccc; }

.no-results-container {
  width: 80%;
  margin-top: 50px; }

.no-results-text h3 {
  color: #634e70;
  font-size: 25px;
  font-weight: bold; }

.no-results-text h4 {
  color: #634e70;
  font-size: 20px;
  line-height: 1.4;
  text-align: justify;
  padding-bottom: 15px; }

.small-btn-container {
  width: 200px;
  margin-top: 15px; }

.speciality-select-name {
  margin-bottom: 0; }

@media (max-width: 580px) {
  .search-panel .item-suggest-inner, .search-panel .loc-suggest-inner, .search-panel .round-input-w-label-inner, .search-panel .rd-input-inner {
    padding-left: 15px; }
  .search-panel .speciality-placeholder {
    padding: 10px; }
  .search-panel .riwl-label-text span {
    display: none; }
  .search-panel .riwl-label-text:after {
    content: 'Ins.'; }
  .search-panel .google-places-autocomplete__suggestions-container {
    left: -65px; }
  .search-panel .suggested-spec-item {
    align-items: center;
    font-size: 15px; }
  .search-panel .speciality-select-name {
    flex: 1 1; } }

.custom-radio {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAAXNSR0IB2cksfwAAADZQTFRFAAAARX3XR3vVRnvVRXvVR37VRnzWRnvWRXzWRnzVRnzVSHzXUIDfRnvWRXzWRnzWRnzXSX3XRGb/WwAAABJ0Uk5TAGCfz/9PUN+Q0IBAEI/goH8/5ME0cwAAAIhJREFUeJx1kNEWwyAIQ2nJsChq+/8/O7up1TOXJ71CwBB9tO0M8P6iR+JQ5aSxA1AfzIJXIHZ21GdrZwFkcPreUq+rfa7MhY6MTEtphp8geWRShBkGMAE2QwPWcNGu60EbeOo3d2//s3z69807kFgdLPZwCuXzMrtOfgIjSS3kNDiR5JKv5oregFcEkzBYEF8AAAAASUVORK5CYII=) no-repeat left center;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 30px;
  padding-left: 40px;
  position: relative;
  white-space: nowrap;
  font-size: 1.3rem;
  max-width: 100%;
  overflow: hidden; }
  .custom-radio[data-checked="true"], .custom-radio:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAAXNSR0IB2cksfwAAADxQTFRFAAAARX3XR3vVRnvVRXvVR37VRnzWRnvWRXzWRnzVRnzVSHzXUIDfRnvWR33WRnvXRXzWRnzWRnzXSX3XwO0udAAAABR0Uk5TAGCfz/9PUN+Q0IBAEI9wX+Cgfz++0L9KAAAAoUlEQVR4nG1QCQ7DIAwLxYMGQgft//+6pOOYplqKBI5zmeiG2zzgtxcthIiOGAa3A5yySE4MlMntPS3jHQCtOazne/yoak78t6UXrYs6F0zEY5AnYZU2JDow4SihqSgvoUmzBiCEH5BoPJJ/5azl/DzI2XK8hBJt+3v5zrItX+eZTml280wzpEg3pExzlPXnJXKdfhlGoY5BdZpsdLOerVMfezwHCh/rItYAAAAASUVORK5CYII=); }

#homepage .hp-section-title {
  margin-bottom: 30px;
  color: #fff; }

#homepage .hp-section-subtitle {
  margin-bottom: 50px;
  color: #ff3a4b;
  font-weight: bold; }

#homepage .text-d {
  color: #fff; }

.home-second-bg {
  background: url(/static/media/home-favourite-products-background-missized.9f68054c.png) no-repeat;
  background-position: center 40px;
  background-size: 2000px; }
  @media (max-width: 991px) {
    .home-second-bg {
      background-size: auto 1300px;
      background-position-y: 0px;
      display: block; }
      .home-second-bg h2 {
        margin-top: 30px; } }
  @media (max-width: 767px) {
    .home-second-bg {
      background-position-y: 150px;
      background-size: 2700px; } }
  .home-second-bg .favorite-section h2 {
    margin-top: 30px; }
  @media (max-width: 767px) {
    .home-second-bg .favorite-section {
      padding-top: 0px; }
      .home-second-bg .favorite-section p {
        text-align: left; } }
  .home-second-bg .special-section {
    max-width: 1200px;
    margin: 50px auto 0 auto; }
    @media (max-width: 780px) {
      .home-second-bg .special-section {
        display: block;
        margin: auto;
        margin-top: 70px; } }
    @media (max-width: 767px) {
      .home-second-bg .special-section {
        margin-top: 0px; }
        .home-second-bg .special-section p {
          text-align: left; } }
  .home-second-bg .travel-section {
    margin-top: 40px; }
    .home-second-bg .travel-section h2 {
      margin-bottom: 45px;
      color: white; }
    .home-second-bg .travel-section .container {
      padding-bottom: 150px; }
    @media (max-width: 767px) {
      .home-second-bg .travel-section h2 {
        color: #634e70; }
      .home-second-bg .travel-section p {
        text-align: left; }
      .home-second-bg .travel-section .container {
        padding-bottom: 50px; } }

.home-logo {
  margin: 100px auto 90px;
  text-align: center; }
  @media (max-width: 991px) {
    .home-logo {
      margin: 110px 0 70px; }
      .home-logo img {
        height: 100px;
        width: auto; } }

.home-title {
  margin-left: 40px;
  margin-bottom: 55px;
  padding-bottom: 20px;
  width: 66.66%; }
  @media (max-width: 991px) {
    .home-title {
      margin: 80px auto 0 100px;
      width: 80%; } }
  @media (max-width: 767px) {
    .home-title {
      margin: 0 auto;
      width: 90%; } }
  .home-title h1 {
    color: #634e70;
    font-size: 30px;
    font-weight: 600; }

@media (max-width: 767px) {
  .home-provider-profiles-left {
    text-align: center; } }

.home-provider-profiles-left img {
  max-width: 100%;
  height: auto; }

.business-name {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: auto;
  overflow: hidden;
  height: 60px; }

.partners-name {
  color: #634e70;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: auto;
  overflow: hidden;
  height: 60px; }

.home-provider-profiles-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 50px; }
  .home-provider-profiles-right h2 {
    margin: 0;
    line-height: 33px; }
    @media (max-width: 767px) {
      .home-provider-profiles-right h2 {
        margin: 40px 0; } }
  .home-provider-profiles-right span {
    line-height: 44px; }
  @media (max-width: 991px) {
    .home-provider-profiles-right {
      padding: 20px 0 25px; } }
  @media (max-width: 767px) {
    .home-provider-profiles-right {
      padding-bottom: 0; }
      .home-provider-profiles-right button {
        margin: 40px auto 0; } }

.inclusive-text h3 {
  font-weight: 700;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 30px; }
  @media (max-width: 767px) {
    .inclusive-text h3 {
      text-align: center; } }

.inclusive-text p {
  padding-bottom: 24px; }
  @media (max-width: 767px) {
    .inclusive-text p {
      padding-bottom: 0;
      text-align: left; } }

.blog-listing--container {
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 575px) {
    .blog-listing--container {
      max-width: 380px; } }
  @media (max-width: 767px) {
    .blog-listing--container .btn {
      margin: 40px 0 0; } }

.blog-button {
  margin-top: 120px;
  position: center; }

.productsButton {
  margin-top: 100px; }
  @media (max-width: 767px) {
    .productsButton {
      padding-bottom: 50px; } }

.home-product-card {
  width: 170px;
  height: 140px;
  margin: 0 3px; }
  .home-product-card .product-title {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: auto;
    overflow: hidden;
    height: 56px; }
  .home-product-card .product--image-container {
    flex-basis: 50%; }
    @media (max-width: 991px) {
      .home-product-card .product--image-container {
        flex-basis: 45%; } }
    .home-product-card .product--image-container .product--image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 20px; }
      @media (max-width: 600px) {
        .home-product-card .product--image-container .product--image {
          height: 140px; } }

.business-flex-slider {
  width: 80%;
  margin: 10px auto 0; }
  @media (max-width: 650px) {
    .business-flex-slider {
      width: 100%;
      margin: 20px auto 0; } }
  .business-flex-slider .flex-slider--row-favourite {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0; }
    @media (max-width: 650px) {
      .business-flex-slider .flex-slider--row-favourite {
        margin: 0 auto;
        padding: 0;
        flex-direction: column;
        align-items: center; }
        .business-flex-slider .flex-slider--row-favourite .home-business-card {
          width: 300px; } }

.home-business-card {
  margin: 0 5px;
  display: flex;
  width: 170px;
  justify-content: space-evenly; }
  .home-business-card .business-image-container, .home-business-card .partners-image-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 20px;
    line-height: 150px;
    background-color: #ffffff;
    margin: 0 auto; }
    .home-business-card .business-image-container img, .home-business-card .partners-image-container img {
      padding: 10px; }
    .home-business-card .business-image-container .business-image, .home-business-card .partners-image-container .business-image {
      width: 100%; }
  .home-business-card .partners-image-container {
    border: 2px solid #634e70; }

.become-member {
  position: relative; }
  .become-member img {
    display: block;
    height: 657px;
    margin: 0 auto;
    max-width: 938px;
    object-fit: cover;
    width: 100%; }
    @media (max-width: 767px) {
      .become-member img {
        height: 500px;
        max-width: 713px; } }
  .become-member .become-member--box {
    color: #fff;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    max-width: 500px;
    width: 100%; }
    .become-member .become-member--box h2 {
      color: #fff;
      margin-bottom: 20px;
      margin-left: 45px; }
    .become-member .become-member--box p {
      margin-bottom: 45px;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 60px; }
      @media (max-width: 767px) {
        .become-member .become-member--box p {
          margin-bottom: 20px; } }
    .become-member .become-member--box .become-member--btn {
      background-image: -webkit-gradient(linear, left top, right top, from(#35baeb), to(#6b37e0));
      background-image: linear-gradient(to right, #35baeb 0%, #6b37e0 100%);
      font-size: 16px;
      height: 50px;
      width: 200px; }

.flex-slider {
  overflow: visible;
  margin-left: 32px;
  margin-right: 32px;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .flex-slider::-webkit-scrollbar {
    height: 4px; }
  .flex-slider::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 30px; }
  .flex-slider::-webkit-scrollbar-thumb {
    background: rgba(99, 78, 112, 0.8);
    border-radius: 30px; }
  .flex-slider:hover {
    /* width */ }
    .flex-slider:hover::-webkit-scrollbar {
      height: 8px; }
    .flex-slider:hover::-webkit-scrollbar-thumb {
      background: #999;
      cursor: pointer; }
  @media (min-width: 576px) {
    .flex-slider {
      max-width: 540px;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 768px) {
    .flex-slider {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .flex-slider {
      max-width: 930px; } }
  .flex-slider .flex-slider--row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 930px;
    padding-bottom: 10px;
    justify-content: space-evenly; }
    .flex-slider .flex-slider--row .flex-slider--item {
      margin: 0 10px;
      width: 30%; }
      .flex-slider .flex-slider--row .flex-slider--item .iframe {
        width: 120px;
        height: 140px; }
        @media (max-width: 540px) {
          .flex-slider .flex-slider--row .flex-slider--item .iframe {
            display: none; } }
      .flex-slider .flex-slider--row .flex-slider--item:first-of-type {
        margin-left: 0; }
      .flex-slider .flex-slider--row .flex-slider--item:last-of-type {
        margin-right: 0; }
  .flex-slider .flex-slider--row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 930px;
    padding-bottom: 10px;
    justify-content: space-evenly; }
    .flex-slider .flex-slider--row .flex-slider--item {
      flex-wrap: nowrap;
      margin: 0 10px;
      width: 30%; }
      .flex-slider .flex-slider--row .flex-slider--item .iframe {
        width: 120px;
        height: 140px; }
        @media (max-width: 540px) {
          .flex-slider .flex-slider--row .flex-slider--item .iframe {
            display: none; } }
      .flex-slider .flex-slider--row .flex-slider--item:first-of-type {
        margin-left: 0; }
      .flex-slider .flex-slider--row .flex-slider--item:last-of-type {
        margin-right: 0; }
  .flex-slider .flex-slider--row-favourite {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 930px;
    padding: 0;
    min-height: 150px;
    justify-content: space-evenly; }
    @media (max-width: 540px) {
      .flex-slider .flex-slider--row-favourite {
        flex-wrap: nowrap; } }
    .flex-slider .flex-slider--row-favourite .flex-slider--item {
      width: 100%;
      max-width: 120px;
      margin: 0 10px;
      width: 30%; }
      .flex-slider .flex-slider--row-favourite .flex-slider--item .iframe {
        width: 120px;
        height: 140px; }
      @media (max-width: 540px) {
        .flex-slider .flex-slider--row-favourite .flex-slider--item {
          width: 31%; } }
      .flex-slider .flex-slider--row-favourite .flex-slider--item:first-of-type {
        margin-left: 0; }
      .flex-slider .flex-slider--row-favourite .flex-slider--item:last-of-type {
        margin-right: 0; }

.toggle-button {
  display: none; }

.disclaimer-text-affil-prod {
  width: 80%;
  max-width: 500px;
  margin: -25px auto 25px;
  font-size: 11px;
  color: lightgray; }

.carousel-indicators li {
  cursor: pointer;
  background-color: darkgray;
  -webkit-filter: invert(100%);
          filter: invert(100%); }

.carousel-indicators .active {
  background-color: red; }

.carousel-control-next,
.carousel-control-prev {
  -webkit-filter: invert(100%);
          filter: invert(100%); }

.col-md-3 .footer-logo {
  margin-top: 200px; }
  @media (max-width: 767px) {
    .col-md-3 .footer-logo {
      margin-top: 0; } }

.col-md-3 .footer-menu {
  margin-top: 200px; }
  @media (max-width: 767px) {
    .col-md-3 .footer-menu {
      margin-top: 0; } }

.social-media-title {
  text-align: center;
  margin-bottom: 25px;
  font-size: 1.25rem; }

.social-icons {
  margin-top: 205px; }

@media (max-width: 769px) {
  .flex-slider .flex-slider--row .flex-slider--item {
    width: 70%;
    margin-bottom: 40px; } }

@media (max-width: 540px) {
  .flex-slider .flex-slider--row .flex-slider--item {
    width: 90%;
    margin-bottom: 20px; } }

@media (max-width: 769px) {
  #homepage .travel-text {
    color: #634e70; } }

@media (max-width: 450px) {
  #homepage h1,
  #homepage h2 {
    font-size: 23px !important;
    text-align: center; }
  #homepage .hp-section-text {
    font-size: 18px;
    line-height: 22px;
    text-align: center; }
  #homepage .travel-text {
    font-size: 18px;
    text-align: center; }
  #homepage .home-sp-text {
    color: #fff !important; }
  #homepage .row.blog-listing {
    display: flex;
    justify-content: center; }
  #homepage .col-md-6.blog-listing--right {
    align-items: center;
    text-align: justify; }
  #homepage .fb-read-button {
    width: 80%; } }

html {
  scroll-behavior: smooth; }

.landingList {
  display: flex;
  align-items: center;
  justify-content: center; }
  .landingList ul {
    list-style-type: circle;
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 40px;
    -webkit-column-gap: 40px;
            column-gap: 40px; }

.landingPageContainer {
  margin-top: 50px; }
  .landingPageContainer h3 {
    color: #634e70;
    font-weight: 600; }

.containerLanding {
  display: flex;
  justify-content: space-between; }
  .containerLanding h2 {
    font-size: 40px; }
  .containerLanding .rowItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%; }
    .containerLanding .rowItem .rowItemTop {
      margin-bottom: 25px; }
      .containerLanding .rowItem .rowItemTop img {
        width: 100%; }

.header {
  padding-bottom: 20px; }

.aboutUsHeading {
  margin: 75px 0;
  color: #ffff;
  font-weight: 600; }
  @media (max-width: 991px) {
    .aboutUsHeading {
      color: #634e70;
      font-size: 38px; } }
  @media (max-width: 767px) {
    .aboutUsHeading {
      font-size: 25px; } }

.our-story h1 {
  font-weight: 600; }

.our-story .about-img {
  margin-top: 35px; }

.our-story h2 {
  margin-top: 48px;
  margin-top: 28px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 30px;
  font-weight: 400;
  font-size: 30px; }

@media (max-width: 991px) {
  .our-story {
    border-radius: 30px;
    max-width: 100%;
    overflow: hidden;
    margin: 18px 0 50px 0; } }
  @media (max-width: 991px) and (max-width: 991px) {
    .our-story {
      display: block;
      margin: 0 auto 30px;
      margin: 0 auto; } }

.our-story .schedule-call-h2 {
  margin-bottom: 16px;
  margin-top: 0;
  padding: 0; }

.our-story .schedule-call {
  margin-top: 32px;
  margin-bottom: 120px; }

.our-story .search-radio-about {
  margin-top: 50px;
  margin-bottom: 100px; }

.side-drawer {
    width: 100%;
}

.side-drawer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sidedrawer-nav-item {
    display: flex;
    justify-content: space-around;
    color: #457bd5;
    padding: 15px;
    cursor: pointer;
}

@media (min-width: 744px) {
    .side-drawer {
        display: none;
    }

}
.aboutUsHeading {
  margin: 55px 0;
  padding-bottom: 20px;
  padding-top: 20px;
  color: white; }
  .aboutUsHeading h1 {
    margin-bottom: 135px;
    font-weight: 600; }
  .aboutUsHeading img {
    display: block; }

.image img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.blog-listing-container {
  padding-top: 100px; }
  .blog-listing-container h2 {
    text-align: center; }

.our-story-therapy .text-section-therapy img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  min-width: 50px;
  margin-bottom: 50px; }

.our-story-therapy h2 {
  margin-top: 48px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 30px; }

.our-story-therapy .article-details-container span {
  margin-right: 5px; }

@media (max-width: 991px) {
  .our-story-therapy {
    flex-direction: column-reverse; } }

.our-story-therapy p {
  color: #634e70;
  font-size: 18px;
  line-height: 1.5; }

.our-story-therapy .about-img {
  float: right;
  width: 60%;
  margin-left: 50px;
  margin-bottom: 20px; }

@media (max-width: 550px) {
  .aboutUsHeading {
    color: white; }
  .our-story img.about-img {
    float: none;
    margin: 30px auto;
    width: 70%; }
  .our-story p {
    width: 90%;
    margin: 25px auto;
    font-size: 15px;
    color: #999;
    text-align: left; } }

@media (max-width: 400px) {
  .aboutUsHeading {
    color: white; }
  .our-story img.about-img {
    float: none;
    margin: 30px auto;
    width: 90%; }
  .our-story p {
    width: 90%;
    margin: 25px auto;
    text-align: left;
    font-size: 15px;
    color: #999;
    line-height: 1.5; } }

.article-card {
  background-color: #fff;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  /* height: 100%; */
  /* overflow: hidden; */
  flex-wrap: wrap;
  cursor: pointer; }
  @media (max-width: 575px) {
    .article-card {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; } }
  .article-card .article--image {
    width: 100%;
    height: 200px;
    border-radius: 30px 30px 0 0; }
  .article-card .article--tag {
    padding: 20px 35px 0;
    font-size: 16px; }
  .article-card .article-title {
    text-align: center;
    margin: 20px 5px;
    font-size: 20px;
    font-weight: bold;
    color: #634e70;
    height: 90px;
    overflow: hidden; }
  .article-card .article--short-content {
    color: #634e70;
    padding: 0 35px;
    margin-bottom: auto;
    height: 100px;
    overflow: hidden;
    font-size: 15px;
    text-align: center; }
  .article-card .article--button-container {
    margin-top: 30px;
    padding: 0 35px;
    padding: 0 35px 35px; }
    .article-card .article--button-container .article--button {
      box-shadow: none;
      line-height: 48px; }
  .article-card .article--content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 297px; }
  .article-card.article--first {
    border: 0 none;
    display: flex;
    flex-direction: row;
    flex-flow: row-reverse;
    height: 375px; }
    @media (max-width: 575px) {
      .article-card.article--first {
        flex-direction: column; } }
    .article-card.article--first .article--image-container {
      flex-basis: 50%; }
      @media (max-width: 991px) {
        .article-card.article--first .article--image-container {
          flex-basis: 45%; } }
      .article-card.article--first .article--image-container .article--image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0 30px 30px 0; }
        @media (max-width: 600px) {
          .article-card.article--first .article--image-container .article--image {
            border-radius: 30px 30px 0 0;
            height: 200px; } }
    .article-card.article--first .article--content {
      padding: 20px 25px;
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      max-width: 500px; }
      @media (max-width: 991px) {
        .article-card.article--first .article--content {
          flex-basis: 55%;
          padding: 20px 0px; } }
      .article-card.article--first .article--content .article--tag {
        font-size: 20px;
        margin-top: auto;
        padding-top: 0; }
      .article-card.article--first .article--content .article--title {
        font-size: 25px;
        padding: 10px 35px 20px;
        height: 60px; }
      .article-card.article--first .article--content .article--short-content {
        line-height: 25px; }
      .article-card.article--first .article--content .article--button-container {
        margin-bottom: auto;
        max-width: 290px;
        padding-bottom: 0; }
  .article-card .art-small-thb {
    height: 200px;
    overflow: hidden; }

.aboutUsHeading {
  margin: 55px 0;
  padding-bottom: 20px;
  padding-top: 20px;
  color: white; }
  @media (max-width: 767px) {
    .aboutUsHeading {
      padding-top: 0px; } }
  .aboutUsHeading h1 {
    margin-bottom: 135px;
    font-weight: 600; }
  .aboutUsHeading img {
    display: block; }

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer; }

.image-style-side-right {
  max-width: 40%;
  float: right;
  margin: 10px 0 10px 20px; }
  @media (max-width: 767px) {
    .image-style-side-right {
      max-width: 100%;
      float: none;
      margin: 10px; } }

.button-container {
  text-align: center;
  margin: 40px 0 60px 0; }

.image-style-side-left {
  max-width: 40%;
  float: left;
  margin: 10px 20px 10px 0px; }
  @media (max-width: 767px) {
    .image-style-side-left {
      max-width: 100%;
      float: none;
      margin: 10px; } }

.image-style-side-right img, .image-style-side-left img {
  margin-bottom: 0px !important; }

.image img {
  display: block;
  margin-left: auto;
  margin-right: auto; }

.blog-listing-container {
  padding-top: 100px; }
  .blog-listing-container h2 {
    text-align: center; }

.our-story-therapy .text-section-therapy {
  margin-top: 100px; }
  .our-story-therapy .text-section-therapy img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    min-width: 50px;
    margin-bottom: 50px; }

.our-story-therapy h2 {
  margin-top: 48px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 30px; }

.our-story-therapy .article-details-container span {
  margin-right: 5px; }

@media (max-width: 991px) {
  .our-story-therapy {
    flex-direction: column-reverse; } }

.our-story-therapy p {
  color: #634e70;
  font-size: 18px;
  line-height: 1.5; }

.our-story-therapy .about-img {
  float: right;
  width: 60%;
  margin-left: 50px;
  margin-bottom: 20px; }

@media (max-width: 550px) {
  .aboutUsHeading {
    color: white; }
  .articles-list {
    padding-left: 0; }
  .our-story img.about-img {
    float: none;
    margin: 30px auto;
    width: 70%; }
  .our-story p {
    width: 90%;
    margin: 25px auto;
    font-size: 15px;
    color: #999;
    text-align: left; } }

@media (max-width: 400px) {
  .aboutUsHeading {
    color: white; }
  .our-story img.about-img {
    float: none;
    margin: 30px auto;
    width: 90%; }
  .our-story p {
    width: 90%;
    margin: 25px auto;
    text-align: left;
    font-size: 15px;
    color: #999;
    line-height: 1.5; } }

#contact-us-page .star {
  color: red; }

#contact-us-page ::-webkit-input-placeholder {
  color: grey;
  opacity: 1; }

#contact-us-page :-ms-input-placeholder {
  color: grey;
  opacity: 1; }

#contact-us-page ::-ms-input-placeholder {
  color: grey;
  opacity: 1; }

#contact-us-page ::placeholder {
  color: grey;
  opacity: 1; }

#contact-us-page .header-contact {
  text-align: center;
  color: white;
  font-weight: 600;
  margin-bottom: 150px; }

.form input {
  font-weight: bold; }

.form textarea {
  font-weight: bold; }

@media (max-width: 780px) {
  #contact-us-page .header-contact {
    margin-top: -60px;
    color: white;
    text-align: center; } }

@media (max-width: 480px) {
  #contact-us-page .form {
    padding-top: 110px;
    padding-left: 15px; }
  #contact-us-page .header-contact {
    padding-top: 100px;
    color: white;
    text-align: center;
    margin-bottom: 80px;
    margin-top: 0px;
    padding-top: 0px; }
  #contact-us-page .contact-submit-row {
    display: flex;
    justify-content: space-around; } }

.travel:hover a {
  color: #634e70; }

.travel:hover .travel--image {
  box-shadow: 0 10px 48px rgba(0, 0, 0, 0.7); }

.travel.travel-card {
  background-color: #fff;
  border: 1px solid #979797;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden; }
  .travel.travel-card:hover {
    border-color: #fff;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3); }
    .travel.travel-card:hover .travel--image {
      box-shadow: none; }
  @media (max-width: 575px) {
    .travel.travel-card {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto; } }
  .travel.travel-card .travel--tag {
    padding: 0 35px;
    font-size: 16px; }
  .travel.travel-card .travel--title {
    color: #634e70;
    display: block;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 auto;
    padding: 0 35px; }
  .travel.travel-card .travel--button-container {
    margin-top: 30px;
    padding: 0 35px;
    padding: 0 35px 35px; }
    .travel.travel-card .travel--button-container .travel--button {
      line-height: 48px; }

.travel .travel--title {
  color: #126ef1;
  font-weight: 700; }

.travel .travel--image {
  margin-top: 20px;
  border-radius: 30px;
  margin-bottom: 20px;
  overflow: hidden; }

#travelPage {
  margin-top: 120px; }
  #travelPage .travel-image {
    margin: 0 auto;
    max-width: 100%;
    width: auto; }

#blogMainPage {
  margin-top: 40px; }
  #blogMainPage .blog-text-ontop {
    padding-right: 40px;
    padding-top: 10px; }
  #blogMainPage .page-title {
    text-align: center;
    font-size: 43px;
    margin-bottom: 130px;
    color: #ffff; }
  #blogMainPage input {
    outline: none; }
  #blogMainPage .article-search--container .article-search--input-background {
    background-image: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a724ec));
    background-image: linear-gradient(to right, #106ff1 0%, #a724ec 100%);
    border-radius: 30px;
    padding: 2px;
    position: relative; }
    #blogMainPage .article-search--container .article-search--input-background input {
      border: 0 none;
      border-radius: 30px;
      height: 54px;
      padding-left: 30px;
      padding-right: 70px;
      width: 100%; }
      @media (max-width: 575px) {
        #blogMainPage .article-search--container .article-search--input-background input {
          padding-left: 15px;
          padding-right: 15px; } }
      #blogMainPage .article-search--container .article-search--input-background input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #457bd5; }
      #blogMainPage .article-search--container .article-search--input-background input::-moz-placeholder {
        /* Firefox 19+ */
        color: #457bd5; }
      #blogMainPage .article-search--container .article-search--input-background input:-ms-input-placeholder {
        /* IE 10+ */
        color: #457bd5; }
      #blogMainPage .article-search--container .article-search--input-background input:-moz-placeholder {
        /* Firefox 18- */
        color: #457bd5; }
    #blogMainPage .article-search--container .article-search--input-background .search-icon {
      cursor: pointer;
      position: absolute;
      right: 30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
      @media (max-width: 575px) {
        #blogMainPage .article-search--container .article-search--input-background .search-icon {
          display: none; } }
  #blogMainPage .filter-icon {
    cursor: pointer;
    margin: auto; }
    @media (max-width: 575px) {
      #blogMainPage .filter-icon {
        margin: auto 0 auto auto; } }
  #blogMainPage .current-page {
    color: black;
    cursor: default;
    background-color: white; }
  #blogMainPage .load-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px auto; }
  #blogMainPage .invisible-btn {
    display: none; }
  #blogMainPage .pagination {
    justify-content: center; }
  #blogMainPage .page-link {
    border: 0; }
  #blogMainPage .page-item {
    cursor: pointer; }

@media (max-width: 450px) {
  #blogMainPage .article-card {
    max-width: 300px; }
  #blogMainPage .articles-list {
    padding-left: 0; } }

@media (max-width: 750px) {
  #blogMainPage .blog-text-ontop {
    padding: 0; }
  #blogMainPage .page-title {
    text-align: center;
    padding-top: 5px;
    color: white;
    margin-bottom: 55px; }
  #blogMainPage .article-card {
    height: auto; }
  #blogMainPage .article-title {
    font-size: 20px; }
  #blogMainPage .article-content {
    flex-basis: 55%;
    padding: 20px 0px;
    flex-direction: column;
    align-items: center; }
  #blogMainPage .article--button-container {
    max-width: 100%;
    width: 100%; }
  #blogMainPage .art-small-thb {
    width: 100%; }
  #blogMainPage .article--content {
    width: 100%; }
  #blogMainPage .article--short-content {
    padding: 0;
    margin: 0 30px; } }

.header {
  padding-bottom: 20px; }

.legalHeading {
  margin: 130px 0;
  color: #ffff;
  font-weight: 600; }
  @media (max-width: 991px) {
    .legalHeading {
      color: #634e70;
      font-size: 38px; } }
  @media (max-width: 767px) {
    .legalHeading {
      font-size: 30px; } }

#root > div > section > div.\"search-radio--row.search-radio-about\" {
  margin-top: 50px; }

.legal h1 {
  font-weight: 600; }

.legal .about-img {
  margin-top: 35px; }

.legal h2 {
  margin-top: 48px;
  margin-top: 28px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  font-weight: 400;
  font-size: 30px;
  font-weight: 400;
  font-size: 30px; }

@media (max-width: 991px) {
  .legal {
    border-radius: 30px;
    max-width: 100%;
    overflow: hidden;
    margin: 18px 0 50px 0; } }
  @media (max-width: 991px) and (max-width: 991px) {
    .legal {
      display: block;
      margin: 0 auto 30px;
      margin: 0 auto; } }

#reset-password .page-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  overflow: hidden; }

#reset-password .top-left-shape {
  width: 650px;
  position: absolute;
  top: -100px;
  left: -60px; }

#reset-password .top-right-ellipse {
  width: 350px;
  position: absolute;
  top: -70px;
  right: -130px; }

#reset-password .bottom-right-shape {
  width: 500px;
  position: absolute;
  bottom: -155px;
  right: -60px; }

#reset-password .bottom-right-ellipse {
  position: absolute;
  bottom: 75px;
  right: 35px;
  width: 145px; }

#reset-password .page-content {
  width: 50%;
  margin: 60px auto 0;
  max-width: 450px; }

#reset-password .logo {
  width: 155px;
  display: block;
  margin: 0 auto; }

#reset-password .page-title {
  margin-top: 40px; }

#reset-password .input-row {
  margin-bottom: 15px; }

#reset-password .form-container {
  margin-top: 35px; }

#reset-password .reset-row {
  margin-top: 65px; }

#reset-password .reset-success {
  margin: 200px auto;
  text-align: center; }

@media (max-width: 750px) {
  #reset-password .page-content {
    width: 75%; }
  #reset-password .input-row input {
    width: 80%; } }

@media (max-width: 615px) {
  #reset-password .top-left-shape {
    left: -135px; }
  #reset-password .top-right-ellipse {
    right: -200px; } }

#reset-password .page-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  overflow: hidden; }

#reset-password .top-left-shape {
  width: 650px;
  position: absolute;
  top: -100px;
  left: -60px; }

#reset-password .top-right-ellipse {
  width: 350px;
  position: absolute;
  top: -70px;
  right: -130px; }

#reset-password .bottom-right-shape {
  width: 500px;
  position: absolute;
  bottom: -155px;
  right: -60px; }

#reset-password .bottom-right-ellipse {
  position: absolute;
  bottom: 75px;
  right: 35px;
  width: 145px; }

#reset-password .page-content {
  width: 50%;
  margin: 60px auto 0;
  max-width: 450px; }

#reset-password .logo {
  width: 155px;
  display: block;
  margin: 0 auto; }

#reset-password .page-title {
  margin-top: 40px; }

#reset-password .input-row {
  margin-bottom: 15px; }

#reset-password .form-container {
  margin-top: 35px; }

#reset-password .reset-row {
  margin-top: 65px; }

#reset-password .input-error {
  border-bottom: 1px solid red; }

@media (max-width: 750px) {
  #reset-password .page-content {
    width: 75%; }
  #reset-password .input-row input {
    width: 80%; } }

@media (max-width: 615px) {
  #reset-password .top-left-shape {
    left: -135px; }
  #reset-password .top-right-ellipse {
    right: -200px; } }

#manage-therapist-reviews {
  background: #f1f2f361;
  position: relative; }
  #manage-therapist-reviews .page-background {
    width: 100%;
    min-height: 100vh; }
  #manage-therapist-reviews h1 {
    margin: 0; }
  #manage-therapist-reviews .page-content {
    display: flex; }
  #manage-therapist-reviews .inner-content-container {
    flex: 1 1; }
  #manage-therapist-reviews .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #manage-therapist-reviews .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd; }
  #manage-therapist-reviews .small-gradient-button {
    width: 90%;
    height: 50px; }
  #manage-therapist-reviews .small-gradient-button span {
    margin: 0; }
  #manage-therapist-reviews .sp-search-input {
    width: 90%; }
  #manage-therapist-reviews .results-section {
    margin-top: 30px; }
  #manage-therapist-reviews .message-span-container {
    padding-left: 30px; }
  #manage-therapist-reviews .filters {
    display: flex;
    margin-top: 20px;
    justify-content: center; }
  #manage-therapist-reviews .filters .filter-item {
    width: 150px;
    text-align: center;
    color: #457bd569;
    cursor: pointer; }
  #manage-therapist-reviews .filters .filter-item.active-filter {
    color: #457bd5;
    font-weight: bold; }
  #manage-therapist-reviews .filters .middle-filter {
    border-left: 3px solid #457bd5a8;
    border-right: 3px solid #457bd5a8; }
  #manage-therapist-reviews .rev-card-manage {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 30px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #manage-therapist-reviews .rev-card-actions {
    width: 75px; }
  #manage-therapist-reviews .rev-actions-container {
    display: flex;
    justify-content: space-around; }
  #manage-therapist-reviews .accept-icon {
    cursor: pointer;
    color: #28a745; }
  #manage-therapist-reviews .decline-icon {
    cursor: pointer;
    color: #dc3545; }
  #manage-therapist-reviews .rev-card-title {
    display: flex;
    margin-bottom: 15px; }
  #manage-therapist-reviews .rev-card-value {
    font-weight: bold;
    color: #634e70; }
  #manage-therapist-reviews .rev-card-rating {
    display: flex;
    margin-bottom: 20px; }
  #manage-therapist-reviews .inactive-tab {
    color: #ddd; }
  #manage-therapist-reviews .tab-item {
    cursor: pointer; }
  #manage-therapist-reviews .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #manage-therapist-reviews .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #manage-therapist-reviews .top-row {
    display: flex;
    justify-content: space-evenly; }
  #manage-therapist-reviews .rating-label {
    margin-right: 15px; }
  #manage-therapist-reviews .rev-card-review {
    display: flex; }
  #manage-therapist-reviews .review-label {
    margin-right: 15px; }
  #manage-therapist-reviews .review-content {
    font-size: 15px;
    line-height: 30px; }

@media (max-width: 580px) {
  #manage-therapist-reviews .inner-content-container {
    width: 100%; }
  #manage-therapist-reviews .inner-content {
    width: 100%; }
  #manage-therapist-reviews .page-title {
    text-align: center; }
  #manage-therapist-reviews .rev-card-manage {
    flex-direction: column;
    align-items: center; }
  #manage-therapist-reviews .rev-card-actions {
    margin-top: 30px; }
  #manage-therapist-reviews .rev-card-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  #manage-therapist-reviews .to-container {
    margin-left: -10px; }
  #manage-therapist-reviews .rev-card-review {
    flex-direction: column;
    align-items: center; }
  #manage-therapist-reviews .review-content {
    text-align: center; }
  #manage-therapist-reviews .no-review-text {
    text-align: center; } }

#therapist-manage-locations {
  background: #f1f2f361;
  position: relative; }
  #therapist-manage-locations .page-background {
    width: 100%;
    min-height: 100vh; }
  #therapist-manage-locations h1 {
    margin: 0; }
  #therapist-manage-locations .page-content {
    display: flex; }
  #therapist-manage-locations .inner-content-container {
    flex: 1 1; }
  #therapist-manage-locations .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px; }
  #therapist-manage-locations .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px; }
  #therapist-manage-locations .add-row {
    display: flex;
    margin-top: 25px;
    padding-bottom: 35px;
    border-bottom: 1px solid #ddd; }
  #therapist-manage-locations .add-row .leftside-content {
    display: flex;
    flex-direction: column;
    height: 150px;
    justify-content: space-between; }
  #therapist-manage-locations .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #therapist-manage-locations .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #therapist-manage-locations .small-gradient-button {
    width: 90%;
    height: 50px; }
  #therapist-manage-locations .small-gradient-button span {
    margin: 0; }
  #therapist-manage-locations .ins-search-input {
    width: 100%; }
  #therapist-manage-locations .results-section {
    margin-top: 30px; }
  #therapist-manage-locations .loc-subtitle {
    color: #634e70; }
  #therapist-manage-locations .add-section {
    margin-top: 50px; }
  #therapist-manage-locations .google-places-autocomplete__suggestion {
    cursor: pointer; }
  #therapist-manage-locations .google-places-autocomplete__suggestion:hover {
    cursor: pointer;
    background: #457bd5;
    color: #fff;
    font-weight: bold; }
  #therapist-manage-locations .location-input-outer {
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    border-radius: 25px;
    padding: 2px;
    margin-top: 15px; }
  #therapist-manage-locations .google-places-autocomplete .google-places-autocomplete__input {
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 25px;
    text-indent: 10px; }
  #therapist-manage-locations .loc-at-item {
    display: flex;
    border-radius: 25px;
    align-items: center;
    margin: 5px 10px;
    justify-content: space-around;
    padding: 2px; }
  #therapist-manage-locations .loc-at-outer {
    display: flex;
    background: #fff;
    padding: 5px 15px;
    border-radius: 25px;
    align-items: center;
    height: 45px; }
  #therapist-manage-locations .loc-at-outer .loc-at-info {
    height: 45px;
    display: flex;
    align-items: center;
    padding-right: 10px; }
  #therapist-manage-locations .loc-at-outer .loc-at-info h4 {
    margin: 0; }
  #therapist-manage-locations .loc-at-actions {
    height: 45px;
    display: flex;
    align-items: center; }
  #therapist-manage-locations .attached-location-list {
    display: flex;
    flex-wrap: wrap; }
  #therapist-manage-locations .loc-at-name {
    font-size: 12px; }
  #therapist-manage-locations .attached-locations-section {
    margin-top: 25px; }
  #therapist-manage-locations .no-access-desc {
    padding: 15px;
    font-size: 18px; }
  #therapist-manage-locations .bold-val {
    color: #634e70;
    font-weight: bold; }

@media (max-width: 580px) {
  #therapist-manage-locations .inner-content {
    width: 95%; }
  #therapist-manage-locations .page-title {
    text-align: center; }
  #therapist-manage-locations .loc-subtitle {
    text-align: center; }
  #therapist-manage-locations .loc-at-item {
    width: 100%; }
  #therapist-manage-locations .loc-at-outer {
    width: 100%; }
  #therapist-manage-locations .loc-at-info {
    flex: 1 1;
    display: flex;
    justify-content: space-around; } }

#payment .page-background .page-content .inner-content-container {
  width: 80%;
  margin: 0 auto;
  max-width: 810px;
  display: flex; }
  #payment .page-background .page-content .inner-content-container .inner-content {
    margin-top: 50px; }
    #payment .page-background .page-content .inner-content-container .inner-content .top-row .page-title {
      margin-bottom: 30px; }
    #payment .page-background .page-content .inner-content-container .inner-content .main-content .stripe-payment-method {
      margin-bottom: 90px; }
    #payment .page-background .page-content .inner-content-container .inner-content .main-content h1 {
      text-align: center;
      font-weight: 600; }
    #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans {
      display: flex;
      align-content: space-between;
      margin-top: 30px;
      margin-bottom: 100px; }
      #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans .plan {
        position: relative;
        width: 270px;
        border-radius: 30px;
        border: 1px solid #979797;
        margin-top: 50px;
        padding-top: 40px;
        text-align: center;
        height: 480px;
        margin-right: 5px; }
        #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans .plan h3 {
          font-size: 25px;
          font-weight: 600; }
        #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans .plan p {
          font-size: 16px;
          font-weight: 400; }
        #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans .plan h1 {
          font-size: 45px;
          font-weight: 700; }
        #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans .plan .plan-list {
          margin: 20px auto;
          color: #634e70;
          font-size: 16px;
          font-weight: 400;
          height: 120px; }
        #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans .plan li {
          margin: 8px; }
        #payment .page-background .page-content .inner-content-container .inner-content .main-content .plans .plan .plan-button {
          margin: 0 auto;
          width: 150px; }

#payment .plans-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }

#payment .order1 {
  order: 1; }

#payment .order2 {
  order: 2; }

#payment .order3 {
  order: 3; }

#payment .order4 {
  order: 4; }

@media (max-width: 580px) {
  #payment .inner-content-container {
    width: 95% !important;
    overflow: hidden; }
  #payment .inner-content {
    width: 100%; } }

.checkout .card-number {
  width: 300px; }

.checkout .expiry {
  width: 110px; }

.checkout .cvc {
  width: 90px; }

.checkout .input-outer {
  background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
  background: linear-gradient(to right, #106ff1, #a723ec);
  border-radius: 30px;
  padding: 2px;
  margin-top: 15px; }
  .checkout .input-outer .input-inner {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 10px 0;
    border-radius: 30px;
    padding-left: 25px;
    overflow: hidden; }

.checkout .send-button {
  margin-top: 30px;
  width: 150px; }

.checkout .error-msg-container {
  font-size: 15px;
  color: #dc3545; }

.checkout .card-completed-container {
  display: flex; }
  .checkout .card-completed-container .saved-card-border {
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    border-radius: 30px;
    padding: 2px;
    width: 400px;
    margin-right: 40px; }
    .checkout .card-completed-container .saved-card-border .saved-card-inner {
      width: 100%;
      height: 100%;
      background: #fff;
      padding: 10px 0;
      border-radius: 30px;
      padding-left: 25px;
      overflow: hidden;
      display: flex;
      align-items: center; }
      .checkout .card-completed-container .saved-card-border .saved-card-inner .card-type-value {
        margin-right: 30px; }
  .checkout .card-completed-container .cc-buttons {
    display: flex; }
    .checkout .card-completed-container .cc-buttons .btn {
      margin-right: 20px;
      width: 180px; }

@media (max-width: 580px) {
  .checkout .card-completed-container {
    flex-direction: column;
    align-items: center; }
  .checkout .saved-card-border {
    width: 100% !important;
    margin: 0 !important; }
  .checkout .cc-buttons {
    flex-direction: column;
    align-items: center;
    width: 100%; }
  .checkout .cc-buttons button {
    margin-right: 0 !important;
    margin: 15px auto !important;
    width: 85% !important; }
  .checkout .checkout-form {
    display: flex;
    flex-direction: column; } }

.plan-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 30px;
  padding: 30px 25px;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  width: 320px;
  padding-bottom: 45px;
  margin-top: 20px;
  position: relative;
  overflow: hidden; }
  .plan-card .p-name-container {
    display: flex;
    justify-content: space-around;
    font-size: 25px;
    font-weight: bold;
    color: #634e70; }
  .plan-card .p-price-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px; }
  .plan-card .badge.color {
    background: #d68843; }
  .plan-card .badge.diagonal {
    white-space: nowrap;
    position: absolute;
    padding: 15px 68px;
    min-width: 200px;
    -webkit-transform: rotate(-45deg) translate(-58%, 0);
            transform: rotate(-45deg) translate(-58%, 0);
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    top: -80px;
    box-sizing: border-box; }
  .plan-card .p-price {
    font-size: 45px;
    font-weight: bold;
    color: #634e70; }
  .plan-card .p-price-period {
    font-size: 17px;
    color: #ddd; }
  .plan-card .p-benefits-container {
    font-size: 15px;
    height: 220px; }
  .plan-card .p-benefit-item {
    margin-top: 10px;
    text-align: center; }
  .plan-card .choose-plan-container {
    display: flex;
    justify-content: space-around;
    margin-top: 65px; }
  .plan-card .current-plan-marker {
    font-weight: bold;
    border: 2px solid #ccc;
    padding: 11px 25px;
    border-radius: 30px; }
  .plan-card .p-name-container .p-name {
    text-align: center; }
  @media (max-width: 580px) {
    .plan-card {
      padding: 20px 25px;
      width: 310px; } }
  @media (max-width: 350px) {
    .plan-card {
      padding: 20px 25px;
      width: 290px; } }

.numeric-input-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 40px 0;
  flex-wrap: wrap; }
  .numeric-input-container .numeric-input {
    display: flex;
    align-items: center;
    margin: 10px 0; }
    .numeric-input-container .numeric-input .numeric-input-group {
      clear: both;
      position: relative;
      display: flex; }
      .numeric-input-container .numeric-input .numeric-input-group input,
      .numeric-input-container .numeric-input .numeric-input-group textarea {
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        margin: 0;
        outline: none;
        padding: 10px; }
      .numeric-input-container .numeric-input .numeric-input-group input[type="button"] {
        -webkit-appearance: button;
        cursor: pointer; }
      .numeric-input-container .numeric-input .numeric-input-group input::-webkit-outer-spin-button,
      .numeric-input-container .numeric-input .numeric-input-group input::-webkit-inner-spin-button {
        -webkit-appearance: none; }
      .numeric-input-container .numeric-input .numeric-input-group input[type="number"] {
        -moz-appearance: textfield;
        -webkit-appearance: none; }
    .numeric-input-container .numeric-input .numeric-input-group input[type="button"] {
      background-color: #eeeeee;
      min-width: 38px;
      width: auto;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }
    .numeric-input-container .numeric-input .numeric-input-group .button-minus,
    .numeric-input-container .numeric-input .numeric-input-group .button-plus {
      font-weight: bold;
      height: 38px;
      padding: 0;
      width: 38px;
      position: relative; }
    .numeric-input-container .numeric-input .numeric-input-group .quantity-field {
      position: relative;
      height: 38px;
      left: -6px;
      text-align: center;
      width: 62px;
      display: inline-block;
      font-size: 13px;
      margin: 0 0 5px;
      resize: vertical; }
    .numeric-input-container .numeric-input .button-plus {
      left: -13px; }
  .numeric-input-container .extra-cost {
    display: flex;
    margin: 10px 0; }
    .numeric-input-container .extra-cost .extra-cost-container {
      margin-left: 10px; }
      .numeric-input-container .extra-cost .extra-cost-container .cost-input-outer {
        width: 160px;
        height: 55px;
        background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
        background: linear-gradient(to right, #106ff1, #a723ec);
        padding: 2px;
        border-radius: 30px; }
        .numeric-input-container .extra-cost .extra-cost-container .cost-input-outer .cost-input-inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          padding-left: 20px;
          align-items: center;
          height: 100%;
          background: #fff;
          border-radius: 30px;
          overflow: hidden;
          flex-direction: column; }
          .numeric-input-container .extra-cost .extra-cost-container .cost-input-outer .cost-input-inner .cost-input-container {
            flex: 1 1;
            display: flex;
            padding: 15px 5px;
            flex-wrap: wrap; }
          .numeric-input-container .extra-cost .extra-cost-container .cost-input-outer .cost-input-inner .cost-input-container input {
            width: 120px;
            border: none; }
          .numeric-input-container .extra-cost .extra-cost-container .cost-input-outer .cost-input-inner .cost-label {
            font-size: 20px;
            font-weight: 700;
            color: #126ef1; }
    .numeric-input-container .extra-cost .extra-cost-btn {
      width: 120px;
      height: 55px;
      margin-left: 20px; }

#payment-first .back-drop {
  background-color: rgba(48, 49, 48, 0.42);
  height: 100%;
  position: fixed;
  -webkit-transition: all 1.3s;
  transition: all 1.3s;
  width: 100%; }

#payment-first .page-background .page-content .inner-content-container-first {
  width: 80%;
  margin: 0 auto;
  max-width: 1000px;
  display: flex; }
  #payment-first .page-background .page-content .inner-content-container-first .inner-content {
    width: 100%;
    margin-top: 50px; }
    #payment-first .page-background .page-content .inner-content-container-first .inner-content .top-row .page-title {
      margin-bottom: 30px; }
    #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .stripe-payment-method {
      margin-bottom: 30px; }
    #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content h1 {
      text-align: center;
      font-weight: 600; }
    #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .last-line {
      text-align: center;
      margin-bottom: 50px; }
    #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .button-link {
      background: none !important;
      border: none;
      padding: 0 !important;
      color: #069;
      text-decoration: underline;
      cursor: pointer; }
    #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans {
      width: 900px;
      margin: 30px auto 100px; }
      #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans .plan {
        position: relative;
        width: 270px;
        border-radius: 30px;
        border: 1px solid #979797;
        margin-top: 50px;
        padding-top: 40px;
        text-align: center;
        height: 480px;
        margin-right: 5px; }
        #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans .plan h3 {
          font-size: 25px;
          font-weight: 600; }
        #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans .plan p {
          font-size: 16px;
          font-weight: 400; }
        #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans .plan h1 {
          font-size: 45px;
          font-weight: 700; }
        #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans .plan .plan-list {
          margin: 20px auto;
          color: #634e70;
          font-size: 16px;
          font-weight: 400;
          height: 120px; }
        #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans .plan li {
          margin: 8px; }
        #payment-first .page-background .page-content .inner-content-container-first .inner-content .main-content .plans .plan .plan-button {
          margin: 0 auto;
          width: 150px; }

#payment-first .plans-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }

#payment-first .order1 {
  order: 1; }

#payment-first .order2 {
  order: 2; }

#payment-first .order3 {
  order: 3; }

#payment-first .order4 {
  order: 4; }

@media (max-width: 580px) {
  #payment .inner-content-container {
    width: 95% !important;
    overflow: hidden; }
  #payment .inner-content {
    width: 100%; } }


#sponsorship .page-content {
  display: flex; }

#sponsorship .inner-content-container {
  width: 80%;
  margin: 0 auto;
  max-width: 960px; }

#sponsorship .inner-content {
  margin-top: 50px; }

#sponsorship .page-title {
  margin-top: 50px; }

#sponsorship .plan-description-section {
  margin-top: 25px;
  font-size: 17px; }

#sponsorship .plans {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px; }

#sponsorship .choose-plan-container {
  display: flex;
  justify-content: space-around; }

#sponsorship .plan-budget-container {
  display: flex;
  justify-content: space-around; }

#sponsorship .stripe-payment-method {
  margin-bottom: 90px; }

@media (max-width: 580px) {
  #sponsorship .inner-content-container {
    width: 95%; }
  #sponsorship .page-title {
    text-align: center; }
  #sponsorship .plan-description-section {
    padding: 15px 25px;
    text-align: center; }
  #sponsorship .plans {
    flex-direction: column;
    align-items: center; }
  #sponsorship .sponsorship-plan-card {
    width: 100%;
    padding: 20px 15px; }
  #sponsorship .settings-form-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #sponsorship .palce-price-container {
    width: 100%;
    text-align: center; }
  #sponsorship .place-item {
    justify-content: center;
    margin: 30px 10px; }
  #sponsorship .plan-settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0; }
  #sponsorship .cancel-change-container {
    flex-direction: column;
    align-items: center;
    width: 100%; }
  #sponsorship .cancel-change-container .btn {
    width: 90% !important;
    margin-bottom: 15px; } }

.sponsorship-plan-card {
  background: #fff;
  border: 1px solid #eee;
  border-radius: 30px;
  padding: 30px 35px;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  width: 47%;
  padding-bottom: 45px;
  margin-top: 20px; }
  .sponsorship-plan-card .plan-name {
    font-size: 25px;
    font-weight: bold;
    color: #634e70;
    display: flex;
    justify-content: space-around; }
  .sponsorship-plan-card .plan-subtitle {
    font-size: 15px;
    color: #ccc;
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-top: 20px; }
  .sponsorship-plan-card .place-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    font-size: 17px;
    align-items: center;
    margin: 25px 10px; }
  .sponsorship-plan-card .checkbox-container {
    margin-right: 15px;
    margin-top: -5px; }
  .sponsorship-plan-card .place-name-container {
    margin-right: 5px; }
  .sponsorship-plan-card .price-val {
    margin-right: 5px;
    color: #634e70;
    font-weight: bold; }
  .sponsorship-plan-card .place-name {
    color: #634e70; }
  .sponsorship-plan-card .budget-input-outer {
    width: 250px;
    height: 60px;
    background: -webkit-gradient(linear, left top, right top, from(#106ff1), to(#a723ec));
    background: linear-gradient(to right, #106ff1, #a723ec);
    padding: 2px;
    border-radius: 30px; }
  .sponsorship-plan-card .budget-input-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding-left: 20px;
    align-items: center;
    height: 100%;
    background: #fff;
    border-radius: 30px;
    overflow: hidden;
    flex-direction: column; }
  .sponsorship-plan-card .budget-input-container {
    flex: 1 1;
    display: flex;
    padding: 15px 5px;
    flex-wrap: wrap; }
  .sponsorship-plan-card .budget-input-container input {
    width: 120px;
    border: none; }
  .sponsorship-plan-card .budget-label {
    font-size: 20px;
    font-weight: 700;
    color: #126ef1; }
  .sponsorship-plan-card .plan-budget-container {
    margin-top: 35px;
    margin-bottom: 70px; }
  .sponsorship-plan-card .pc-title {
    font-size: 20px;
    font-weight: bold;
    color: #634e70;
    margin: 35px 0 5px; }
  .sponsorship-plan-card .psc-row {
    display: flex;
    padding-left: 15px; }
  .sponsorship-plan-card .psc-label {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px; }
  .sponsorship-plan-card .psc-value {
    font-size: 16px;
    font-weight: bold;
    color: #634e70; }
  .sponsorship-plan-card .plan-settings-container {
    margin-bottom: 50px;
    padding-left: 10px; }
  .sponsorship-plan-card .csp-button {
    border: 2px solid #ddd;
    width: 300px;
    border-radius: 35px;
    cursor: pointer; }
  .sponsorship-plan-card .csp-btn-main {
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
    color: #ccc; }
  .sponsorship-plan-card .csp-btn-sub {
    font-size: 13px;
    text-align: center; }
  .sponsorship-plan-card .card-btn-small {
    width: 45% !important;
    min-width: 150px !important; }
  .sponsorship-plan-card .cancel-change-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly; }
  .sponsorship-plan-card .marginbottom0 {
    margin-bottom: 0 !important; }
  .sponsorship-plan-card .active-plan-marker {
    text-align: center;
    color: #dc3545;
    font-weight: bold;
    font-size: 13px; }

#article-content-container {
  width: 100%;
  margin-top: 80px; }
  #article-content-container p {
    line-height: 1.5; }
  #article-content-container h3 {
    margin-bottom: 12px; }
  @media (max-width: 575px) {
    #article-content-container .article-read-only-blg {
      padding-top: 30px; } }
  #article-content-container .article-read-only-blg .ck.ck-editor__top.ck-reset_all {
    display: none; }
  #article-content-container .article-read-only-blg p {
    line-height: 1.5; }
  #article-content-container .ck-blurred.ck.ck-content {
    border: none; }
  #article-content-container .ck-content .image > img {
    width: 100%;
    height: auto; }
  @media (max-width: 575px) {
    #article-content-container .ck-content .image-style-side {
      float: none;
      margin: 0 auto; }
    #article-content-container .ck-content .image-style-align-center, #article-content-container .ck-content .image-style-align-left, #article-content-container .ck-content .image-style-align-right, #article-content-container .ck-content .image-style-side {
      width: 100%;
      max-width: 100%;
      height: auto; }
    #article-content-container h2 {
      text-align: left !important; } }
  #article-content-container .art-con-title-container {
    text-align: center;
    margin-bottom: 5px; }
    #article-content-container .art-con-title-container h1 {
      color: #fff;
      font-weight: 600;
      margin-bottom: 100px; }
    @media (max-width: 575px) {
      #article-content-container .art-con-title-container h1 {
        color: white;
        text-align: center; } }
  #article-content-container .article-details-container {
    display: inline-block;
    font-size: 17px; }
    @media (max-width: 575px) {
      #article-content-container .article-details-container {
        padding-bottom: 13px;
        padding-left: 40px; } }
  #article-content-container .article-details-container p {
    margin-right: 5px; }
  #article-content-container .ad-value {
    font-weight: bold;
    color: #634e70;
    margin-bottom: -4px; }

.art-tags-section {
  max-width: 960px;
  width: 80%;
  margin: 0 auto; }
  .art-tags-section .art-tags-sec-title {
    color: #634e70;
    font-size: 30px;
    font-weight: bold;
    margin: 0 0 30px; }
  .art-tags-section .article-tags-container {
    display: flex;
    flex-wrap: wrap; }
  .art-tags-section .art-tag-display {
    padding: 5px 25px;
    background: #f7d7eb;
    border-radius: 20px;
    color: #634e70;
    margin: 0 10px;
    margin-top: 10px; }

.share-section {
  max-width: 960px;
  width: 80%;
  margin: 0 auto; }
  .share-section a:hover {
    color: white; }
  .share-section .share-sec-title {
    color: #634e70;
    font-size: 30px;
    font-weight: bold;
    margin: 0 0 30px; }
  .share-section .share-container {
    display: flex; }
  .share-section .share-display {
    padding: 5px 25px;
    background: #f7d7eb;
    border-radius: 20px;
    color: #634e70;
    margin: 0 10px; }
  .share-section .mix-button {
    display: inline-block;
    color: #fff;
    background-color: purple;
    border-radius: 5px;
    line-height: 1.2em;
    margin: 0.5em;
    -webkit-transition: 50ms ease-out;
    transition: 50ms ease-out;
    padding: 0.5em 0.75em;
    border: 1px solid #eee;
    outline: none; }
  .share-section .mix-button:hover {
    background-color: darkviolet;
    color: white; }
  .share-section .mix-image {
    width: 20px; }

#similar-articles-section {
  max-width: 960px;
  width: 80%;
  margin: 40px auto; }
  #similar-articles-section .s-art-title {
    color: #634e70;
    font-size: 30px;
    font-weight: bold;
    margin: 0 0 30px; }
  #similar-articles-section .sim-article-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  #similar-articles-section .sim-art-card-container {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px; }
  #similar-articles-section .sim-art-thumbnail-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 30px;
    margin-right: 15px; }
  #similar-articles-section .sim-art-thumbnail-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  #similar-articles-section .sim-art-card {
    width: 100%; }
  #similar-articles-section .sa-data-row {
    display: flex; }
  #similar-articles-section .sim-art-details-container {
    flex: 1 1; }
  #similar-articles-section .sa-date-row {
    font-size: 15px;
    color: #b1b1b1;
    margin-bottom: 10px;
    max-width: 150px;
    overflow: hidden; }
  #similar-articles-section .sa-title-row {
    font-size: 20px;
    font-weight: bold;
    color: #634e70; }
  #similar-articles-section .read-sim-art-btn {
    width: 150px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 170px; }

#article-review-section {
  width: 80%;
  max-width: 960px;
  margin: 0 auto; }
  #article-review-section .section-title {
    font-size: 30px;
    font-weight: bold; }
  #article-review-section .rev-f-row {
    width: 100%;
    margin: 0 auto 30px; }
  #article-review-section .rev-f-half-input-container {
    width: 45%; }
  #article-review-section .rev-f-half-input-container .basic-input {
    width: 100%; }
  #article-review-section .rev-f-row {
    width: 100%;
    display: flex;
    justify-content: space-between; }
  #article-review-section .rev-f-full-input-container {
    width: 100%; }
  #article-review-section .rev-f-full-input-container .basic-input {
    width: 100%; }
  #article-review-section .art-rev-submit-btn {
    width: 250px;
    height: 55px;
    display: flex;
    justify-content: space-around;
    align-items: center; }
  #article-review-section .ar-form-response-row {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px; }
  #article-review-section .basic-input-err {
    border-bottom: 1px solid red; }

#review-list-section-container {
  margin-top: 75px; }
  #review-list-section-container .rev-list-item {
    margin-bottom: 20px; }
  #review-list-section-container .rev-list-item-name {
    font-weight: bold;
    color: #634e70;
    margin-bottom: 5px; }
  #review-list-section-container .rev-list-item-comment {
    font-size: 17px; }
  #review-list-section-container .rev-list-item-reply {
    cursor: pointer;
    color: #457bd5;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content; }
  #review-list-section-container .reply-section {
    margin-top: 1rem;
    margin-left: 1rem; }
  #review-list-section-container .arrow-replies {
    margin-left: 10px;
    font-size: 2rem;
    cursor: pointer; }
  #review-list-section-container .visible {
    display: inline; }
  #review-list-section-container .hidden {
    display: none; }
  #review-list-section-container .reply-form {
    margin-bottom: 20px; }

#article-container {
  max-width: 960px;
  display: flex;
  justify-content: space-around;
  margin: 0px auto; }
  @media (max-width: 575px) {
    #article-container {
      width: 94%;
      font-size: 22px; } }
  @media (max-width: 340px) {
    #article-container {
      width: 100%; } }

.share-button {
  padding-top: 40px; }

#article {
  padding-bottom: 70px; }
  #article .authro-av-card {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 15px; }
  #article .authro-av-card img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  #article .about-author-section {
    width: 80%;
    max-width: 960px;
    margin: 80px auto;
    border-radius: 25px; }
  #article .about-author-section .section-title {
    color: #634e70;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px; }
  #article .button-container {
    text-align: center; }
  #article .therapists-type {
    padding-bottom: 50px; }
  #article .member-title h2 {
    font-size: 35px;
    font-weight: 300; }
  #article .authro-details-container {
    display: flex;
    flex-wrap: wrap; }
  #article .author-info-container {
    flex: 1 1;
    padding-left: 30px; }
  #article .author-full-name {
    color: #634e70;
    font-size: 25px;
    font-weight: bold; }
  #article .author-bio {
    padding-left: 20px;
    margin-top: 10px;
    font-size: 18px; }

@media (max-width: 480px) {
  #article .page-title {
    text-align: left;
    font-size: 22px; }
  #article .article-details-container {
    flex-direction: column;
    align-items: center; }
  #article .ad-label {
    display: none; }
  #article .article-tags-container {
    flex-direction: column;
    align-items: center; }
  #article .art-tag-display {
    width: 80%;
    text-align: center;
    margin: 10px auto;
    font-weight: bold; }
  #article .rev-f-row {
    flex-wrap: wrap; }
  #article .rev-f-half-input-container {
    width: 100%; }
  #article .rev-f-submit-row {
    display: flex;
    justify-content: center; }
  #article .rev-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; }
  #article .sim-article-list {
    flex-direction: column;
    align-items: center; }
  #article .sim-art-card {
    display: flex;
    flex-direction: column;
    align-items: center; }
  #article .sa-data-row {
    flex-direction: column;
    align-items: center; }
  #article .sim-art-details-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%; }
  #article .sim-art-card-container {
    width: 100%; }
  #article .btn.read-sim-art-btn {
    margin: 0 auto; }
  #article .sim-art-thumbnail-container {
    margin: 0; }
  #article .sa-date-row {
    max-width: 100%; }
  #article .sa-action-row {
    width: 80%;
    margin: 15px auto; }
  #article .read-sim-art-btn {
    width: 100%; }
  #article .about-author-section .section-title {
    text-align: center; }
  #article .authro-details-container {
    flex-direction: column;
    align-items: center; }
  #article .author-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 10px; }
  #article .s-art-title {
    text-align: center; }
  #article .author-bio {
    font-size: 22px; } }

#faq .accordion {
  cursor: pointer; }

#faq .faq-container {
  padding-top: 20px;
  width: 80%;
  max-width: 960px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px auto;
  flex-direction: column; }

#faq .page-title {
  margin: 50px auto;
  color: white; }

#faq .more-therapists {
  padding-top: 50px;
  text-align: center; }

#faq h2 {
  font-size: 1.5em !important; }

#faq h1 {
  display: block;
  font-size: 2em !important;
  font-weight: bold; }

@media (max-width: 780px) {
  #faq .page-title {
    margin: 0px auto;
    text-align: center; }
  #faq h1 {
    font-size: 2em !important;
    font-weight: bold; }
  #faq h2 {
    font-size: 1.5em !important;
    font-weight: bold;
    margin-left: -120px; }
  #faq h2:nth-of-type(2) {
    margin-left: -190px; }
  #faq .accordion {
    margin-left: 5px;
    width: 95%; }
  #faq .faq-container {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    min-width: 100%; } }

@media (max-width: 480px) {
  #faq h1 {
    display: block;
    font-size: 2em !important;
    font-weight: bold; }
  #faq h2:first-of-type {
    margin-top: 140px;
    padding: 15px;
    font-size: 1.5em !important;
    margin-left: 0px; }
  #faq h2 {
    padding: 10px;
    font-size: 1.5em !important;
    margin-left: 0px !important; }
  #faq .page-title {
    text-align: center; }
  #faq .faq-container {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    min-width: 100%; } }

#manage-article-reviews {
  background: #f1f2f361;
  position: relative; }
  #manage-article-reviews .page-background {
    width: 100%;
    min-height: 100vh; }
  #manage-article-reviews h1 {
    margin: 0; }
  #manage-article-reviews .page-content {
    display: flex; }
  #manage-article-reviews .inner-content-container {
    flex: 1 1; }
  #manage-article-reviews .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 910px; }
  #manage-article-reviews .search-row {
    display: flex;
    margin-top: 50px;
    padding-bottom: 25px;
    border-bottom: 1px solid #ddd; }
  #manage-article-reviews .top-row {
    display: flex;
    justify-content: space-evenly; }
  #manage-article-reviews .inactive-tab {
    color: #ddd; }
  #manage-article-reviews .tab-item {
    cursor: pointer; }
  #manage-article-reviews .leftside-content {
    width: 70%;
    display: flex;
    justify-content: flex-start; }
  #manage-article-reviews .rightside-content {
    width: 30%;
    display: flex;
    justify-content: flex-end; }
  #manage-article-reviews .small-gradient-button {
    width: 90%;
    height: 50px;
    text-align: center; }
  #manage-article-reviews .small-gradient-button span {
    margin: 0; }
  #manage-article-reviews .sp-search-input {
    width: 90%; }
  #manage-article-reviews .results-section {
    margin-top: 30px; }
  #manage-article-reviews .message-span-container {
    padding-left: 30px; }
  #manage-article-reviews .filters {
    display: flex;
    margin-top: 20px;
    justify-content: center; }
  #manage-article-reviews .filters .filter-item {
    width: 150px;
    text-align: center;
    color: #457bd569;
    cursor: pointer; }
  #manage-article-reviews .filters .filter-item.active-filter {
    color: #457bd5;
    font-weight: bold; }
  #manage-article-reviews .filters .middle-filter {
    border-left: 3px solid #457bd5a8;
    border-right: 3px solid #457bd5a8; }
  #manage-article-reviews .rev-card-manage {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 30px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 15px 0; }
  #manage-article-reviews .rev-card-actions {
    width: 100px; }
  #manage-article-reviews .rev-actions-container {
    display: flex;
    justify-content: space-around; }
  #manage-article-reviews .accept-icon {
    cursor: pointer;
    color: #28a745; }
  #manage-article-reviews .delete-icon {
    cursor: pointer; }
  #manage-article-reviews .decline-icon {
    cursor: pointer;
    color: #dc3545; }
  #manage-article-reviews .rev-card-title {
    display: flex;
    margin-bottom: 15px; }
  #manage-article-reviews .rev-card-value {
    font-weight: bold;
    color: #634e70; }
  #manage-article-reviews .rev-card-rating {
    display: flex;
    margin-bottom: 20px; }
  #manage-article-reviews .rating-label {
    margin-right: 15px; }
  #manage-article-reviews .rev-card-review {
    display: flex; }
  #manage-article-reviews .review-label {
    margin-right: 15px; }
  #manage-article-reviews .review-content {
    font-size: 15px;
    line-height: 30px; }

@media (max-width: 580px) {
  #manage-article-reviews .inner-content-container {
    width: 100%; }
  #manage-article-reviews .inner-content {
    width: 95%; }
  #manage-article-reviews .page-title {
    text-align: center; }
  #manage-article-reviews .rev-card-manage {
    flex-direction: column;
    align-items: center; }
  #manage-article-reviews .rev-card-actions {
    margin-top: 30px; }
  #manage-article-reviews .rev-card-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  #manage-article-reviews .to-container {
    margin-left: -10px; }
  #manage-article-reviews .rev-card-review {
    flex-direction: column;
    align-items: center; }
  #manage-article-reviews .review-content {
    text-align: center; }
  #manage-article-reviews .no-review-text {
    text-align: center; }
  #manage-article-reviews .no-review-text {
    text-align: center; } }

#therapist-top-locations .top-loc-container {
  width: 80%;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }

#therapist-top-locations .page-title {
  margin: 50px auto; }

#therapist-top-locations .loc-list-container {
  width: 100%; }

#therapist-top-locations .loc-location-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

#therapist-top-locations .top-loc-item {
  width: 33%;
  display: flex;
  justify-content: space-around;
  align-items: center; }

@media (max-width: 480px) {
  #therapist-top-locations .page-title {
    text-align: center; }
  #therapist-top-locations .loc-location-list {
    flex-direction: column;
    align-items: center; }
  #therapist-top-locations .top-loc-item {
    width: 100%;
    text-align: center;
    margin: 10px auto; } }

#therapists-by-locations .therapists-by-loc-container {
  padding-top: 20px;
  width: 80%;
  max-width: 960px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px auto;
  flex-direction: column; }

#therapists-by-locations .page-title {
  margin: 50px auto;
  color: white; }

#therapists-by-locations .more-therapists {
  padding-top: 50px;
  text-align: center; }

@media (max-width: 480px) {
  #therapists-by-locations .page-title {
    text-align: center; }
  #therapists-by-locations .therapists-by-loc-container {
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    min-width: 100%; } }

.aboutUsHeading {
  margin: 55px 0; }

.our-story h2 {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 30px; }

@media (max-width: 991px) {
  .our-story {
    flex-direction: column-reverse; } }

.our-story p {
  color: #634e70;
  font-size: 18px;
  line-height: 1.5; }

.our-story img {
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  max-width: 100%;
  overflow: hidden; }
  @media (max-width: 991px) {
    .our-story img {
      display: block;
      margin: 0 auto 30px; } }

.our-story .about-img {
  float: right;
  width: 47%;
  margin-left: 50px;
  margin-bottom: 50px; }

@media (max-width: 550px) {
  .our-story img.about-img {
    float: none;
    margin: 30px auto;
    width: 70%; }
  .our-story p {
    width: 90%;
    margin: 25px auto;
    text-align: center;
    font-size: 15px;
    color: #999; } }

@media (max-width: 400px) {
  .our-story img.about-img {
    float: none;
    margin: 30px auto;
    width: 90%; }
  .our-story p {
    width: 90%;
    margin: 25px auto;
    text-align: center;
    font-size: 15px;
    color: #999; } }

.custom-cookies-consent-container {
  position: fixed;
  bottom: 5px;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0px 5px 23px rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 6px 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 15px;
  font-weight: bold;
  flex-direction: column; }
  .custom-cookies-consent-container .ccc-accept-button {
    padding: 7px 15px;
    background: #007bff;
    color: #fff;
    border-radius: 10px;
    border: 1px solid #007bff;
    cursor: pointer; }
  .custom-cookies-consent-container .ccc-details-button {
    padding: 7px 15px;
    background: #17a2b8;
    border-radius: 10px;
    border: 1px solid #17a2b8;
    color: #fff;
    margin-left: 10px;
    cursor: pointer; }
  .custom-cookies-consent-container .ccc-actions {
    display: flex;
    align-items: center; }
  .custom-cookies-consent-container .ccc-details-text {
    margin-right: 7px; }
  .custom-cookies-consent-container .ccc-text-details {
    margin-right: 10px;
    margin-top: 5px;
    font-size: 12px;
    color: #777;
    font-weight: normal; }
  .custom-cookies-consent-container .ccc-main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }

.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6,
.col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
  padding-left: 10px;
  padding-right: 10px; }

@media (min-width: 1200px) {
  .container {
    max-width: 960px; } }

@media (max-width: 575px) {
  .container {
    padding-left: 32px;
    padding-right: 32px; } }

.row {
  margin-left: -10px;
  margin-right: -10px; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Regular.ee653992.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-SemiBold.c641dbee.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/Montserrat-Bold.ade91f47.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

body {
  color: #634e70;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  height: auto; }
  body.overflow-hidden {
    overflow: hidden; }
  body p {
    text-align: left !important; }

a {
  color: #457bd5;
  font-family: 'Montserrat', sans-serif; }
  a:hover {
    text-decoration: none; }

h1 {
  color: #634e70;
  font-size: 45px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif; }
  @media (max-width: 767px) {
    h1 {
      font-size: 35px; } }

h2 {
  color: #634e70;
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 60px;
  font-family: 'Montserrat', sans-serif; }
  @media (max-width: 767px) {
    h2 {
      font-size: 35px; } }

.menu-navigation {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd; }
  .menu-navigation .navigate-links {
    display: flex;
    justify-content: space-between; }
  .menu-navigation .navigate-right {
    margin-right: 10px;
    text-align: right; }
  .menu-navigation .position-right {
    display: flex;
    justify-content: flex-end; }
  .menu-navigation .navigate-left {
    margin-left: 10px; }
  .menu-navigation .icon-left {
    display: none; }
  .menu-navigation .position-left {
    display: flex;
    justify-content: flex-start; }

.info-text {
  color: #634e70;
  display: flex;
  font-size: 12px;
  margin-top: 5px; }
  .info-text p {
    margin-left: 3px; }

.published-date {
  width: 170px; }

.btn.btn-default {
  background-image: -webkit-gradient(linear, left top, right top, from(#2441d1), to(#c380d6));
  background-image: linear-gradient(to right, #2441d1 0%, #c380d6 100%);
  border: 0 none;
  border-radius: 38px;
  box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  height: 60px;
  max-width: 300px;
  width: 100%;
  font-family: 'Montserrat', sans-serif; }
  .btn.btn-default.btn-small {
    max-width: 220px; }
  .btn.btn-default a {
    color: #fff;
    font-family: 'Montserrat', sans-serif; }
    .btn.btn-default a:hover {
      text-decoration: none; }

.m-width-600 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.mb-30 {
  margin-bottom: 30px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-60 {
  margin-bottom: 60px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-50 {
  margin-top: 50px; }

.mt-80 {
  margin-top: 80px; }

.mt-120 {
  margin-top: 120px; }

.mt-180 {
  margin-top: 180px; }

.mt-200 {
  margin-top: 180px; }

@media (max-width: 767px) {
  .mt-ltmd-80 {
    margin-top: 80px; } }

.text-purple {
  color: #634e70;
  font-family: 'Montserrat', sans-serif; }

.text-white {
  color: #fff;
  font-family: 'Montserrat', sans-serif; }

@media (max-width: 575px) {
  .text-xs-left {
    padding-left: 32px;
    padding-right: 32px;
    text-align: left; } }

.container .text-xs-left {
  padding-left: 0;
  padding-right: 0; }

.custom-input {
  border-color: #d8d8d8;
  border-width: 0 0 1px 0;
  font-family: "Montserrat";
  font-size: 20px;
  margin-bottom: 60px;
  width: 100%; }
  .custom-input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #d8d8d8; }
  .custom-input::-moz-placeholder {
    /* Firefox 19+ */
    color: #d8d8d8; }
  .custom-input:-ms-input-placeholder {
    /* IE 10+ */
    color: #d8d8d8; }
  .custom-input:-moz-placeholder {
    /* Firefox 18- */
    color: #d8d8d8; }
  @media (max-width: 767px) {
    .custom-input {
      margin-bottom: 30px; } }

.custom-radio--row {
  margin-bottom: 60px; }
  @media (max-width: 767px) {
    .custom-radio--row {
      margin-bottom: 30px; } }

