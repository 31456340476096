@import "../../variables.scss";

.custom-radio {
    background: url("../../static/images/desktop/radio-off-blue.png") no-repeat left center;
    cursor: pointer;
    margin-bottom: 5px;
    margin-right: 30px;
    padding-left: 40px;
    position: relative;
    white-space: nowrap;
    font-size: 1.3rem;
    max-width: 100%;
    overflow: hidden;

    &[data-checked="true"], &:hover {
        background-image: url("../../static/images/desktop/radio-on-blue.png");
    }
}