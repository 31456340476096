@import "../../variables.scss";


.tc-list-item {
    
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 20px 35px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    margin: 25px 0;
    
    .like-link {
        color: #457bd5
    }

    & .tc-image-container {
        width: 130px;
        height: 130px;
        line-height: 130px;
        border-radius: 15px;
        overflow: hidden;
    }

    & .tc-image-container img {
        width: 100%;
        // height: 100%;
    }

    & .tc-info-container {
        //margin-left: 140px;
        flex: 1;
        padding-left: 50px;
    }

    & .tc-actions-container {
        width: 120px;
    }

    .right-col {
        display: flex;
        align-items: center;
    }

    & .tc-main-info {
        font-size: 25px;
        
        // font-weight: 600;
        color: #634e70;
        flex-wrap: wrap;
    }

    & .tc-main-info .ai-u-name {
        font-weight: 600;
    }

    & .tc-details-info {
        font-size: 17px;
        color: #634e70;
    }

    & .tc-detail-container {
        display: flex;
    }

    & .tc-detail-label {
        width: 75px;
        font-weight: 600;
    }

    & .tc-actions-container {
        display: flex;
        justify-content: space-evenly;
    }

    & .tr-reviews-value {
        margin-left: 20px;
    }

    & .contact-info-item {
        margin: 5px 0 5px;
        align-items: center;
        @media (max-width: $md) {
            max-width: 250px;
            width: 100%;
            overflow: hidden;
            display: flex;
        }
    }

    & .contact-info-value {
        margin-left: 15px;
    }
    & .left-col {
        width: 130px;
    }

    & .tr-reviews-row {
        margin-bottom: 20px;
    }

    & .therapist-flag {
        font-size: 13px;
        margin-left: 15px;
        margin-top: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-weight: bold;
        color: #28a745;
    }

    & .online-flag {
        font-size: 13px;
        margin-left: 15px;
        margin-top: 10px;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        font-weight: bold;
        color: #53d6d6;
    }

    & .therapist-flag-text {
        margin-left: 5px;
    }

    & .green-icon {
        font-size: 30px;
    }

    .yellow-icon {
        font-size: 30px;
        color: #ebbd34;
    }

    .therapist-insurance-flag {
        color: #ebbd34;
        margin-left: 5px;
    }
}

.hide-distance {
    display: none !important;
}

.flags-row {
    //clear: left;
    padding-top: 5px;
   // margin-left: -150px;
    display: block;
    margin-left: -20px !important;
}

@media (max-width: $sm) {
    
    .tc-list-item {
        padding: 20px 20px ;
        flex-direction: column;
        align-items: center;

        & .tc-info-container {
            padding: 0;
            margin-left: 0px;
            padding-left: 0 ;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            order: 3;
        }

        & .tc-main-info {
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin-top: 15px;
            margin-bottom: 10px;
            text-align: center;
        }

        & .tc-u-title {
            text-align: center;
            margin-left: -15px;
        }

        & .tc-details-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 15px;
            width: 90%;
        }

        & .tr-reviews-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
        }

        & .tr-reviews-value {
            margin: 5px auto;
            text-align: center;
        }

        .contact-info-list {
            width: 100%;
            // margin-top: 30px;
            // margin-bottom: 30px;
        }

        .left-col {
            order: 1;
        }

        .right-col {
            order: 2;
        }

    }

    

}