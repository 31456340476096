@import "../../variables.scss";

.aboutUsHeading {
    margin: 55px 0;
    padding-bottom: 20px;
    padding-top: 20px;
    color: white;
    h1{
      margin-bottom: 135px;
        font-weight: 600;
    }
    img {
        display: block;
    }
}
.image{
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
.blog-listing-container {
    padding-top: 100px;
    h2{
        text-align: center;
    }
}
.our-story-therapy {
  .text-section-therapy{
  img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      min-width: 50px;
      margin-bottom: 50px;
  }
}
    h2 {
       margin-top: 48px;
       padding-bottom: 24px;
       margin-bottom: 24px;
        font-weight: 400;
        font-size: 30px;

    }
    & .article-details-container span {
        margin-right: 5px;
    }
    @media(max-width: $lg - 1) {
        flex-direction: column-reverse;
    }

    p {
        color: $heading_purple;
        font-size: 18px;
        line-height: 1.5;
    }

    & .about-img {
        float: right;
        width: 60%;
        margin-left: 50px;
        margin-bottom: 20px;
    }
}

@media (max-width: 550px) {
  .aboutUsHeading {
    color: white;
}
    .our-story {

        img.about-img {
            float: none;
            margin: 30px auto;
            width: 70%;
        }

        p {
            width: 90%;
            margin: 25px auto;
            font-size: 15px;
            color: #999;
            text-align: left;
        }

    }
}

@media (max-width: 400px) {
  .aboutUsHeading {
    color: white;
}
    .our-story {

        img.about-img {
            float: none;
            margin: 30px auto;
            width: 90%;
        }

        p {
            width: 90%;
            margin: 25px auto;
            text-align: left;
            font-size: 15px;
            color: #999;
            line-height: 1.5;
        }

    }
}
