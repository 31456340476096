@import "../../variables.scss";

.aboutUsHeading {
    margin: 55px 0;
}
.our-story {
    h2 {
       margin-bottom: 10px;
        font-weight: 400;
        font-size: 30px;

    }
    @media(max-width: $lg - 1) {
        flex-direction: column-reverse;
    }

    p {
        color: $heading_purple;
        font-size: 18px;
        line-height: 1.5;
    }

    img {
        box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        border-radius: 30px;
        max-width: 100%;
        overflow: hidden;

        @media(max-width: $lg - 1) {
            display: block;
            margin: 0 auto 30px;
        }
    }

    & .about-img {
        float: right;
        width: 47%;
        margin-left: 50px;
        margin-bottom: 50px;
    }
}

@media (max-width: 550px) {
    .our-story {

        img.about-img {
            float: none;
            margin: 30px auto;
            width: 70%;
        }

        p {
            width: 90%;
            margin: 25px auto;
            text-align: center;
            font-size: 15px;
            color: #999;
        }

    }
}

@media (max-width: 400px) {
    .our-story {

        img.about-img {
            float: none;
            margin: 30px auto;
            width: 90%;
        }

        p {
            width: 90%;
            margin: 25px auto;
            text-align: center;
            font-size: 15px;
            color: #999;
        }

    }
}
