.rlb-item {
    display: flex;
    background: linear-gradient(to right,  #106ff1, #a723ec);
    // padding: 5px 15px;
    border-radius: 25px;
    align-items: center;
    margin: 5px 10px;
    justify-content: space-around;
    padding: 2px;

    & .sp-at-outer {
        background: #fff;
        border-radius: 25px;
        padding: 5px 20px;
    }

    & .sp-at-name {
        margin: 0;
    }
}   