#therapist-details {

    background: #f1f2f361;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .second-row {
        display: flex
    }

    & .leftside-content {
        width: 30%;
    }

    & .rightside-content {
        width: 70%;
    }

    & .inner-content .leftside-content .avatar-container {
        width: 125px;
        height: 125px;
        background: #ddd;
        border-radius: 25px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        overflow: hidden;
        border: 1px solid #efefef;
        position: relative;
    }

    & .inner-content .avatar-container .avatar-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        color: transparent;
    }

    & .inner-content .avatar-container .avatar-cover:hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0, .5);
        color: #eee;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
    }

    & .inner-content .avatar-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & .inner-content .add-photo-btn {
        width: 50px;
        height: 50px;
        border: 1px solid #fff;
        border-radius: 50%;
        color: #fff;
        font-size: 31px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto 15px auto;
    }

    & .input-row input {
        width: 80%;
        max-width: 380px;
    }

    & .form-container {
        margin-top: 50px;
    }

    & .input-row {
        margin-bottom: 35px;
        // display: flex
    }

    & .textarea-row.input-row {
        display: flex;
    }

    & .submit-row {
        margin-top: 25px;
    }

    & input.input-error {
        border-bottom: 1px solid red;
    }

    & .submit-btn-medium {
        width: 200px;
        height: 50px;
    }

    & textarea.basic-input {
        width: 80%;
        max-width: 380px;
        border: 1px solid rgb(221, 221, 221);
        outline: none;
        background: transparent;
        color: rgb(204, 204, 204);
        margin-top: 0px;
        margin-bottom: 0px;
        height: 160px;
        text-indent: 5px;
    }

    & .input-icon {
        margin-right: 15px;
    }

    & .profile-img-sub-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}

@media (max-width: 580px) {
    #therapist-details {

        & .inner-content {
            width: 95%;
        }

        & .page-title {
            text-align: center;
        }

        & .second-row {
            flex-direction: column;
            align-items: center;
        }

        & .leftside-content {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }

        & .rightside-content {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }

        & .form-container {
            width: 100%;
            display: flex;
            justify-content: space-around;
        }

        & .form-container form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .input-row {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        & .input-row input {
            flex: 1;
        }

        & .input-icon {
            margin-top: 15px;
        }

        & .textarea-row {
            flex-direction: column;
            align-items: center;
        }

        & .textarea-row .basic-input {
            margin-top: 10px;
            width: 100%;
        }

        & .submit-row {
            margin-top: 35px;
            margin-bottom: 30px;
        }

    }
}