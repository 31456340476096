@import "../../variables.scss";

.header {
    padding-bottom: 20px;
}

.aboutUsHeading {
    margin: 75px 0;
    color: #ffff;
    font-weight: 600;

    @media(max-width: $lg - 1) {
        color: #634e70;
        font-size: 38px;
    }
    @media(max-width: $md - 1) {
        font-size: 25px;
    }
}

.our-story {
    h1 {
        font-weight: 600;
    }
    .about-img{
        margin-top: 35px;
    }
    h2 {
        margin-top: 48px;
        margin-top: 28px;
        padding-bottom: 24px;
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 30px;
        font-weight: 400;
        font-size: 30px;

    }

    @media(max-width: $lg - 1) {
        border-radius: 30px;
        max-width: 100%;
        overflow: hidden;
        margin: 18px 0 50px 0;

        @media(max-width: $lg - 1) {
            display: block;
            margin: 0 auto 30px;
            margin: 0 auto;
        }
    }

    .schedule-call-h2 {
        margin-bottom: 16px;
        margin-top: 0;
        padding: 0;
    }

    .schedule-call {
        margin-top: 32px;
        margin-bottom: 120px;
    }

    .search-radio-about {
        margin-top: 50px;
        margin-bottom: 100px;
    }

}