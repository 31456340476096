.rd-container {

    & .rd-input-outer {
        width: 100%;
        height: 60px;
        background: linear-gradient(to right, #106ff1, #a723ec);
        padding: 2px;
        border-radius: 30px;
    }

    & .rd-input-inner {
        display: flex;
        justify-content: flex-start;
        padding-left: 50px;
        align-items: center;
        height: 100%;
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
        cursor: pointer;
    }

    & .rd-input-placeholder {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 15px;
        align-items: center;
    }

    & .rd-input-placeholder-text {
        flex: 1;
        padding-left: 15px;
    }

    & .rd-label {
        font-size: 25px;
        font-weight: 700;
        color: #126ef1;
    }

    & .rd-options-open {
        position: absolute;
        z-index: 1000;
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 15px;
        width: 100%;
        border-top: none;
    }

    & .rd-options-hidden {
        display: none;
    }

    & .rd-option-item {
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: space-around;
        color: #634e70;
    }

    & .rd-option-item:hover {
        background-image: linear-gradient(to right, #2441d1 0%, #c380d6 100%);
        color: #fff;
        cursor: pointer;
    }

}