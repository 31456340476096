@import "../../variables.scss";

.aboutUsHeading {
    margin: 55px 0;
    padding-bottom: 20px;
    padding-top: 20px;
    color: white;

    @media (max-width: $md - 1) {
        padding-top: 0px;
    }

    h1{
      margin-bottom: 135px;
        font-weight: 600;
    }
    img {
        display: block;
    }
}

.link-button {
    background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
//   font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.image-style-side-right {
    max-width: 40%;
    float: right;
    margin: 10px 0 10px 20px;

    @media (max-width: $md - 1) {
        max-width: 100%;
        float: none;
        margin: 10px;
    }
}

.button-container {
    text-align: center;
    margin: 40px 0 60px 0;
  }

.image-style-side-left {
    max-width: 40%;
    float: left;
    margin: 10px 20px 10px 0px;

    @media (max-width: $md - 1) {
        max-width: 100%;
        float: none;
        margin: 10px;
    }
}

.image-style-side-right img, .image-style-side-left img {
    margin-bottom: 0px!important;
}

.image{
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
.blog-listing-container {
    padding-top: 100px;
    h2{
        text-align: center;
    }
}
.our-story-therapy {

  .text-section-therapy{
      margin-top: 100px;
      
  img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      min-width: 50px;
      margin-bottom: 50px;
  }
}
    h2 {
       margin-top: 48px;
       padding-bottom: 24px;
       margin-bottom: 24px;
        font-weight: 400;
        font-size: 30px;

    }
    & .article-details-container span {
        margin-right: 5px;
    }
    @media(max-width: $lg - 1) {
        flex-direction: column-reverse;
    }

    p {
        color: $heading_purple;
        font-size: 18px;
        line-height: 1.5;
    }

    & .about-img {
        float: right;
        width: 60%;
        margin-left: 50px;
        margin-bottom: 20px;
    }
}

@media (max-width: 550px) {
    .aboutUsHeading {
    color: white;
    }

    .articles-list {
        padding-left: 0;
    }
    .our-story {

        img.about-img {
            float: none;
            margin: 30px auto;
            width: 70%;
        }

        p {
            width: 90%;
            margin: 25px auto;
            font-size: 15px;
            color: #999;
            text-align: left;
        }

    }
}

@media (max-width: 400px) {
  .aboutUsHeading {
    color: white;
}
    .our-story {

        img.about-img {
            float: none;
            margin: 30px auto;
            width: 90%;
        }

        p {
            width: 90%;
            margin: 25px auto;
            text-align: left;
            font-size: 15px;
            color: #999;
            line-height: 1.5;
        }

    }
}
