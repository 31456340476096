#article-container {
    max-width: 960px;
    display: flex;
    justify-content: space-around;
    margin: 0px auto;

    @media (max-width: 575px) {
        width: 94%;
        font-size: 22px;
    }

    @media (max-width: 340px) {
        width: 100%;
    }
}
.share-button{
    padding-top: 40px;
}
#article {
    padding-bottom: 70px;

    & .authro-av-card {
        width: 150px;
        height: 150px;
        overflow: hidden;
        border-radius: 15px;
    }

    & .authro-av-card img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & .about-author-section {
        width: 80%;
        max-width: 960px;
        margin: 80px auto;
        // box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        border-radius: 25px;
    }

    & .about-author-section .section-title {
        color: #634e70;
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .button-container {
        text-align: center;
      }

      .therapists-type{
        padding-bottom: 50px;
      }
      .member-title{
        h2{
          font-size: 35px;
          font-weight: 300;
        }}

    & .authro-details-container {
        display: flex;
        flex-wrap: wrap;
    }

    & .author-info-container {
        flex: 1;
        padding-left: 30px;
    }

    & .author-full-name {
        color: #634e70;
        font-size: 25px;
        font-weight: bold;
    }

    & .author-bio {
        padding-left: 20px;
        margin-top: 10px;
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    #article {

        & .page-title {
            text-align: left;
            font-size: 22px;
        }

        & .article-details-container {
            flex-direction: column;
            align-items: center;
        }

        & .ad-label {
            display: none;
        }

        & .article-tags-container {
            flex-direction: column;
            align-items: center;
        }

        & .art-tag-display {
            width: 80%;
            text-align: center;
            margin: 10px auto;
            font-weight: bold;
            // color: #fff;
        }

        & .rev-f-row {
            flex-wrap: wrap;
        }

        & .rev-f-half-input-container {
            width: 100%;
        }

        & .rev-f-submit-row {
            display: flex;
            justify-content: center;
        }

        & .rev-list-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        & .sim-article-list {
            flex-direction: column;
            align-items: center;
        }

        & .sim-art-card {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .sa-data-row {
            flex-direction: column;
            align-items: center;
        }

        & .sim-art-details-container {
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 100%;
        }

        & .sim-art-card-container {
            width: 100%;
        }

        & .btn.read-sim-art-btn {
            margin: 0 auto;
        }

        & .sim-art-thumbnail-container {
            margin: 0;
        }

        & .sa-date-row {
            max-width: 100%;
        }

        & .sa-action-row {
            width: 80%;
            margin: 15px auto;
        }

        & .read-sim-art-btn {
            width: 100%;
        }

        & .about-author-section .section-title {
            text-align: center;
        }

        & .authro-details-container {
            flex-direction: column;
            align-items: center;
        }

        & .author-info-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            margin-top: 10px;
        }

        & .s-art-title {
            text-align: center;
        }

        & .author-bio {
            font-size: 22px;
        }

    }
}
