#sponsorship {

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        width: 80%;
        margin: 0 auto;
        max-width: 960px;
    }

    & .inner-content {
        margin-top: 50px;
    }

    & .page-title {
        margin-top: 50px
    }

    & .plan-description-section {
        margin-top: 25px;
        font-size: 17px;
    }

    & .plans {
        display: flex;
        justify-content: space-between;
        padding-bottom: 100px;
    }

    & .choose-plan-container {
        display: flex;
        justify-content: space-around;
    } 

    & .plan-budget-container  {
        display: flex;
        justify-content: space-around;
    }

    .stripe-payment-method {
        margin-bottom: 90px;
      }
}


@media (max-width: 580px) {
    #sponsorship {
        
        & .inner-content-container {
            width: 95%;
        }

        & .page-title {
            text-align: center;
        }

        & .plan-description-section {
            padding: 15px 25px;
            text-align: center;
        }

        & .plans {
            flex-direction: column;
            align-items: center;
        }

        & .sponsorship-plan-card {
            width: 100%;
            padding: 20px 15px;
        }

        & .settings-form-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .palce-price-container {
            width: 100%;
            text-align: center; 
        }

        & .place-item {
            justify-content: center;
            margin: 30px 10px;
        }

        & .plan-settings-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
        }

        & .cancel-change-container {
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        & .cancel-change-container .btn {
            width: 90% !important;
            margin-bottom: 15px;
        }

    }
  }