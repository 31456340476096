#article-content-container  {

    width: 100%;
    margin-top: 80px;

    p{
      line-height:1.5;
    }

    h3 {
      margin-bottom: 12px;
    }
    & .article-read-only-blg {
      @media (max-width: 575px) {
        padding-top: 30px;
        }

        .ck.ck-editor__top.ck-reset_all {
            display: none;
        }
        p{
          line-height:1.5;
        }
    }

    & .ck-blurred.ck.ck-content {
        border: none;
    }

    .ck-content .image>img {
      width: 100%;
      height: auto;
    }

    @media (max-width: 575px) {
      .ck-content .image-style-side {
        float: none;
        margin: 0 auto;
      }

      .ck-content .image-style-align-center, .ck-content .image-style-align-left, .ck-content .image-style-align-right, .ck-content .image-style-side {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      h2 {
        text-align: left !important;
      }
    }

    & .art-con-title-container {
        text-align: center;
        margin-bottom: 5px;
        h1{
          color: #fff;
          font-weight: 600;
          margin-bottom: 100px;
          }
          @media (max-width: 575px) {
            h1{
                  color: white;
              text-align: center;

            }
              }
    }


    & .article-details-container {
        display:inline-block;
        font-size: 17px;
      @media (max-width: 575px) {
        padding-bottom: 13px;
        padding-left: 40px;
      }
    }

    & .article-details-container p {
        margin-right: 5px;
    }

    & .ad-value {
        font-weight: bold;
        color: #634e70;
        margin-bottom: -4px;
    }

    // &   figcaption {
    //     display: none !important;
    // }
}
