.sponsorship-plan-card {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 30px 35px;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    width: 47%;
    padding-bottom: 45px;
    margin-top: 20px;

    & .plan-name {
        font-size: 25px;
        font-weight: bold;
        color: #634e70;
        display: flex;
        justify-content: space-around;
    }

    & .plan-subtitle {
        font-size: 15px;
        color: #ccc;
        display: flex;
        justify-content: space-around;
        text-align: center;
        margin-top: 20px;
    }

    & .place-item {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        font-size: 17px;
        align-items: center;
        margin: 25px 10px;
    }

    & .checkbox-container {
        margin-right: 15px;
        margin-top: -5px;
    }

    & .place-name-container {
        margin-right: 5px;
    }

    & .price-val {
        margin-right: 5px;
        color: #634e70;
        font-weight: bold;
    }

    & .place-name {
        color: #634e70;
    }

    & .budget-input-outer {
        width: 250px;
        height: 60px;
        background: linear-gradient(to right, #106ff1, #a723ec);
        padding: 2px;
        border-radius: 30px;
    }

    & .budget-input-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding-left: 20px;
        align-items: center;
        height: 100%;
        background: #fff;
        border-radius: 30px;
        overflow: hidden;
        flex-direction: column;
    }

    & .budget-input-container {
        flex: 1;
        display: flex;
        padding: 15px 5px;
        flex-wrap: wrap;
    }

    & .budget-input-container input {
        width: 120px;
        border: none;
        // border-bottom: 1px solid #eee;
    }

    & .budget-label {
        font-size: 20px;
        font-weight: 700;
        color: #126ef1;

    }

    & .plan-budget-container {
        margin-top: 35px;
        margin-bottom: 70px;
    }

    & .pc-title {
        font-size: 20px;
        font-weight: bold;
        color: #634e70;
        margin: 35px 0 5px;
    }

    & .psc-row {
        display: flex;
        padding-left: 15px;
    }

    & .psc-label {
        font-size: 15px;
        font-weight: bold;
        margin-right: 10px;
    }

    & .psc-value {
        font-size: 16px;
        font-weight: bold;
        color: #634e70;
    }

    & .plan-settings-container {
        margin-bottom: 50px;
        padding-left: 10px;
    }

    & .csp-button {
        border: 2px solid #ddd;
        width: 300px;
        border-radius: 35px;
        cursor: pointer
    }

    & .csp-btn-main {
        font-weight: bold;
        text-align: center;
        margin-top: 10px;
        color: #ccc;
    }

    & .csp-btn-sub {
        font-size: 13px;
        text-align: center;
    }

    & .card-btn-small {
        width: 45% !important;
        min-width: 150px !important;
    }

    & .cancel-change-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    & .marginbottom0 {
        margin-bottom: 0 !important;
    }

    & .active-plan-marker {
        text-align: center;
        color: #dc3545;
        font-weight: bold;
        font-size: 13px;
    }
}