@import "../../variables.scss";

#travelPage {
    margin-top: 120px;

    .travel-image {
        margin: 0 auto;
        max-width: 100%;
        width: auto;
    }   
}