#payment {
  .page-background {
    .page-content {
      .inner-content-container {
        width: 80%;
        margin: 0 auto;
        // margin-top: 50px;
        max-width: 810px;
        display: flex;

        .inner-content {

          .top-row {
            .page-title {
              margin-bottom: 30px;
            }
          }
          margin-top: 50px;
          .main-content {

            .stripe-payment-method {
              margin-bottom: 90px;
            }

            h1 {
              text-align: center;
              font-weight: 600;
            }

            .plans {
              display: flex;
              align-content: space-between;

              margin-top: 30px;
              margin-bottom: 100px;

              .plan {
                position: relative;
                width: 270px;
                border-radius: 30px;
                border: 1px solid #979797;
                margin-top: 50px;
                padding-top: 40px;
                text-align: center;
                height: 480px;
                
                margin-right: 5px;

                h3 {
                  font-size: 25px;
                  font-weight: 600;
                  
                }

                p {
                  font-size: 16px;
                  font-weight: 400;
                  
                }

                h1 {
                  font-size: 45px;
                  font-weight: 700;
                  
                }

                .plan-list {
                  margin: 20px auto;
                  color: #634e70;
                  font-size: 16px;
                  font-weight: 400;
                  height: 120px;

                }

                li {
                  margin: 8px;
                }

                .plan-button {
                  margin: 0 auto;
                  width: 150px;
                  
                }
              }
            }
          }
        }
      }
    }
  }

  & .plans-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  & .order1 {
    order: 1
  }

  & .order2 {
    order: 2
  }

  & .order3 {
    order: 3
  }

  & .order4 {
    order: 4
  }

}


@media (max-width: 580px) {
  #payment {

      & .inner-content-container {
          width: 95% !important;
          overflow: hidden;
      }

      & .inner-content {
        width: 100%;
      }

  }
}