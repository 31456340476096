@import "../../variables.scss";
login-btn {
    margin-left: 40px;
    img {
        margin-right: 10px;
    }
    @media (max-width: $md - 1) {
        margin-left: 0;
    }
}

.header {
    
    h1{
        font-weight: 600;
    }
    background-position-x: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    position: relative;

    .search-form-container{
        p{
            color:white;
        }
        @media (max-width: $md) {
            margin-top: 10px;
            margin-bottom: 40px;
        }
    }
    & .greeting{
        margin-left: 180px;
       // padding-bottom: 80px;
       @media (max-width: $md) {
        margin-left: 0;
        }
        @media (max-width: 780px) {
            margin: 0px;
        }
    }

    #searchForm {
        margin-top: 0;
    }

    & .close-mobile-menu {
        display: none;
        // @media (max-width: 780px) {
        //     display: none;
        // }
        @media (max-width: $lg - 1) {
            display: block;
            position: absolute;
            right: 10px;
            color: white;
            top:31px;
            font-size: 20px;
            font-weight: 900;
        }
        
    }
    
    & .menu li {
        font-size: 20px;
        font-weight: 600;

        @media (max-width: $lg) {
            width: max-content !important;
        }
    }

    &.header-home {
        background-image: url("../../static/images/desktop/homepage-header-background_missized.png");
        padding-top: 20px;
        background-size: cover;

        @media (max-width: 1566px ) {
            // background-size: auto 600px;
            background-size: contain;
        }

        @media (max-width: $lg - 1) {
            background-image: url("../../static/images/desktop/home-bg-md.png");
            // background-size: auto 600px;
            background-size: contain;

        }

        @media (max-width: $md - 1) {
            background-image: url("../../static/images/desktop/home-mobile-bg.png");
            // background-position: -90px -70px;
            background-size: contain;
            padding-top: 220px;
        }

        @media (max-width: 350px) {
            background-size: auto 480px;
            padding-top: 170px;
        }

        .search-panel {
            .search-field--bg {
                padding: 2px;
            }
        }
        .search-form-container{
            p{
                color:$heading_purple;
            }
        }
        .menu {
            margin-bottom: 50px;
            width: 100%;

            & .sub-menu-link {
                color: #437cd0;

            }

            li {
                a {
                    color: $link_blue;

                    @media (max-width: $lg - 1) {
                        color: #fff;
                        font-weight: bold;
                    }

                    &:hover {
                        border-bottom-color: transparent;
                    }
                }
            }
        }
    }

    .search-white-text {
        color: #ffff;
        margin-top: 40px;
        @media (max-width: $md - 1) {
            margin-top: -10px;
        }
    }

    .burger-menu {
        background-image: url("../../static/images/desktop/burger_menu_icon.png");
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        filter: drop-shadow(0px 0px 1px #fff);
        height: 18px;
        padding: 20px;
        position: absolute;
        text-align: center;
        right: 20px;
        top: 70px;
        width: 24px;

        @media (min-width: $lg) {
            display: none;
        }

        @media (max-width: 350px) {
            right: 20px;
        }

        .toggle-button__line {
            display: none;
        }
    }

    .bg-image {
        height: 1200px;
        left: 50%;
        max-width: 3020px;
        object-fit: cover;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        z-index: -1;
        margin-top: -350px;
        @media (max-width: $sm - 1) {
            headerBackground: url('../../static/images/desktop/header-background.png');
            margin-top: 0px;
            //max-width: 650px;
            height: 730px;
            // background-position: -90px -70px;
            background-size: 90px;
            object-position: -370px -93px;
        }
    }

    .menu {
        align-items: center;
        display: flex;
        flex-flow: wrap;
        font-size: 16px;
        margin-bottom: 0;
        padding-left: 0;

        @media (min-width: $md) {
            justify-content: space-between;
        }

        @media (max-width: $lg - 1) {
            width: 100%;
        }

        @media (max-width: $lg - 1) {
            background-color: rgba($default_text_grey, 0.8);
            flex-direction: column;
            // height: 100vh;
            flex-wrap: nowrap;
            left: 0;
            padding: 20px 0;
            position: fixed;
            top: 0;
            visibility: hidden;
            width: 100vw;
            z-index: 10;
        }

        .btn.btn-default {
            height: 50px;
            width: 150px;

            @media (max-width: $lg - 1) {
                width: 160px;
            }
        }

        li {
            list-style: none;
            margin-left: 15px;
            margin-right: 15px;

            &:first-of-type {
                margin-left: 0;
            }

            // &:last-of-type {
            //     margin-right: 0;
            // }

            @media (max-width: $lg - 1) {
                margin-left: 10px;
                margin-right: 10px;
            }

            @media (max-width: $md - 1) {
                text-align: center;
                width: 100%;
                margin: 0 auto;
            }

            &.login-btn {
                margin-left: 40px;
                img {
                    margin-right: 10px;
                }
                @media (max-width: $md - 1) {
                    margin-left: 0;
                }
            }

            a {
                color: #fff;
                padding: 5px 0;
                border: solid 1px transparent;
                &:hover, &.active {
                    border-bottom-color: #fff;
                }
                @media (max-width: $md - 1) {
                    display: block;
                    padding: 10px;
                }
            }
        }
    }
    .logo {
        margin-right: auto;
        height: auto;

        @media (max-width: $lg - 1) {
            padding-bottom: 20px;
            text-align: center;
            width: 230px;
            max-width: 230px;
        }
        @media (max-width: $md - 1) {
            margin: 10px 0 40px;
            text-align: left;
            padding-bottom: 0;
        }
        @media (max-width: $sm - 1) {
            img {
                width: 230px;
            }
        }
    }

    .media-icons-container {
        display: flex;
        justify-content: flex-end;
        margin-top: -50px;
        height: 45px;
    }

    @media (max-width: 766px) {
        .media-icons-container {
            justify-content: space-around;
        }
    }
}

