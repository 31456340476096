#therapists-by-locations {

    & .therapists-by-loc-container {
        padding-top: 20px;
        width: 80%;
        max-width: 960px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 20px auto;
        flex-direction: column;
    }

    & .page-title {
        margin: 50px auto;
        color: white;
    }
    & .more-therapists{
        padding-top: 50px;
        text-align: center;
    }
}

@media (max-width: 480px) {
    #therapists-by-locations {

        & .page-title {
            text-align: center;
        }

        & .therapists-by-loc-container {
            flex-direction: column;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }

    }
}
