
#therapist-profiles {
    & .therapist-profiles-content {
        // width: 80%;
        max-width: 960px;
        margin: 80px auto;
    }
    & .search-form-container{
        margin-bottom: 0px;
    }
    & .load-more-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 35px auto;
    }

    & .invisible-btn {
        display: none;
    }

    & .search-by-name-section {
        // width: 80%;
        margin: 35px auto;
        max-width: 960px;
    }

    & .search-by-name-input-container {
        display: flex;
        justify-content: space-between;
    }

    & .search-by-name-btn {
        width: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    & .search-by-name-input {
        flex: 1;
        margin-right: 35px;
    }
}

@media only screen and (max-width: 660px) {
	.tc-u-name {
		text-align: center;
	}
	
	.tc-b-name {
    text-align: center;
	}
}

@media (max-width: 460px) {
    #therapist-profiles {
        
        & .search-by-name-section {
            width: 100%;
            padding: 50px 0px;
            margin: 0;
        }

        & .page-title {
            text-align: center;
        }

        & .search-by-name-input-container {
            flex-direction: column;
            align-items: center;
        }

        & .round-input-gradient.search-by-name-input {
            margin: 15px 0;
            width: 100%;
        }

        .therapist-profiles-content {
            margin-top: 0;
        }
        
    }
  }