@import "../../variables.scss";

.header {
    margin-bottom: -30px;
    background-position-x: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 5px;
    position: relative;

    & .close-mobile-menu {
        display: none;
        @media (max-width: $md - 1) {
            display: none;
        }
        @media (max-width: $sm) {
            display: block;
            position: absolute;
            right: 10px;
            color: white;
            top:31px;
            font-size: 20px;
            font-weight: 700!important;
        }

    }

    & .sub-menu-item {
        margin: 0;
    }

    & .sub-menu-link {
        color: #ffff;
        cursor: pointer;

        .sub-menu-item {
        margin: 0;
        @media (max-width: $lg - 1) {
            margin: 0 auto;
        }

        }

        .sub-menu-name {
            @media (max-width: $lg - 1) {
                display: none;
            }
            
        }

        .sub-menu-item a{
            color: #437cd0;
            @media (max-width: $lg - 1) {
                color: #ffff;
            }
        }

        .sub-menu-item a:hover{
        border-bottom-color: #437cd0;
        }
    }
    
    & .sub-menu {
        position: absolute;
        display: none;
        flex-direction: column;
        background-color: #eeeeee;
        padding: 10px 10px 10px 20px;
        border-radius: 20px;
        z-index: 100;

        @media (max-width: $lg - 1) {
            position: relative;
            background: none;
            display: block;
            padding: 0;
        }

        
    }

    & .show-sub-menu {
        display: flex;

    }

    & .greeting{
        // margin-left: 180px !important;
       // padding-bottom: 80px;
       @media (max-width: $md) {
        margin: 0px;
        }
    }
    
    @media (max-width: 500px) {
        // margin-bottom: -160px;
        padding-top: 30px;

    }
    
    & .menu li {
        font-size: 20px;
        font-weight: 600;
    }

    .burger-menu {
        background-image: url("../../static/images/desktop/burger_menu_icon.png");
        background-repeat: no-repeat;
        display: block;
        filter: drop-shadow(0px 0px 1px #fff);
        height: 18px;
        padding: 20px;
        position: absolute;
        right: 20px;
        top: 70px;
        width: 24px;

        @media (min-width: $sm) {
            display: none;
        }

        @media (max-width: 350px) {
            right: 20px;
        }

        .toggle-button__line {
            display: none;
        }
    }

    bg-image {
        height: 1200px;
        left: 50%;
        max-width: 3020px;
        object-fit: cover;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        z-index: -1;
        margin-top: -350px;
        @media (max-width: $sm - 1) {
            headerBackground: url('../../static/images/desktop/header-background.png');
            margin-top: 0px;
            //max-width: 650px;
            height: 730px;
            // background-position: -90px -70px;
            background-size: 90px;
            object-position: -350px -60px;
            .d-md-flex {
                display: none;
            }
        }
    }

    .menu {
        align-items: center;
        display: flex;
        flex-flow: wrap;
        font-size: 16px;
        margin-bottom: 0;
        padding-left: 0;
        margin-top: 20px;

        @media (min-width: $md) {
            justify-content: space-between;
            
        }

        @media (max-width: $lg - 1) {
            width: 100%;
        }

        @media (max-width: $md - 1) {
            background-color: rgba($default_text_grey, 0.8);
            flex-direction: column;
            height: 100vh;
            flex-wrap: nowrap;
            left: 0;
            padding: 20px 0;
            position: fixed;
            top: 0;
            visibility: hidden;
            width: 100vw;
            z-index: 10;
            margin-top: 0px;
        }

        li {
            list-style: none;
            margin-left: 15px;
            margin-right: 15px;

            // &:first-of-type {
            //     // margin-left: 0;
            // }

            &:last-of-type {
                // margin-right: 0;
                @media (max-width: 780px) {
                    // margin-left: 20px;
                    margin: 0 auto;
                }
            }

            @media (max-width: $lg - 1) {
                margin-left: 7px;
                margin-right: 7px;
            }

            @media (max-width: $lg - 1) {
                text-align: center;
                width: 100%;
                margin: 0 auto;
            }

            &.login-btn {
                margin-left: 40px;
                img {
                    margin-right: 10px;
                }
                @media (max-width: $md - 1) {
                    margin-left: 0;
                }
            }

            a {
                color: #fff;
                padding: 5px 0;
                border: solid 1px transparent;
                &:hover, &.active {
                    border-bottom-color: #fff;
                }
                @media (max-width: $md - 1) {
                    display: block;
                    padding: 10px;
                }
            }
        }
    }
    .logo {
        margin-right: auto;
        height: auto;

        @media (max-width: $lg - 1) {
            padding-bottom: 20px;
            text-align: center;
            width: 230px;
            max-width: 230px;
            height: auto;
        }
        @media (max-width: $md - 1) {
            margin: 10px 0 60px;
            text-align: left;
            padding-bottom: 0;
        }
        @media (max-width: $sm - 1) {
            img {
                width: 230px;
                height: auto;
            }
        }
    }

    .media-icons-container {
        display: flex;
        justify-content: flex-end;
        margin-top: -50px;
        height: 45px;
    }

    @media (max-width: 766px) {
        .media-icons-container {
            justify-content: space-around;
        }
    }
}
