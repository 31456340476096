#admin-sidebar {
    width: 0;
    height: 100%;
    position: absolute;
    background: #fff;
    z-index: 100;

    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    & .sidebar-item {
        display: flex;
        justify-content: space-around;
        height: 50px;
        align-items: center;
        cursor: pointer;
    }

    & .sidebar-item :hover {
        color: #126ef1
    }

    .active-item {
        color: #126ef1;
    }

    & .sidebar-items {
        width: 75px;
        background: #fff;
        height: 100%;
    }

    & .sidebar-items a {
        color: #aaa;
    }

    & .sdb-hamburger {
        height: 65px;
        width: 75px;
        margin: 0 auto;
        cursor: pointer;
        padding-top: 30px;
    }

    & .sd-h-row {
        width: 30%;
        height: 4px;
        border-radius: 3px;
        margin: 3px auto;
        background: #126ef1;
    }

    & .sidebar-hidden {
        display: none;
    }

    & .hamburger-open {
        background: #fff;
    }

}
