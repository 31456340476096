.google-places-autocomplete__input {
  border: none;
  margin-left: 25px;
}

.google-places-autocomplete__suggestion  {
  cursor: pointer;
  background-color: #fff;
  
}

.google-places-autocomplete__suggestion:hover  {
  cursor: pointer;
  background: #457bd5;
  color: #fff;
  font-weight: bold;
}
.google-places-autocomplete .google-places-autocomplete__input {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 25px;
  text-indent: 10px;
}

input:focus {
  outline: none;
}

#-google-places-suggestions-container {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 20px;
  position: absolute;
  top: 50px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  min-width: 100%;
}
