@import "../../variables.scss";

.product-card {
    // background-color: #fff;
    // box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    // border-radius: 30px;
    // display: flex;
    // flex-direction: column;
    // align-items: space-between;
    // height: 100%;
    // overflow: hidden;

    background-color: #fff;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    /* height: 100%; */
    /* overflow: hidden; */
    flex-wrap: wrap;
    cursor: pointer;

    @media(max-width: $sm - 1) {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .product--image {
        width: 100%;
        height: 200px;
        border-radius: 30px 30px 0 0;
    }

    .product--tag {
        padding: 20px 35px 0;
        font-size: 16px;
    }

    // .product--title {
    //     color: $heading_purple;
    //     display: block;
    //     font-size: 20px;
    //     font-weight: 600;
    //     padding: 20px 35px;
    // }

    & .product-title {
        text-align: center;
        margin: 20px 5px;
        font-size: 20px;
        font-weight: bold;
        color: #634e70;
        height: 90px;
        overflow: hidden;
    }

    .product--short-content {
        color: $heading_purple;
        padding: 0 15px;
        margin-bottom: auto;
        height: 150px;
        overflow: hidden;
        font-size: 15px;
        text-align: center;
    }

    .product--price {
        color: $link_blue;
        display: block;
        font-size: 24px;
        font-weight: 600;
        margin-top: 10px;
        padding: 15px 35px 0;
        text-align: center;
    }

    .product--button-container {
        margin-top: 30px;
        padding: 0 35px;
        padding: 0 35px 35px;

        .product--button {
            box-shadow: none;
            line-height: 48px;
        }
    }

    .product--content {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 297px;
    }

    &.product--first {
        border: 0 none;
        display: flex;
        flex-direction: row;
        flex-flow: row-reverse;
        height: 375px;
        
        @media (max-width: $sm - 1) {
            flex-direction: column;
        }

        .product--image-container {
            flex-basis: 50%;

            @media (max-width: $lg - 1) {
                flex-basis: 45%;
            }


            .product--image {
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-radius: 0 30px 30px 0;

                @media (max-width: 600px) {
                    border-radius: 30px 30px 0 0;
                    height: 200px;
                }
            }
        }


        .product--content {
            padding: 20px 25px;
            display: flex;
            flex-basis: 50%;
            flex-direction: column;
            max-width: 500px;

            @media (max-width: $lg - 1) {
                flex-basis: 55%;
                padding: 20px 0px;
            }

            .product--tag {
                font-size: 20px;
                margin-top: auto;
                padding-top: 0;
            }

            .product--title {
                font-size: 25px;
                padding: 10px 35px 20px;
                height: 60px;
            }

            .product--short-content {
                line-height: 25px;
            }

            .product--button-container {
                margin-bottom: auto;
                max-width: 290px;
                padding-bottom: 0;
            }
        }
    }

    & .art-small-thb {
        height: 200px;
        overflow: hidden;
    }
}