#similar-articles-section {
    max-width: 960px;
    width: 80%;
    margin: 40px auto;

    & .s-art-title {
        color: #634e70;
        font-size: 30px;
        font-weight: bold;
        margin: 0 0 30px;
    }

    & .sim-article-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    & .sim-art-card-container {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 40px;
    }

    & .sim-art-thumbnail-container {
        width: 150px;
        height: 150px;
        overflow: hidden;
        border-radius: 30px;
        margin-right: 15px;
    }

    & .sim-art-thumbnail-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    & .sim-art-card {
        width: 100%;
    }

    & .sa-data-row {
        display: flex;
    }

    & .sim-art-details-container {
        flex: 1;
    }

    & .sa-date-row {
        font-size: 15px;
        color: #b1b1b1;
        margin-bottom: 10px;
        max-width: 150px;
        overflow: hidden;
    }

    & .sa-title-row {
        font-size: 20px;
        font-weight: bold;
        color: #634e70;
    }

    & .read-sim-art-btn {
        width: 150px;
        height: 50px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-left: 170px;
    }

}
