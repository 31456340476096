@import "../../variables.scss";

.footer {
    background-image: url("../../static/images/desktop/Shape_4.png");
    background-position-x: center;
    background-repeat: no-repeat;
    //background-position: center;
    //background-size: contain;
    color: #fff;
    display: flex;
    font-size: 16px;
    min-height: 630px;
    margin-top: 100px;
    @media (max-width: $md - 1) {
        background-position: left -175px;
        padding-top: 400px;
        background-image: url("../../static/images/desktop/footer-background-extended.png");
    }


    .container {
        margin-top: auto;

        @media (max-width: $md - 1) {
            width: 100%;
        }

        @media (max-width: $sm - 1) {
            max-width: 100%;
            width: 100%;
        }
    }

    .footer-logo {
        display: block;
        max-width: 100%;
        margin: 0 auto 20px;
        width: auto;
        height: auto;
    }

    .footer-menu {
        // columns: 2;
        display: block;
        list-style: none;
        margin-bottom: 0;
        padding: 0;

        .footer-menu li {
            text-align: left;
        }

        @media (max-width: $md - 1) {
            columns: 3;
        }

        @media (max-width: $sm - 1) {
            columns: 1;
            // text-align: center;
        }

        li {
            padding: 4px 0;

            a {
                padding: 4px 0;
                color: #fff;
            }
        }
    }

    .social-icons {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        

        .iconsContainer {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;

            @media (max-width: $md - 1) {
                justify-content: space-around;}
        }

        @media (max-width: $md - 1) {
            justify-content: space-around;
            margin: 0px auto 0;
            max-width: 290px;
            width: 100%;
        }

        img {
            &:hover {
                opacity: 0.7;
                transition: opacity 500ms;
            }

            @media (max-width: $lg - 1) {
                width: 45px;
                height: 45px;
            }
        }

        .media-icon-container {
            margin-right: 25px;
            width: 50px;
            height: 50px;
            @media (min-width: $lg) {
                width: 50px;
            }
            @media (max-width: $md - 1) {
                width: 50px;
                margin-right: 0px;
            }
        }
    }

    .copyright {
        font-size: 10px;
        margin: 40px auto 35px;

        @media (max-width: $md - 1px) {
            margin: 0px auto 10px;
        }

        a{
            color: white;
        }
    }

    & .disclaimer-bottom-affil {
        width: 80%;
        max-width: 500px;
        margin: 20px auto;
        font-size: 11px;
    }

    @media (max-width: 766px) {
        .social-icons {
            width: 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 575px) {
        // columns: 1;
        .footer-menu li {
            text-align: center;
        }

    }

    // @media (max-width: 577px) {
    //     .footer {
    //         & .container {
    //             width: 100%;
    //             max-width: 100%;
    //         }
    //     }

    // }
}
