#support {

    background: #f1f2f361;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }


    & .input-row input {
        width: 80%;
        max-width: 380px;
    }

    & .form-container {
        margin-top: 50px;
    }

    & .input-row {
        margin-bottom: 35px;
    }

    & .submit-row {
        margin-top: 75px;
    }

    & input.input-error {
        border-bottom: 1px solid red;
    }

    & .submit-button {
        width: 200px;
        height: 50px;
    }

}

@media (max-width: 580px) {
    #support {

        & .page-title {
            text-align: center;
        }
        
        & .input-row {
            display: flex;
            justify-content: space-around;
        }

    }
}