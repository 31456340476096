.apply-button-container {
  
  .apply-button {
    line-height: 48px;
  }
}

.signature {
  box-shadow: none !important;
  width: 250px;
}

.thank-you {
  color: darkgreen;
  font-weight: 700 !important;
}
