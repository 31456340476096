//@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Overpass:400,700&display=swap');

@import "./variables.scss";
@import "./bootstrap_customization.scss";


@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat/Montserrat-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat/Montserrat-SemiBold.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat/Montserrat-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}


body {
    color: $default_text_grey;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    height: auto;

    &.overflow-hidden {
        overflow: hidden;
    }

    p {
        text-align: left !important;
    }

    // h2 {
    //     @media (max-width: $md - 1) {
    //         text-align: left !important;
    //     }
    // }

   
}

a {
    color: $link_blue;
    font-family: 'Montserrat', sans-serif;
    &:hover {
        text-decoration: none;
    }
}

h1 {
    color: $heading_purple;
    font-size: 45px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    @media (max-width: $md - 1) {
        font-size: 35px;
    }
}

h2 {
    color: $heading_purple;
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 60px;
    font-family: 'Montserrat', sans-serif;

    @media (max-width: $md - 1) {
        font-size: 35px;
    }
}

.menu-navigation {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    line-height: 100%;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;

    .navigate-links {
        display: flex;
        justify-content: space-between;
    }

    .navigate-right {
        margin-right: 10px;
        text-align: right;
    }

    .position-right {
        display: flex; 
        justify-content: flex-end
    }

    .navigate-left {
        margin-left: 10px;
    }

    .icon-left {
        display: none;
    }

    .position-left {
        display: flex; 
        justify-content: flex-start
    }
    
    
}

.info-text {
    color: #634e70;
    display: flex;
    font-size: 12px;
    margin-top: 5px;
    p {
        margin-left: 3px;
    }
}

.published-date {
    width: 170px;
}

.btn.btn-default {
    background-image: linear-gradient(to right, $btn_gradient_start 0%, $btn_gradient_end 100%);
    border: 0 none;
    border-radius: 38px;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    height: 60px;
    max-width: 300px;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
    &.btn-small {
        max-width: 220px;
    }

    a {
        color: #fff;
        font-family: 'Montserrat', sans-serif;
        &:hover {
            text-decoration: none;
        }
    }
}

.m-width-600 {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.ml-auto {
    margin-left: auto;
}

.mr-auto {
    margin-right: auto;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-80 {
    margin-top: 80px;
}

.mt-120 {
    margin-top: 120px;
}

.mt-180 {
    margin-top: 180px;
}

.mt-200 {
    margin-top: 180px;
}

.mt-ltmd-80 {
    @media(max-width: $md - 1) {
        margin-top: 80px;
    }
}

.text-purple {
    color: $heading_purple;
    font-family: 'Montserrat', sans-serif;
}

.text-white {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.text-xs-left {
    @media (max-width: $sm - 1) {
        padding-left: 32px;
        padding-right: 32px;
        text-align: left;
    }
}

.container {
    .text-xs-left {
        padding-left: 0;
        padding-right: 0;
    }
}


.custom-input {
    border-color: $placeholder_grey_light;
    border-width: 0 0 1px 0;
    font-family: "Montserrat";
    font-size: 20px;
    margin-bottom: 60px;
    width: 100%;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $placeholder_grey_light;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        color: $placeholder_grey_light;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        color: $placeholder_grey_light;
    }
    &:-moz-placeholder { /* Firefox 18- */
        color: $placeholder_grey_light;
    }

    @media (max-width: $md - 1) {
        margin-bottom: 30px;
    }

}

.custom-radio--row {
    margin-bottom: 60px;

    @media (max-width: $md - 1) {
        margin-bottom: 30px;
    }
}