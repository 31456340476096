#therapist-manage-locations {

    background: #f1f2f361;
    position: relative;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .search-row {
        display: flex;
        margin-top: 50px;
        padding-bottom: 25px;
        // border-bottom: 1px solid #ddd;
    }

    & .add-row {
        display: flex;
        margin-top:25px;
        padding-bottom: 35px;
        border-bottom: 1px solid #ddd;
    }

    & .add-row .leftside-content {
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: space-between;
    }

    & .leftside-content {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }

    & .rightside-content {
        width: 30%;
        display: flex;
        justify-content: flex-end;
    }

    
    & .small-gradient-button {
        width: 90%;
        height: 50px;
    }

    & .small-gradient-button span {
        margin: 0;
    }

    & .ins-search-input {
        width: 100%;
    }

    & .results-section {
        margin-top: 30px;
    }

    & .loc-subtitle {
        color: #634e70;
    }

    & .add-section {
        margin-top: 50px;
    }

    & .google-places-autocomplete__suggestion  {
        cursor: pointer;
    }

    & .google-places-autocomplete__suggestion:hover  {
        cursor: pointer;
        background: #457bd5;
        color: #fff;
        font-weight: bold;
    }

    & .location-input-outer {
        background: linear-gradient(to right, #106ff1, #a723ec);
        border-radius: 25px;
        padding: 2px;
        margin-top: 15px;
    }

    & .google-places-autocomplete .google-places-autocomplete__input {
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 25px;
        text-indent: 10px;
    }

    & .loc-at-item {
        display: flex;
        //background: linear-gradient(to right,  #106ff1, #a723ec);
        // padding: 5px 15px;
        border-radius: 25px;
        align-items: center;
        margin: 5px 10px;
        justify-content: space-around;
        padding: 2px;
    }

    & .loc-at-outer {
        display: flex;
        background: #fff;
        padding: 5px 15px;
        border-radius: 25px;
        align-items: center;
        height: 45px;
    }

    & .loc-at-outer .loc-at-info {
        height: 45px;
        display: flex;
        align-items: center;
        padding-right: 10px;
    }

    & .loc-at-outer .loc-at-info h4 {
        margin: 0
    }

    & .loc-at-actions {
        height: 45px;
        display: flex;
        align-items: center;
    }

    & .attached-location-list {
        display: flex;
        flex-wrap: wrap;
    }

    & .loc-at-name {
        font-size: 12px;
    }

    & .attached-locations-section {
        margin-top: 25px;
    }

    & .no-access-desc {
        padding: 15px;
        font-size: 18px;
    }

    & .bold-val {
        color: #634e70;
        font-weight: bold;
    }

}

@media (max-width: 580px) {
    #therapist-manage-locations {

        & .inner-content {
            width: 95%;
        }

        & .page-title {
            text-align: center;
        }

        & .loc-subtitle {
            text-align: center;
        }

        & .loc-at-item {
            width: 100%;
        }

        & .loc-at-outer {
            width: 100%;
        }

        & .loc-at-info {
            flex: 1;
            display: flex;
            justify-content: space-around;
        }

    }
}