#admin-manage-specialities {

    background: #f1f2f361;
    position: relative;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .search-row {
        display: flex;
        margin-top: 50px;
        padding-bottom: 25px;
        border-bottom: 1px solid #ddd;
    }

    & .add-row {
        display: flex;
        margin-top:25px;
        padding-bottom: 35px;
        border-bottom: 1px solid #ddd;
    }

    & .add-row .leftside-content {
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: space-between;
    }

    & .leftside-content {
        width: 70%;
        display: flex;
        justify-content: flex-start;
    }

    & .rightside-content {
        width: 30%;
        display: flex;
        justify-content: flex-end;
    }

    
    & .small-gradient-button {
        width: 90%;
        height: 50px;
        text-align: center;
    }

    & .small-gradient-button span {
        margin: 0;
    }

    & .sp-search-input {
        width: 90%;
    }

    & .results-section {
        margin-top: 30px;
    }

    & .message-span-container {
        padding-left: 30px;
    }

    & .sp-list-item {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 30px;
        padding: 20px 35px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        margin: 15px 0;
    }

    & .sp-li-name {
        font-weight: 600;
    }

    & .sp-li-description {
        font-size: 15px;
    }

    & .sp-li-info {
        flex: 1;
        border-right: 1px solid #eee;
    }

    & .sp-li-actions {
        width: 70px;
        display: flex;
        justify-content: space-evenly;
    }

    & .edit-icon:hover {
        color: #ffc107;
        cursor: pointer;
    }
    & .delete-icon:hover {
        color: #dc3545;
        cursor: pointer;
    }
    & .update-icon:hover {
        color: #28a745;
        cursor: pointer;
    }

    & .edit-sp-name, .edit-sp-description {
        width: 90%;
    }

    & .edit-sp-description{
        margin-top: 15px;
    }

}

@media (max-width: 580px) {
    #admin-manage-specialities {

        & .page-title {
            text-align: center;
        }

        & .search-row {
            flex-direction: column;
            align-items: center;
        }

        & .leftside-content {
            width: 100%;
            justify-content: space-around;
        }

        & .rightside-content {
            width: 100%;
            justify-content: space-around;
            margin-top: 20px;
        }

        & .add-row {
            flex-direction: column;
            align-items: center;
        }

        & .add-row .leftside-content {
            align-items: center;
        }

    }
}