.side-drawer {
    width: 100%;
}

.side-drawer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.sidedrawer-nav-item {
    display: flex;
    justify-content: space-around;
    color: #457bd5;
    padding: 15px;
    cursor: pointer;
}

@media (min-width: 744px) {
    .side-drawer {
        display: none;
    }

}