@import "../../variables.scss";

.blog-listing {
    .blog-listing--image {
        border-radius: 20px;
        height: 100%;
        max-width: 100%;
        object-fit: cover;

        @media (max-width: $md - 1) {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .blog-listing--right {
        display: flex;
        flex-direction: column;

        @media (max-width: $md - 1) {
            margin-top: 20px;
        }

        .blog-listing--date {
            font-family: 'Montserrat', sans-serif;
        }

        .blog-listing--title {
            color: $heading_purple;
            font-family: 'Montserrat', sans-serif;
            font-size: 23px;
            font-weight: 700;
            margin-top: 15px;
            margin-bottom: auto;

            @media (max-width: $md - 1) {
                text-align: center;
            }
        }

        .blog-feat-snippet {
            @media (max-width: $md - 1) {
                text-align: left;
            }
        }
        .read-button{
          margin-top:15px;
        }

        .btn.btn-default {
            font-size: 20px;
        }
    }

    & .f-art-thb-container {
        width: 250px;
        height: 250px;
    }
}
