#manage-bloggers {

    background: #f1f2f361;
    position: relative;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .search-row {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
    }

    & .search-row .content-half {
        width: calc(50% - 20px);
        padding: 10px;
    }

    & .content-half .t-search-input {
        width: 100%;
        margin-bottom: 25px;
    }
    
    & .t-search-button {
        width: 200px;
        height: 50px;
        text-align: center;
    }

    & .t-search-button span {
        margin: 0;
    }

    & .sp-search-input {
        width: 90%;
    }

    & .results-section {
        margin-top: 30px;
    }

    & .message-span-container {
        padding-left: 30px;
    }

    & .ai-list-item {
        background: #fff;
        border: 1px solid #eee;
        border-radius: 30px;
        padding: 30px 35px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
        margin: 15px 0;
    }

    & .ai-image-container {
        width: 130px;
        height: 130px;
        border-radius: 15px;
        overflow: hidden;
    }

    & .ai-image-container img {
        width: 100%;
        height: 100%;
    }

    & .ai-info-container {
        flex: 1;
        padding-left: 50px;
    }

    & .ai-actions-container {
        width: 120px;
    }

    & .ai-main-info {
        font-size: 25px;
        display: flex;
        justify-content: flex-start;
        // font-weight: 600;
        color: #634e70
    }

    & .ai-main-info .ai-u-name {
        font-weight: 600;
    }

    & .ai-details-info {
        margin-top: 10px;
        font-size: 17px;
        color: #634e70;
    }

    & .ai-detail-container {
        display: flex;
    }

    & .ai-detail-label {
        width: 75px;
        font-weight: 600;
    }

    & .ai-actions-container {
        display: flex;
        justify-content: space-evenly;
    }

    & .edit-icon:hover {
        color: #ffc107;
        cursor: pointer;
    }
    & .delete-icon:hover {
        color: #dc3545;
        cursor: pointer;
    }
    & .update-icon:hover {
        color: #28a745;
        cursor: pointer;
    }

    & .view-icon {
        color: #aaa;
    }

    & .green-icon {
        color: #28a745;
        cursor: pointer;
    }

    & .red-icon {
        color: #dc3545;
        cursor: pointer;
    }

    & .yellow-icon {
        color: #ffc107;
        cursor: pointer;
    }

    & .view-icon:hover {
        color: #007bff;
        cursor: pointer;
    }

    & .edit-sp-name, .edit-sp-description {
        width: 90%;
    }

    & .edit-sp-description{
        margin-top: 15px;
    }

    & .suggest-container {
        position: relative;
    }

    & .autosugest-input {
        z-index: 2;
    }

    & .suggested-specs-container {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 20px;
        position: absolute;
        width: 100%;
        top: 25px;
        z-index: 1;
        padding-top: 30px;
        display: block;
    }

    & .hidden-element {
        display: none;
    }

    & .suggested-spec-item {
        display: flex;
        padding: 10px;
        flex-wrap: wrap;
    }

    & .suggested-specs-container .speciality-select-box {
        margin-right: 20px;
    }

    & .tr-reviews-value {
        margin-left: 20px;
    }

    & .tr-reviews-row {
        margin-bottom: 15px;
    }

}

@media (max-width: 580px) {
    #manage-bloggers {

        & .page-title {
            text-align: center;
        }
        
        & .inner-content-container {
            max-width: 100%;
        }

        & .search-row {
            flex-direction: column;
            align-items: center;
        }

        & .search-row .content-half {
            width: 100%;
        }

        & .search-row .rightside-content {
            flex-direction: column;
            align-items: center;
        }

        & .suggest-container {
            width: 100%;
        }

        & .ai-list-item {
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .ai-info-container {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .ai-main-info {
            flex-direction: column;
            align-items: center;
        }

        & .ai-details-info {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .tr-reviews-row {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & .tr-reviews-value {
            margin: 5px auto;
        }

        & .tr-details-container {
            margin-bottom: 25px;
        }

    }
}