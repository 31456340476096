@import "../../variables.scss";

    #contact-us-page {
      .star{
        color:red;
      }
      ::placeholder {
        color: grey;
        opacity: 1;
      }
      .header-contact {
         text-align: center;
         color: white;
         font-weight: 600;
         margin-bottom: 150px;
      }
    }
    .form{
      input{
      font-weight: bold;
    }

    textarea{
      font-weight: bold;
    }
  }

@media (max-width: 780px) {
    #contact-us-page {
      .header-contact{
            margin-top: -60px;
            color: white;
            text-align: center;
        }
      }
}

@media (max-width: 480px) {
    #contact-us-page {
      .form{
        padding-top: 110px;
        padding-left: 15px;
      }
      .header-contact{
        padding-top: 100px;
        color: white;
          text-align: center;
          margin-bottom: 80px;
          margin-top: 0px;
          padding-top: 0px;
    }

        & .contact-submit-row {
            display: flex;
            justify-content: space-around;
        }

    }
}
