.container-checkbox-round {
  & .round {
    position: relative;
    display: flex;
    align-items: center;
  }
  & .round input[type="checkbox"] {
    margin-right: 10px;
  }
  & .round input[type="checkbox"]:hover {
    cursor: pointer;
  }
  & .round label:hover {
    cursor: pointer;
  }
  & body {
    background-color: #f1f2f3;
  }
}