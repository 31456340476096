.plan-card {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 30px;
    padding: 30px 25px;
    box-shadow: 0 16px 23px rgba(0, 0, 0, 0.3);
    width: 320px;
    padding-bottom: 45px;
    margin-top: 20px;
    position: relative;
    overflow:hidden;

    & .p-name-container {
        display: flex;
        justify-content: space-around;
        font-size: 25px;
        font-weight: bold;
        color: #634e70;
        // height: 50px;
    }

    & .p-price-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        margin-top: 10px;
    }

    .badge {
		
		&.color{
			background: #d68843;	
		}
		
		&.diagonal{
		white-space:nowrap;
		position: absolute;
		padding: 15px 68px;
		min-width: 200px;
		transform: rotate(-45deg) translate(-58%, 0);
		color: white;
		text-align: center;
		text-transform: uppercase;
		font-size: 14px;
		top: -80px;
		box-sizing: border-box;
		}
	}

    & .p-price {
        font-size: 45px;
        font-weight: bold;
        color: #634e70;
    }

    & .p-price-period {
        font-size: 17px;
        color: #ddd;
    }

    & .p-benefits-container {
        font-size: 15px;
        height: 220px;
    }

    & .p-benefit-item {
        margin-top: 10px;
        text-align: center;
    }

    & .choose-plan-container {
        display: flex;
        justify-content: space-around;
        margin-top: 65px;
    }

    & .current-plan-marker {
        font-weight: bold;
        border: 2px solid #ccc;
        padding: 11px 25px;
        border-radius: 30px;
    }

    & .p-name-container .p-name {
        text-align: center;
    }

    @media (max-width: 580px) {
        padding: 20px 25px;
        width: 310px;
    }

    @media (max-width: 350px) {
        padding: 20px 25px;
        width: 290px;
    }

}