.numeric-input-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 40px 0;
  flex-wrap: wrap;

  .numeric-input {
    display: flex;
    align-items: center;
    margin: 10px 0;

    .numeric-input-group {
      clear: both;
      position: relative;
      display: flex;

      input,
      textarea {
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        margin: 0;
        outline: none;
        padding: 10px;
      }

      input[type="button"] {
        -webkit-appearance: button;
        cursor: pointer;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type="number"] {
        -moz-appearance: textfield;
        -webkit-appearance: none;
      }
    }

    .numeric-input-group input[type="button"] {
      background-color: #eeeeee;
      min-width: 38px;
      width: auto;
      transition: all 300ms ease;
    }

    .numeric-input-group .button-minus,
    .numeric-input-group .button-plus {
      font-weight: bold;
      height: 38px;
      padding: 0;
      width: 38px;
      position: relative;
    }

    .numeric-input-group .quantity-field {
      position: relative;
      height: 38px;
      left: -6px;
      text-align: center;
      width: 62px;
      display: inline-block;
      font-size: 13px;
      margin: 0 0 5px;
      resize: vertical;
    }

    .button-plus {
      left: -13px;
    }
  }

  .extra-cost {
    display: flex;
    margin: 10px 0;

    .extra-cost-container {
      margin-left: 10px;

      .cost-input-outer {
        width: 160px;
        height: 55px;
        background: linear-gradient(to right, #106ff1, #a723ec);
        padding: 2px;
        border-radius: 30px;

        .cost-input-inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          padding-left: 20px;
          align-items: center;
          height: 100%;
          background: #fff;
          border-radius: 30px;
          overflow: hidden;
          flex-direction: column;

          .cost-input-container {
            flex: 1;
            display: flex;
            padding: 15px 5px;
            flex-wrap: wrap;
          }

          .cost-input-container input {
            width: 120px;
            border: none;
            // border-bottom: 1px solid #eee;
          }

          .cost-label {
            font-size: 20px;
            font-weight: 700;
            color: #126ef1;
          }
        }
      }
    }

    .extra-cost-btn {
      width: 120px;
      height: 55px;
      margin-left: 20px;
    }
  }
}
