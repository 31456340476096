#admin-profile {

    background: #f1f2f361;

    & .page-background {
        width: 100%;
        min-height: 100vh;
    }

    & h1 {
        margin: 0;
    }

    & .page-content {
        display: flex;
    }

    & .inner-content-container {
        flex: 1;
    }

    & .inner-content {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 810px;
    }

    & .second-row {
        display: flex
    }

    & .leftside-content {
        width: 30%;
    }

    & .rightside-content {
        width: 70%;
    }

    & .inner-content .leftside-content .avatar-container {
        width: 125px;
        height: 125px;
        background: #ddd;
        border-radius: 25px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        overflow: hidden;
        border: 1px solid #efefef;
        position: relative;
    }

    & .inner-content .add-photo-btn {
        width: 50px;
        height: 50px;
        border: 1px solid #fff;
        border-radius: 50%;
        color: #fff;
        font-size: 31px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto 15px auto;
    }

    & .input-row input {
        width: 80%;
        max-width: 380px;
    }

    & .form-container {
        margin-top: 50px;
    }

    & .input-row {
        margin-bottom: 35px;
    }

    & .submit-row {
        margin-top: 25px;
        margin-bottom: 50px;
    }

    & input.input-error {
        border-bottom: 1px solid red;
    }

    & .profile-img-sub-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    & .profile-tabs {
        display: flex;
        flex-wrap: wrap;
    }

    & textarea.basic-input {
        width: 80%;
        max-width: 380px;
        border: 1px solid rgb(221, 221, 221);
        outline: none;
        background: transparent;
        color: rgb(204, 204, 204);
        margin-top: 0px;
        margin-bottom: 0px;
        height: 160px;
        text-indent: 5px;
    }

    & .profile-tabs h1 {
        margin-right: 10px;
    }

    & .tab-item {
        cursor: pointer;
    }

    & .inactive-tab {
        color: #ddd;
    }

    & .password-tab {
        width: 100%;
        margin-top: 50px;
    }


}

@media (max-width: 580px) {
    #admin-profile {

        & .tabs-bar {
            display: none;
        }

        & .profile-tabs {
            justify-content: space-around;
        }

        & .second-row {
            flex-direction: column;
            align-items: center;
        }

        & .leftside-content {
            width: 80%;
            display: flex;
            justify-content: space-around;
        }

        

        & .rightside-content {
            width: 90%;
        }

        & .form-container .input-row {
            display: flex;
            justify-content: space-around;
        }

        & .submit-row {
            display: flex;
            justify-content: space-around;
        }

        & .page-title {
            text-align: center;
        }
    }
}