$default_text_grey: #634e70;
$placeholder_grey: #b8b8b8;
$placeholder_grey_light: #d8d8d8;
$card_border_color: #979797;
$link_blue: #457bd5;
$logo_blue: #126ef1;

$error_red: #dc3545;

$btn_gradient_start: #2441d1; //dark purple/dark blue
$btn_gradient_end: #c380d6; // light purple

$article_search_gradient_start: #106ff1;
$article_search_gradient_end: #a724ec;


$search_btn_gradient_start: #a723ec; //dark purple/dark blue
$search_btn_gradient_end: #106ff1; // light purple

$become_member_btn_gradient_start: #35baeb; //light blue-ish
$become_member_btn_gradient_end: #6b37e0; //some kind of blue-ish purple-ish

$heading_purple: #634e70;

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
