#signin {
    //position: absolute;
    width: 100vw;

    & .page-background {
        width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: -1;
    }

    & .bck-left-shape {
        position: absolute;
        width: 600px;
        top: -300px;
        left: -200px;
    }

    & .bck-right-top-ellipse {
        width: 400px;
        position: absolute;
        right: -200px;
        top: -200px;
    }

    & .bck-bottom-shape {
        width: 730px;
        position: absolute;
        bottom: -225px;
        right: -200px;
    }

    & .bck-bottom-ellipse {
        position: absolute;
        bottom: 45px;
        right: 15px;
        width: 175px;
    }

    & .page-content {
        width: 50%;
        margin: 0 auto;
        max-width: 470px;
    }

    & .logo {
        display: block;
        width: 200px;
        margin: 20px auto 30px;
    }

    & input {
        width: 50%;
        margin-bottom: 10px;
        margin-top: 5px;
    }

    & ::placeholder {
        color: dimgrey;
    }

    & .remember-me-row {
        display: flex;
        margin-top: 25px;
        margin-bottom: 5px;

        .sign-in-checkbox {
            width: 20px;
            margin: 10px;
            cursor: pointer;
        }

        .grey-text {
            cursor: pointer;
        }
    }

    & .remember-me-row span {
        display: block;
    }

    & .remember-me-row .container-checkbox-round {
        margin-right: 25px;
    }

    & .login-row {
        margin-top: 75px;
    }

    & .signin-row {
        margin-top: 30px;
    }

    & input.input-error {
        border-bottom: 1px solid red;
    }

}

@media (max-width: 795px) {
    #signin {
        & input {
            width: calc(100% - 20px);
            display: block;
            margin: 15px auto;
        }

        & .page-content {
            width: 85%;
        }

        & .bck-left-shape {
            left: -350px;
        }
    }
}


@media (max-width: 480px) {

    #signin {
        position: absolute;
        width: 100vw;
        & .page-title {
            text-align: center;
            font-size: 21px;
        }

        & .remember-me-row {
            justify-content: center;
            align-items: center;
        }

        & .container-checkbox-round {
            margin-top: -15px;
        }

        & .forgot-password-row {
            display: flex;
            justify-content: center;
        }

        & .login-row {
            display: flex;
            justify-content: center;
        }

        & .signin-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 20px;
            text-align: center;
            margin-top: 20px;
        }

        & .bck-bottom-shape {
            bottom: -195px;
            right: -280px;
        }

        & .signin-row .grey-text {
            color: #fff;
        }
    }
}