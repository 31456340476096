@import "../../variables.scss";

html {
  scroll-behavior: smooth;
}

.landingList {
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    list-style-type: circle;
    column-count: 2;
    column-gap: 40px;
  }
}

.landingPageContainer {
  margin-top: 50px;

  h3 {
    color: $heading_purple;
    font-weight: 600;
  }
}

.containerLanding {
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 40px;
  }

  .rowItem {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;

    .rowItemTop {
      margin-bottom: 25px;

      img {
        width: 100%;
      }
    }
  }

}
