.toggle-button {
    height: 30px;
    width: 30px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
}

.toggle-button__line {
    width: 90%;
    height: 3px;
    background: #126ef1;
    margin: 4px auto
}

.toggle-button__line:nth-child(2) {
    width: 100%;
}