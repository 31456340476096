#payment-first {
  .back-drop {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
  }

  .page-background {
    .page-content {
      .inner-content-container-first {
        width: 80%;
        margin: 0 auto;
        // margin-top: 50px;
        max-width: 1000px;
        display: flex;

        .inner-content {
          width: 100%;

          .top-row {
            .page-title {
              margin-bottom: 30px;
            }
          }
          margin-top: 50px;
          .main-content {

            .stripe-payment-method {
              margin-bottom: 30px;
            }

            h1 {
              text-align: center;
              font-weight: 600;
            }

            .last-line {
              text-align: center;
              margin-bottom: 50px;
            }

            .button-link {
              background: none!important;
              border: none;
              padding: 0!important;
              color: #069;
              text-decoration: underline;
              cursor: pointer;
            }

            .plans {

              width: 900px;
              margin: 30px auto 100px;

              .plan {
                position: relative;
                width: 270px;
                border-radius: 30px;
                border: 1px solid #979797;
                margin-top: 50px;
                padding-top: 40px;
                text-align: center;
                height: 480px;
                margin-right: 5px;

                h3 {
                  font-size: 25px;
                  font-weight: 600;

                }

                p {
                  font-size: 16px;
                  font-weight: 400;

                }

                h1 {
                  font-size: 45px;
                  font-weight: 700;

                }

                .plan-list {
                  margin: 20px auto;
                  color: #634e70;
                  font-size: 16px;
                  font-weight: 400;
                  height: 120px;

                }

                li {
                  margin: 8px;
                }

                .plan-button {
                  margin: 0 auto;
                  width: 150px;

                }
              }
            }
          }
        }
      }
    }
  }

  & .plans-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  & .order1 {
    order: 1
  }

  & .order2 {
    order: 2
  }

  & .order3 {
    order: 3
  }

  & .order4 {
    order: 4
  }

}


@media (max-width: 580px) {
  #payment {

      & .inner-content-container {
          width: 95% !important;
          overflow: hidden;
      }

      & .inner-content {
        width: 100%;
      }

  }
}
