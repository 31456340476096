@import "../../../variables.scss";

#admin-create-admin {
  background: #f1f2f361;

  & .page-background {
    width: 100%;
    min-height: 100vh;
  }

  & h1 {
    font-weight: 600;
    margin: 0;
  }

  & .page-content {
    display: flex;
  }

  & .inner-content-container {
    flex: 1;
  }

  & .inner-content {
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 810px;
  }

  & textarea.basic-input {
    width: 80%;
    max-width: 380px;
    border: 1px solid rgb(221, 221, 221);
    outline: none;
    background: transparent;
    color: rgb(204, 204, 204);
    margin-top: 0px;
    margin-bottom: 0px;
    height: 160px;
    text-indent: 5px;
  }

  & .second-row {
    display: flex;
  }

  & .leftside-content {
    width: 30%;
  }

  & .rightside-content {
    width: 70%;
  }

  & .inner-content .leftside-content .avatar-container {
    width: 125px;
    height: 125px;
    background: #ddd;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    overflow: hidden;
    position: relative;
  }

  & .inner-content .add-photo-btn {
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 31px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 15px auto;
  }

  & .input-row input {
    width: 80%;
    max-width: 380px;
  }

  & .form-container {
    margin-top: 50px;
  }

  & .input-row {
    margin-bottom: 35px;
  }

  & .submit-row {
    margin-top: 75px;
    margin-bottom: 50px;
  }

  & .inner-content .leftside-content .avatar-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .inner-content .avatar-container .change-image-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    // display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    font-family: "Montserrat";
    cursor: pointer;
  }

  & .visible-item {
    display: block;
  }

  & .hidden-item {
    display: none;
  }

  & .flex-item {
    display: flex;
  }

  .selected-specialities {
    & .attached-specialities-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;

      .sp-at-item {
        display: flex;
        justify-content: space-around;
        padding: 2px;

        & .sp-at-outer {
          display: flex;

          align-items: center;
        }

        & .sp-at-actions {
          display: flex;
          align-items: center;
          margin-left: 5px;
        }
      }
    }
  }

  #add-locations {

    .attached-locations-section ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
    }

    .loc-at-item {
      list-style: none;
      width: max-content;
      margin-right: 10px;
    }

    .loc-at-outer {
      display: flex;
      // padding: 5px 15px;
      align-items: center;
  }

    .loc-at-info {
      display: flex;
      align-items: center;
      padding-right: 10px;
    }
  }

  .search-select {
    margin-top: 20px;
    .search-field--select {
      //   border-radius: 30px;
      max-width: 100%;
      margin: 0 auto 20px;
      width: 100%;
      padding: 2px;
      position: relative;
      color: dimgrey;
      display: none;
      &.error {
        display: initial;
        p {
          color: red;
        }
        .search-field--label {
          color: $error_red;
        }
        @media (max-width: $md) {
          padding-top: 70px;
        }
      }
      @media (min-width: $md) {
        flex: 0 0 48%;
        max-width: 100%;
        // margin: 0 1% 30px;
      }
    }

    .search-field--bg {
      //   background-image: linear-gradient(
      //     to right,
      //     $btn_gradient_start 0%,
      //     $btn_gradient_end 100%
      //   );
      //   border-radius: 30px;
      max-width: 100%;
      // margin: 0 auto 20px;
      width: 100%;
      // padding: 2px;
      position: relative;
      color: dimgrey;
      &.error {
        background-image: linear-gradient(
          to right,
          $error_red 0%,
          $error_red 100%
        );

        .search-field--label {
          color: $error_red;
        }
      }

      @media (min-width: $md) {
        flex: 0 0 48%;
        max-width: 100%;
        // margin: 0 1% 30px;
      }
    }

    .search-field--button {
      align-items: center;
      display: flex;
      flex-direction: row;
      text-align: left;

      @media (max-width: $md - 1) {
        margin: 0 auto;
        max-width: 334px;
        width: 100%;
      }
    }

    .search-field--button-container {
      @media (max-width: $md - 1) {
        padding: 0;
      }
    }

    .autocomplete-input {
      position: relative;

      input {
        width: 100%;
      }

      .autocomplete-input--list {
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        margin-left: 5px;
        max-height: 250px;
        overflow-y: scroll;
        position: absolute;
        width: 100%;
        z-index: 2;

        .autocomplete-input--list-item {
          padding: 2px 10px;

          &.active {
            // background-image: linear-gradient(
            //   to right,
            //   $btn_gradient_start 0%,
            //   $btn_gradient_end 100%
            // );
            color: #fff;
          }
        }
      }
    }

    .no-bg-image {
      background-image: none;
    }

    // Search Field Styles
    & .item-suggest-outer {
      width: 100%;
      height: 40px;
      // background: lightgray;
      padding: 2px;
      border-radius: 25px;
      box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
    }

    & .item-suggest-inner,
    .loc-suggest-inner {
      display: flex;
      justify-content: flex-start;
      // padding-left: 50px;
      align-items: center;
      height: 100%;
      padding: 21px 0;
      background: #fff;
      border-radius: 25px;
      overflow: hidden;
    }

    & .loc-suggest-inner {
      overflow: visible;
    }

    & .item-suggest-label {
      display: none;
    }

    & .item-suggest-input {
      flex: 1;
      margin-left: 20px;
      border: none;
      outline: none;
    }

    .suggested-specs-container {
      background: #fff;
      border: 1px solid #ddd;
      border-radius: 20px;
      position: absolute;
      width: 100%;
      //   top: 60px;
      z-index: 10;
      padding-top: 20px;
      display: block;
    }

    & .suggested-spec-item {
      display: flex;
      padding: 3px;
      flex-wrap: wrap;
    }

    & .speciality-select-box {
      margin-right: 20px;
    }

    & .hidden-element {
      display: none;
    }

    & .speciality-placeholder {
      width: 100%;
      display: flex;
      padding-left: 10px;
      justify-content: space-between;
      padding-right: 20px;
      cursor: pointer;
    }

    & .specialit-drop-arrow {
      color: #126ef1;
    }

    & .speciality-placeholder-text {
      color: dimgrey;
    }

    & .spec-list-close-btn {
      width: 100%;
      border: 1px solid #eee;
      margin-top: -30px;
      /* border-radius: 15px; */
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      display: flex;
      justify-content: center;
    }

    & .spec-list-clos-btn-item {
      margin: 0 10px;
    }

    & .spec-list-close-icon {
      color: #dc3545;
    }

    & .spec-list-close-text {
      font-weight: bold;
      color: #ccc;
    }
  }
}

@media (max-width: 580px) {
  #admin-create-admin {
    & .second-row {
      flex-direction: column;
    }

    .leftside-content,
    .rightside-content {
      width: 100%;
    }

    .avatar-container {
      margin: 0 auto 50px auto;
    }

    .search-panel {
      // & .item-suggest-label, .riwl-label {
      //     display: none;
      // }

      & .item-suggest-inner,
      .loc-suggest-inner,
      .round-input-w-label-inner,
      .rd-input-inner {
        padding-left: 15px;
      }

      & .speciality-placeholder {
        padding: 10px;
      }

      & .riwl-label-text span {
        display: none;
      }

      & .riwl-label-text:after {
        content: "Ins.";
      }

      & .suggested-spec-item {
        align-items: center;
        font-size: 15px;
      }

      & .speciality-select-name {
        flex: 1;
      }

      & .page-title {
        text-align: center;
      }

      & .rightside-content {
        width: 100%;
      }

      & .input-row {
        display: flex;
        justify-content: space-around;
      }

      & .submit-row {
        display: flex;
        justify-content: space-around;
      }
    }
  }
}
