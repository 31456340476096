
.round-input-gradient {
    width: 350px;
    position: relative;


    & .input-gradient-border-container {
        background: linear-gradient(to right,  #106ff1, #a723ec);
        padding: 1px;
        border-radius: 30px;
    }
    
    & .input-gradient-inner-container {
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 10px 0;
        border-radius: 30px;
        padding-left: 25px;
        overflow: hidden;
        display: flex;
    }
    
    & .input-gradient-inner-container label {
        margin-right: 15px;
        color: #126ef1;
        font-size: 25px;
        max-width: 100px;
    }
    
    & .input-gradient-inner-container input{
        border: none;
        outline: none;
        font-size: 20px;
        width: 100%;
    }

    & .invalid-input {
        background: red;
    }

}


