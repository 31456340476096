#signup {
    & .page-background {
        width: 100%;
        min-height: 100%;
        // min-height: 500px;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        overflow: hidden;
    }
    & .bck-left-ellipse {
        position: absolute;
        top: -70px;
        left: -70px;
        width: 141px;
    }

    & .sign-in-checkbox {
        width: 20px;
        margin: 10px;
        cursor: pointer;
    }

    & .bck-right-ellipse {
        position: absolute;
        right: -111px;
        top: -111px;
        width: 222px;

    }

    & .bck-bottom-shape {
        position: absolute;
        bottom: -231px;
        right: -187px;
        width: 700px;
    }

    & .page-content {
        width: 90%;
        margin: 0 auto;
        display: flex;
        margin-top: 60px;
    }

    & .half-panel {
        width: 50%;
    }

    & .leftside-panel {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        text-align: center
    }

    & .logo {
        width: 25%;
        display: block;
        margin: 0 auto
    }

    & .people-group {
        width: 65%;
        display: block;
        margin: 50px auto;
    }

    // & .rightside-panel {
    //     display: flex;
    //     flex-wrap: wrap;
    //     flex-direction: row;
    //     justify-content: space-around;
    // }

    & .page-title {
        font-size: 45px;
        font-weight: 700;
        margin-top: 0;
    }

    & .basic-paragraph {
        color: #bfbfbf;
    }

    & .inputs-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-bottom: 20px;
    }

    & .inputs-row input {
        width: calc(50% - 40px);
    }

    & .classic-text {
        color: #969696
    }

    & .checkbox-text-row {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }

    & .checkbox-text-row p {
        margin: 7px
    }

    & .checkbox-text-row input[type="checkbox"] {
        margin-right: 15px;
    }

    & .login-row {
        display: flex;
        margin-top: 15px; 
    }

    & .login-row p {
        margin-right: 10px;
    }

    & .link-text {
        color: #126ef1
    }

    & .signup-row {
        margin-top: 30px
    }

    & input.input-error {
        border-bottom: 1px solid red;
    }

    & .error-message {
        color: red;
        text-align: center;
    }

    & .role-options-container {
        position: relative;
    }

    & .role-options-select {
        border: 1px solid #eee;
        outline: none;
        background: #fff;
        height: 45px;
        color: #ccc;
    }

    & .role-options-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 20px;
        padding: 0 25px;
    }

}



@media (max-width: 851px) {
    #signup {
        & .page-content {
            flex-direction: column;
            align-items: center
        }

        & .half-panel {
            width: 80%;
        }

        & .inputs-row input {
            width: 100%;
            margin-bottom: 15px;
        }

        & .inputs-row {
            margin-bottom: 0
        }

        & .bck-left-ellipse {
            display: none;
        }
    
        & .bck-right-ellipse {
            display: none;
    
        }
    
        & .bck-bottom-shape {
            display: none;
        }
    }
}

@media (max-width: 480px) {
    #signup {
        & .page-title {
            text-align: center;
            font-size: 21px;
        }

        & .login-row {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        & .logo {
            width: 80%;
        }

        & .people-group {
            width: 95%;
        }
    }
}
