#header-user {

    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-left: 30px;
    width: 100%;
    background: #fff;

    & img.align-self {
        align-self: center
    }

    & .logo {
        width: 35px;
    }

    & .header-content {
        width: 80%;
        display: flex;
        margin: 0 auto;
    }

    & .avatar-img-container {
        width: 57px;
        height: 57px;
        overflow: hidden;
        border-radius: 50%;
        /* text-align: center; */
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 7px solid #eee;
        z-index: 1;
        cursor: pointer
    }

    & .avatar-img-container img {
        width: 100%;
        height: 100%;
    }

    & .avatar-container {
        display: flex;
        align-items: center;
        position: relative;
    }

    & .name-container {
        width: 57px;
        height: 57px;
        background: #eee;
        border-radius: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        color: #126ef1;
        position: absolute;
        right: 45px;
        z-index: 1;
        cursor: pointer;
        border: 6px solid #fff;
    }

    & .user-aac-options-container {
        position: absolute;
        width: 110px;
        height: 100px;
        background: #eee;
        border-radius: 25px;
        bottom: -90px;
        right: 10px;
    }

    & ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    & .aac-options {
        margin-top: 20px;
        text-align: center;
        list-style-type: none;
    }

    & .spacer {
        flex: 1
    }

}